import { Progressbar } from 'framework7-react';
import { FC, useMemo, useState } from 'react';

import { useCurrentEntertainerQuery } from '@queries/EntertainerQueries';
import { useInAppMessageQuery } from '@queries/PostQueries';
import { useSweepstakes } from '@queries/SweepstakesQueries';
import { useCurrentUser, useUserChallengesQuery, useUserWalletQuery } from '@queries/UserQueries';

import { useAnalytics } from '@hooks/useAnalytics';
import { useCurrentUserCity } from '@hooks/useCurrentUserLocation';

import { useLocation, useNavigate, useParams } from '@router/index';

import { mapStringToParagraphs } from '@utilities/mapStringToParagraphs';
import { openExternalUrl } from '@utilities/openExternalUrl';

import Button from '@components/buttons/Button';
import EventCard from '@components/cards/EventCard';
import { Chip } from '@components/chips/Chip';
import { FontAwesomeIcon } from '@components/icons/FontAwesomeIcon';
import { ButtonFilter } from '@components/layout/ButtonFilter/ButtonFilter';
import Card from '@components/layout/Card/Card';
import { useDialog } from '@components/layout/Dialog/useDialog';
import { EmptyState } from '@components/layout/EmptyState/EmptyState';
import { HorizontalScroll } from '@components/layout/HorizontalScroll/HorizontalScroll';
import { LetterAvatar } from '@components/layout/LetterAvatar/LetterAvatar';
import { Screen } from '@components/layout/Screen';
import { TitleToolbar } from '@components/layout/TitleToolbar/TitleToolbar';

import { UserGagglRankingCard } from '@screens/HomeScreen/HomeScreen';
import { useAuthGate } from '@screens/LogInScreen/AuthGateContext';
import {
  SweepstakesCard,
  useSweepstakesReferralLink,
} from '@screens/series/SeriesScreen/SweepstakesCard';

import { Skeleton } from '../../components/layout/Skeleton/Skeleton';

const WalletCard = () => {
  const { track } = useAnalytics();
  const { walletIsReady, wallet } = useUserWalletQuery();
  const { openAlertDialog } = useDialog();

  const handleCashOut = () => {
    track('Cash Out', {
      total: wallet.total,
    });

    openAlertDialog({
      title: 'Not Available',
      text: 'Cashing out is not available at the moment, but we are working to get this feature to you ASAP.',
    });
  };

  return (
    <Card>
      <Card.Body>
        <div className="d-flex gap-3 align-items-center">
          <div className="flex-grow-1">
            <div className="fs-5">Your Wallet</div>
            <div className="fs-6">
              {walletIsReady ? (
                <>
                  <strong>{`$${(wallet?.total / 100).toFixed(2)}`}</strong>
                </>
              ) : (
                <Skeleton height={100} />
              )}
            </div>
          </div>
        </div>
      </Card.Body>
      <Card.Footer>
        <Button
          roundness="rounded"
          color="neutral"
          variant="smooth"
          // disabled={!wallet.total}
          onClick={handleCashOut}
        >
          Cash Out
        </Button>
      </Card.Footer>
    </Card>
  );
};

const ChallengeCard: FC<{ challenge: any }> = ({ challenge }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const { track } = useAnalytics();
  const navigate = useNavigate();
  const { openAlertDialog } = useDialog();

  const handleTaskClick = (task: { to: string; type: string }) => {
    track('Task Clicked', { task });

    const disableAnimate =
      task.to.startsWith('/community') ||
      task.to.startsWith('/discover') ||
      task.to.startsWith('/manage');

    navigate(task.to, {
      animate: !disableAnimate,
    });
  };

  const progress = useMemo(() => {
    const totalTasks = challenge.tasks.length;
    const completedTasks = challenge.tasks.filter((task) => task.completedAt).length;

    return Math.round((completedTasks / totalTasks) * 100);
  }, [challenge.tasks]);

  const action = useMemo(() => {
    if (!challenge.completedAt && challenge.payout) {
      return (
        <Button
          color="danger"
          size="sm"
          startIcon={<FontAwesomeIcon name="info-circle" />}
          style={{ borderRadius: 8 }}
          showHighlight
          onClick={() => {
            track('Payout Challenge Info Clicked');
            openAlertDialog({
              title: 'Paid Mini-Challenge',
              text: `Complete all the tasks in this mini-challenge to earn a $${(challenge.payout / 100).toFixed(2)} tip!`,
            });
          }}
        >
          {`Earn a $${challenge.payout / 100} Tip`}
        </Button>
      );
    }

    if (challenge.points || challenge.payout) {
      return (
        <Chip
          variant="outlined"
          label={
            challenge.payout
              ? `+ $${(challenge.payout / 100).toFixed(2)}`
              : `+ ${challenge.points} Points`
          }
        />
      );
    }

    return undefined;
  }, [challenge]);

  return (
    <Card>
      <Card.Header title={challenge.title} action={action} />
      <Card.Body>
        <Progressbar
          style={{ height: 10, borderRadius: 500, marginBottom: '.5rem' }}
          progress={progress}
        />
        {mapStringToParagraphs(challenge.description)}

        {(!challenge.completedAt || (challenge.completedAt && isExpanded)) && (
          <>
            <hr className="opacity-25" />
            <div className="d-grid mt-3 gap-3">
              {challenge.tasks.map((task) => (
                <div className="d-flex gap-2">
                  <div className="fs-5" style={{ height: 20 }}>
                    {task.completedAt ? (
                      <FontAwesomeIcon name="check-circle" className="color-primary" />
                    ) : (
                      <FontAwesomeIcon faStyle="far" name="circle" />
                    )}
                  </div>
                  <div className="flex-grow-1">
                    <div className="fs-6" style={{ paddingTop: 2 }}>
                      <strong>{task.title}</strong>
                    </div>
                    <div>{task.description}</div>
                    <div className="mt-2">
                      <Button
                        roundness="rounded"
                        disabled={task.completedAt}
                        onClick={() => handleTaskClick(task)}
                      >
                        {task.ctaText}
                      </Button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </>
        )}
      </Card.Body>
      {challenge.completedAt && !isExpanded && (
        <Card.Footer>
          <Button
            variant="smooth"
            color="neutral"
            roundness="rounded"
            onClick={() => setIsExpanded(true)}
          >
            Details
          </Button>
        </Card.Footer>
      )}
    </Card>
  );
};

const TAB_OPTION_RECORD = {
  active: 'Active',
  completed: 'Completed',
};
type TabOption = keyof typeof TAB_OPTION_RECORD;

const TAB_OPTIONS = Object.entries(TAB_OPTION_RECORD).map(([value, label]) => ({ value, label }));

export const ChallengesScreen: FC<{}> = () => {
  const { track } = useAnalytics();
  const [currentTab, setCurrentTab] = useState<TabOption>('active');
  const { challenges, challengesAreReady, refetchChallenges } = useUserChallengesQuery();
  const { currentCity } = useCurrentUserCity();
  const {
    isFetched: sweepstakesAreReady,
    sweepstakes,
    refetch: refetchSweepstakes,
  } = useSweepstakes();
  const navigate = useNavigate();
  const { createAuthGateHandler } = useAuthGate();

  const challengesByStatus = useMemo(() => {
    if (!challenges) {
      return {
        active: [],
        completed: [],
      };
    }

    return {
      active: challenges.filter((challenge) => !challenge.completedAt),
      completed: challenges.filter((challenge) => challenge.completedAt),
    };
  }, [challenges]);

  const filteredChallenges = challengesByStatus[currentTab];

  const handleLogin = () => {
    createAuthGateHandler(() => {}, {
      entertainerCallback: () => {},
      cityId: currentCity?._id,
    });
  };

  const handleRevisit = () => {
    openExternalUrl('https://gaggl.app/download');
  };

  const { handleShareSweepstakesReferralLink } = useSweepstakesReferralLink({
    cityId: currentCity?._id,
  });

  const handleRecommend = () => {
    navigate(`/community`);
  };

  const handleAddEvent = () => {
    navigate(`/community/shows`);
  };

  return (
    <Screen
      name="Challenges Screen"
      onEnter={() => {
        refetchChallenges();
      }}
      onReenter={() => {
        refetchChallenges();
        refetchSweepstakes();
      }}
    >
      <Screen.Content maxWidth={425}>
        {sweepstakesAreReady &&
          (sweepstakes ? (
            <SweepstakesCard
              isCardVisible
              onLogin={handleLogin}
              onRevisit={handleRevisit}
              onRefer={handleShareSweepstakesReferralLink}
              onRecommend={handleRecommend}
              onAddEvent={handleAddEvent}
            />
          ) : (
            <>
              <TitleToolbar text="Mini-Challenges 🏆" />

              <ButtonFilter
                options={TAB_OPTIONS.map(({ value, label }) => ({
                  value,
                  label,
                }))}
                selected={currentTab}
                onChange={(updatedSearchType: TabOption) => {
                  track(`Switch to ${TAB_OPTION_RECORD[updatedSearchType]} Challenges`);
                  setCurrentTab(updatedSearchType);
                }}
              />

              {challengesAreReady ? (
                <div>
                  {filteredChallenges.length ? (
                    filteredChallenges.map((challenge) => (
                      <ChallengeCard key={challenge._id} challenge={challenge} />
                    ))
                  ) : (
                    <EmptyState
                      title={`No ${TAB_OPTION_RECORD[currentTab]} Mini-Challenges`}
                      text={
                        currentTab === 'active'
                          ? 'Check back later for more mini-challenges to improve your changes of winning.'
                          : "You haven't completed any mini-challenges yet. Head back to your active mini-challenges for tasks to do."
                      }
                    />
                  )}
                </div>
              ) : (
                <>
                  <Skeleton height={200} />
                  <Skeleton height={200} />
                  <Skeleton height={200} />
                  <Skeleton height={200} />
                </>
              )}
            </>
          ))}
      </Screen.Content>
    </Screen>
  );
};
