import * as Sentry from '@sentry/react';
import { ComponentType, FC, useEffect, useState } from 'react';
import PhoneInput, { formatPhoneNumber, isValidPhoneNumber } from 'react-phone-number-input';
import flags from 'react-phone-number-input/flags';
import 'react-phone-number-input/style.css';

import { useAppContext } from '@contexts/AppContext';
import { Autoplay, Pagination } from 'swiper';

import { useIsValidTokenQuery } from '@queries/UserQueries';
import { useMagic } from '@queries/index';

import { useAuthWithMagicLink, useValidateAuthWithMagicLink } from '@mutations/MagicLinkMutations';

import { useAnalytics } from '@hooks/useAnalytics';
import { useOneSignal } from '@hooks/useOneSignal';

import { openExternalUrl } from '@utilities/openExternalUrl';

import Button from '@components/buttons/Button';
import { Input } from '@components/form/Input';
import { FontAwesomeIcon } from '@components/icons/FontAwesomeIcon';
import Card from '@components/layout/Card/Card';
import { Popup } from '@components/layout/Popup/Popup';
import { Screen } from '@components/layout/Screen';
import { Swiper } from '@components/layout/Swiper/Swiper';
import { TitleToolbar } from '@components/layout/TitleToolbar/TitleToolbar';

import { HomeScreen } from '../HomeScreen/HomeScreen';
import { AuthPopupEntertainer } from './EntertainerLoginContent';
import './LogInScreen.scss';
import CritiqueScreenshot from './images/critique-screenshot.png';
import InterestScreenshot from './images/interest-screenshot.png';
import UpcomingScreenshot from './images/upcoming-screenshot.png';

const Slides = () => {
  const slides = [
    {
      image: CritiqueScreenshot,
      title: 'Critique local queer events',
      description: 'Easily share your thoughts about the local queer scene',
    },
    {
      image: UpcomingScreenshot,
      title: "Track what's happening near you",
      description: "With easy filters see what's happening in your city",
    },
    {
      image: InterestScreenshot,
      title: 'Find the right events that interest you',
      description: 'Follow your favorite venues or interests to find the right events for you',
    },
  ];

  return (
    <Swiper
      modules={[Pagination, Autoplay]}
      autoplay={{
        delay: 5000,
        disableOnInteraction: false,
      }}
      pagination
    >
      {slides.map(({ image, title, description }, i) => (
        <Swiper.Slide key={i}>
          <div className="w-100 text-center pb-4">
            <div className="p-4 slide-img-wrapper">
              <img src={image} alt={title} className="d-block w-auto" />
            </div>
            <h3>{title}</h3>
            {/* <p>{description}</p> */}
          </div>
        </Swiper.Slide>
      ))}
    </Swiper>
  );
};

export const useAuthState = () => {
  const [isAuthorizing, setIsAuthorizing] = useState(false);
  const [userId, setUserId] = useState('');
  const [code, setCode] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const { track } = useAnalytics();

  const { authWithMagicLinkAsync } = useAuthWithMagicLink();
  const handleInternalPhoneLogIn = async () => {
    console.log('handleInternalPhoneLogIn');
    setIsAuthorizing(true);

    track('Log In With Phone Number', {
      phoneNumber,
    });

    try {
      const response = await authWithMagicLinkAsync({ phoneNumber });
      setUserId(response.userId);
    } catch (e) {
      Sentry.captureException(e);
      console.log(e);
    }

    setIsAuthorizing(false);
  };

  return {
    isAuthorizing,
    setIsAuthorizing,
    userId,
    setUserId,
    code,
    setCode,
    phoneNumber,
    setPhoneNumber,
    handleInternalPhoneLogIn,
  };
};
export type AuthState = ReturnType<typeof useAuthState>;

const PhoneInputContent = ({
  setPhoneNumber,
  handleInternalPhoneLogIn,
  isAuthorizing,
  phoneNumber,
  loginButtonText = 'Log in',
}: Pick<
  AuthState,
  'setPhoneNumber' | 'handleInternalPhoneLogIn' | 'isAuthorizing' | 'phoneNumber'
> & {
  loginButtonText?: string;
}) => {
  const handleChange = (value: string = '') => {
    setPhoneNumber(value);
  };

  return (
    <>
      <div className="grid gap-2">
        <PhoneInput
          placeholder="Phone Number"
          defaultCountry="US"
          flags={flags}
          value={phoneNumber}
          onChange={handleChange}
          inputComponent={Input}
        />
        <Button
          roundness="rounded"
          onClick={handleInternalPhoneLogIn}
          disabled={isAuthorizing || !isValidPhoneNumber(phoneNumber)}
        >
          {loginButtonText}
        </Button>
      </div>
      <p className="text-center" style={{ fontSize: 12 }}>
        {`By signing up, you agree to receive notifications from Gaggl at the provided number. Msg & data rates may apply. Frequency varies. Reply STOP to opt out, HELP for help. `}
        <strong onClick={() => openExternalUrl('https://gaggl.app/terms')}>Terms</strong>
        {' & '}
        <strong onClick={() => openExternalUrl('https://gaggl.app/privacy-policy')}>
          Privacy Policy
        </strong>
        .
      </p>
    </>
  );
};

const DEFAULT_AUTH_POPUP_MESSAGE =
  "This requires signing in. Don't worry, it's simple. Enter your phone number, and we'll send you a one-time password. This will give you access to all the features Gaggl offers, including discovering queer events, managing gigs as a performer, and connecting with performers if you're a producer.";

export interface AuthPopupProps
  extends Pick<
    AuthState,
    | 'phoneNumber'
    | 'setPhoneNumber'
    | 'userId'
    | 'setUserId'
    | 'code'
    | 'setCode'
    | 'isAuthorizing'
    | 'handleInternalPhoneLogIn'
  > {
  isVisible: boolean;
  onClose: () => void;
  redirectOverride?: () => void;
  entertainerCallback?: (entertainerProperties: AuthPopupEntertainer) => void;
  authMessage?: string;
  loginButtonText?: string;
  CustomLoginContentComponent?: ComponentType<AuthPopupProps>;
  options?: {
    cityId?: string;
  };
}

const DefaultAuthPopupLoginContent: FC<AuthPopupProps> = ({
  authMessage = DEFAULT_AUTH_POPUP_MESSAGE,
  ...props
}) => {
  return (
    <>
      <TitleToolbar text="Continue with Gaggl!" size="md" />
      <p className="mt-0">{authMessage}</p>
      <PhoneInputContent {...props} />
    </>
  );
};

export const AuthPopup: FC<AuthPopupProps> = (props) => {
  const {
    isVisible,
    onClose,
    phoneNumber,
    userId,
    code,
    setUserId,
    setCode,
    isAuthorizing,
    handleInternalPhoneLogIn,
    redirectOverride,
    CustomLoginContentComponent,
  } = props;
  const { navigateAfterLogin, updateToken } = useAppContext();

  const { isSubmitting, validateAuthWithMagicLinkAsync } = useValidateAuthWithMagicLink();
  const handleValidateCode = async () => {
    try {
      const { token } = await validateAuthWithMagicLinkAsync({ userId, code });
      updateToken(token);
      setUserId('');
      onClose();

      if (redirectOverride) {
        redirectOverride();
        return;
      }

      await navigateAfterLogin();
    } catch (e) {}
    setCode('');
  };
  useEffect(() => {
    if (userId && code.length === 6 && !isSubmitting) {
      handleValidateCode();
    }
  }, [userId, code]);

  return (
    <Popup isVisible={isVisible} onClose={onClose}>
      <Popup.Content>
        {userId ? (
          <div className="login-popup text-center">
            <span className="login-popup_icon">
              <FontAwesomeIcon faStyle="far" name="comment-dots" />
            </span>
            <p className="fs-3">
              Please enter the code sent to{' '}
              <strong className="d-inline-block">{formatPhoneNumber(phoneNumber)}</strong>
            </p>
            {userId && (
              <Input
                focusOnMount
                className="code-field"
                disabled={isSubmitting}
                placeholder="------"
                inputMode="numeric"
                onChange={(e) => setCode(e.target.value)}
                maxLength={6}
              />
            )}
            <div className="mt-2">
              <Button
                variant="flat"
                color="neutral"
                disabled={isAuthorizing}
                onClick={handleInternalPhoneLogIn}
              >
                Send a new code
              </Button>
            </div>
          </div>
        ) : (
          <>
            {CustomLoginContentComponent ? (
              <CustomLoginContentComponent {...props} />
            ) : (
              <DefaultAuthPopupLoginContent {...props} />
            )}
          </>
        )}
      </Popup.Content>
    </Popup>
  );
};

export const LogInScreen = () => {
  const authState = useAuthState();
  const { isAuthorizing, setIsAuthorizing, phoneNumber, setPhoneNumber, handleInternalPhoneLogIn } =
    authState;
  const { loginWithSms } = useMagic();
  const { initOneSignal } = useOneSignal();
  const { tokenIsValidIsReady, tokenIsValid } = useIsValidTokenQuery();
  const { navigateAfterLogin } = useAppContext();

  const handlePhoneLogIn = async () => {
    setIsAuthorizing(true);

    try {
      await loginWithSms(phoneNumber);
      initOneSignal();
      await navigateAfterLogin();
    } catch (e) {
      console.log(e);
    }

    setIsAuthorizing(false);
  };

  useEffect(() => {
    if (tokenIsValidIsReady && tokenIsValid) {
      navigateAfterLogin();
    }
  }, [tokenIsValidIsReady, tokenIsValid]);

  return (
    <Screen name="Log In Screen" hideToolbar>
      <Screen.Content maxWidth={425}>
        <TitleToolbar text="Welcome to Gaggl!" />
        <Slides />
        <PhoneInputContent {...authState} />
      </Screen.Content>
      <AuthPopup
        isVisible={!!authState.userId}
        onClose={() => authState.setUserId('')}
        {...authState}
      />
    </Screen>
  );
};
