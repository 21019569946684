import { FC, useMemo } from 'react';
import { PieChart, PieChartProps } from 'react-minimal-pie-chart';

import { sumBy } from 'lodash';

export const emptyChartProps = {
  rounded: false,
  data: [{ key: 'empty', value: 100, color: 'rgba(241,241,241, .2)' }],
  label: undefined,
  labelStyle: undefined,
  paddingAngle: 0,
};

interface AdvancedPieChartProps extends Omit<PieChartProps, 'data'> {
  data: { key: string; value: number; color: string }[];
}

export const AdvancedPieChart: FC<AdvancedPieChartProps> = ({ data, ...props }) => {
  const pieChartData: { key: string; value: number; color: string }[] = useMemo(() => {
    const total = sumBy(data, 'value');

    return data
      .filter(({ value }) => value)
      .map(({ key, value, color }) => ({
        key,
        value: Math.round((value / total) * 100),
        color,
      }));
  }, [data]);

  return (
    <PieChart
      rounded
      lineWidth={25}
      radius={50}
      data={pieChartData}
      paddingAngle={25}
      {...(pieChartData.length > 0 ? {} : emptyChartProps)}
      {...props}
      // label={({ dataEntry }) => `${dataEntry.value}%`}
      // labelStyle={(index) => ({
      //   fill: pieChartData[index].color,
      //   fontSize: '12px',
      //   fontWeight: 'bold',
      //   fontFamily: 'Rubik',
      //   letterSpacing: 1,
      // })}
    />
  );
};
