import { FC, useRef, useState } from 'react';

import { useAppContext } from '@contexts/AppContext';
import Markdown from 'markdown-to-jsx';

import {
  useCurrentEntertainerQuery,
  useFetchCurrentEntertainer,
} from '@queries/EntertainerQueries';
import { useCurrentUserSweepstakesEntries, useSweepstakes } from '@queries/SweepstakesQueries';

import { useAnalytics } from '@hooks/useAnalytics';
import { useShareLink } from '@hooks/useShareLink';

import { openExternalUrl } from '@utilities/openExternalUrl';

import Button from '@components/buttons/Button';
import IconButton from '@components/buttons/IconButton';
import { AdvancedPieChart } from '@components/charts/AdvancedPieChart';
import { Chip } from '@components/chips/Chip';
import { FontAwesomeIcon } from '@components/icons/FontAwesomeIcon';
import Card from '@components/layout/Card/Card';
import { useDialog } from '@components/layout/Dialog/useDialog';

import { useAuthGate } from '@screens/LogInScreen/AuthGateContext';

const officialRules = `1. **Eligibility:** Open to legal residents of the United States who are 18+ and active drag performers with a Gaggl account. Void where prohibited.
2. **How to Enter:** Enter by completing any of the actions listed above. Each completed action earns entries into the sweepstakes. No purchase necessary.
3. **Entry Period:** The sweepstakes will close 24 hours after the 100th eligible participant has joined. Entries received after that time will not be counted.
4. **Prize:** One winner will receive a $250 tip added directly to their Gaggl wallet. Funds can be withdrawn via Venmo. No cash alternative or prize substitution allowed.
5. **Winner Selection:** One winner will be randomly selected from all eligible entries. Odds depend on the number of entries received. The $250 tip will be automatically added to the winner’s Gaggl wallet—no action required to claim.
6. **General Conditions:** By entering, participants agree to these rules and the decisions of Gaggl, which are final. Gaggl may disqualify anyone suspected of tampering or violating the rules.
7. **Modification Clause:** Gaggl reserves the right to update or modify these rules at any time, including adjusting the total number of required participants, provided that the changes do not materially disadvantage participants who have already entered. Any updates will be announced publicly and reflected in the official rules.
8. **Release:** Entrants release and hold harmless Gaggl and its affiliates from any liability related to participation or prize acceptance.
9. **Sponsor:** Sweepstakes is sponsored by Gaggl, hosted at gaggl.app.`;

export const useSweepstakesReferralLink = ({ cityId }: { cityId: string }) => {
  const { entertainer } = useCurrentEntertainerQuery();
  const fetchCurrentEntertainer = useFetchCurrentEntertainer();

  const { createAuthGateHandler } = useAuthGate();
  const { shareLink } = useShareLink();
  const { openConfirmDialog } = useDialog();
  const handleShareSweepstakesReferralLink = async () => {
    if (entertainer?.sweepstakesReferralUrl) {
      shareLink({ url: entertainer.sweepstakesReferralUrl });
      return;
    }

    createAuthGateHandler(
      async () => {
        let scopedEntertainer = entertainer;

        if (!scopedEntertainer) {
          const { entertainer: updatedEntertainer } = await fetchCurrentEntertainer();
          scopedEntertainer = updatedEntertainer;
        }

        if (scopedEntertainer?.sweepstakesReferralUrl) {
          openConfirmDialog({
            title: 'Share Link',
            text: 'Earn 10,000 entries for each entertainer who joins the sweepstakes through your referral link.',
            buttonOk: 'Share',
            onConfirm() {
              shareLink({ url: scopedEntertainer.sweepstakesReferralUrl });
            },
          });
        }
      },
      {
        entertainerCallback: () => {},
        cityId,
      },
    );
  };

  return { handleShareSweepstakesReferralLink };
};

export const SweepstakesCard: FC<{
  isCardVisible?: boolean;
  onLogin: () => void;
  onRevisit: () => void;
  onRefer: (event) => void;
  onRecommend: () => void;
  onAddEvent: () => void;
}> = ({
  isCardVisible: initialIsCardVisible,
  onLogin,
  onRefer,
  onRevisit,
  onRecommend,
  onAddEvent,
}) => {
  const [isCardVisible, setIsCardVisible] = useState(initialIsCardVisible);
  const [areRulesVisible, setAreRulesVisible] = useState(false);
  const { entertainer } = useCurrentEntertainerQuery();
  const { track } = useAnalytics();
  const { sweepstakesEntries, total: currentUserEntryCount = 0 } =
    useCurrentUserSweepstakesEntries();
  const { sweepstakes, totalParticipants, totalEntries } = useSweepstakes();
  const { shareLink } = useShareLink();
  const { openConfirmDialog } = useDialog();

  const otherUsersEntryCount = Math.max(0, totalEntries - currentUserEntryCount);
  const percentChanceOfWinning = Math.round((currentUserEntryCount / totalEntries) * 100);
  const userIsOptedIn = !!sweepstakesEntries?.find(({ reason }) => reason === 'InitialVisit');

  if (!sweepstakes) {
    return <></>;
  }

  if (!isCardVisible) {
    return (
      <Button
        roundness="rounded"
        variant="smooth"
        color="neutral"
        onClick={() => {
          track('Show Sweepstakes Details');
          setIsCardVisible(true);
        }}
        contentElement={
          <div className="d-flex align-items-center w-100">
            <span>💰</span>
            <span className="px-2">$250 Sweepstakes</span>
            <div className="d-flex align-items-center gap-2 ml-auto">
              <span className="fw-normal">{`${currentUserEntryCount} entries`}</span>
              <FontAwesomeIcon className="ml-auto" name="chevron-down" />
            </div>
          </div>
        }
      />
    );
  }

  const YELLOW_COLOR = '#ffcc00';

  return (
    <Card>
      <Card.Body>
        <div>
          <div className="mb-2">
            <div className="d-flex align-items-center justify-content-between mb-2 fs-6">
              <strong>{sweepstakes.title}</strong>
              {!initialIsCardVisible && (
                <div style={{ margin: '-.5rem 0' }}>
                  <IconButton
                    variant="flat"
                    color="neutral"
                    onClick={() => {
                      track('Hide Sweepstakes Details');
                      setIsCardVisible(false);
                    }}
                  >
                    <FontAwesomeIcon name="chevron-up" />
                  </IconButton>
                </div>
              )}
            </div>
            <div className="d-flex align-items-center gap-3">
              <div className="d-flex flex-column align-items-center">
                <div style={{ width: 80 }}>
                  <AdvancedPieChart
                    data={[
                      {
                        key: 'others',
                        value: otherUsersEntryCount,
                        color: 'rgba(241,241,241, .2)',
                      },
                      { key: 'user', value: currentUserEntryCount, color: YELLOW_COLOR },
                    ]}
                    label={({ dataEntry }) =>
                      dataEntry.key === 'user' ? `${dataEntry.value}%` : ''
                    }
                    labelStyle={() => ({
                      fill: YELLOW_COLOR,
                      fontSize: '12px',
                      fontWeight: 'bold',
                      fontFamily: 'Rubik',
                      letterSpacing: 1,
                    })}
                  />
                </div>
              </div>
              <div>
                <div>{sweepstakes.description}</div>
                <div
                  className="d-flex gap-1 align-items-center mt-2 pb-1"
                  style={{ fontSize: '.7rem', whiteSpace: 'nowrap' }}
                >
                  <span style={{ color: YELLOW_COLOR }}>
                    <FontAwesomeIcon name="circle" />
                  </span>
                  <span>% chance of winning</span>
                </div>
              </div>
            </div>
            <div
              className="d-flex gap-2 mt-2 mb-3"
              style={{ background: 'rgba(0,0,0,.3)', borderRadius: 8 }}
            >
              <div className="d-flex flex-column justify-content-between w-100 py-2">
                <div className="d-flex justify-content-around w-100">
                  <div className="text-align-center">
                    <div className="mb-1">
                      <strong>Your Entries</strong>
                    </div>
                    <div className="fs-6">{currentUserEntryCount}</div>
                  </div>
                  <div className="text-align-center">
                    <div className="mb-1">
                      <strong>Remaining Spots</strong>
                    </div>
                    <div className="fs-6">{`${(sweepstakes?.maxParticipants ?? 0) - totalParticipants} / ${sweepstakes?.maxParticipants ?? 0}`}</div>
                  </div>
                  {/* <div className="text-align-center">
                    <div className="mb-1">
                      <strong>Chances Of Winning</strong>
                    </div>
                    <div className="fs-5">{`${percentChanceOfWinning}%`}</div>
                  </div> */}
                </div>
              </div>
            </div>
            <div>
              <div>
                <strong>How to earn more entries</strong>
              </div>
              <p>
                There are multiple ways to earn entries into the sweepstakes. The more you
                participate, the higher your chances of winning!
              </p>
              <div>
                <ul style={{ paddingInlineStart: '1.25rem' }}>
                  {sweepstakes.tasks.map((sweepstakesTask) => (
                    <li key={sweepstakesTask.title} className="mb-3">
                      <div className="d-flex justify-content-between mb-1">
                        <strong>{sweepstakesTask.title}</strong>
                        <span>{sweepstakesTask.numberOfEntriesLabel}</span>
                      </div>
                      {sweepstakesTask.mobileAdvantageReason && (
                        <div
                          className="px-2 mb-1"
                          style={{ background: `#19b48e`, borderRadius: 4 }}
                          onClick={() => {
                            track('Mobile App Advantage');
                            openConfirmDialog({
                              title: 'Earn more in the Gaggl App',
                              text: `When completing this action in the Gaggl app, you will earn bonus entries. Would you like to download the app?`,
                              buttonOk: 'Download',
                              onConfirm: () => {
                                track('Confirm App Download');
                                openExternalUrl('https://gaggl.app/download');
                              },
                            });
                          }}
                        >
                          <div className="d-flex justify-content-between align-items-center gap-1 w-100">
                            <div className="d-flex align-items-center gap-2">
                              <FontAwesomeIcon name="info-circle" />
                              <span>Mobile App Advantage</span>
                            </div>
                            <strong>
                              {sweepstakesTask.mobileAdvantageReason.numberOfEntriesLabel}
                            </strong>
                          </div>
                        </div>
                      )}
                      <div>{sweepstakesTask.description}</div>
                      <div className="mt-1">
                        <Button
                          roundness="rounded"
                          color="neutral"
                          variant="smooth"
                          disabled={userIsOptedIn && sweepstakesTask.reason === 'InitialVisit'}
                          onClick={(event) => {
                            track('Sweepstakes Task Click', {
                              title: sweepstakesTask.title,
                            });

                            if (sweepstakesTask.action === 'onLogin') {
                              onLogin();
                            } else if (sweepstakesTask.action === 'onRevisit') {
                              onRevisit();
                            } else if (sweepstakesTask.action === 'onRefer') {
                              onRefer(event);
                            } else if (sweepstakesTask.action === 'onRecommend') {
                              onRecommend();
                            } else if (sweepstakesTask.action === 'onAddEvent') {
                              onAddEvent();
                            }
                          }}
                        >
                          {sweepstakesTask.buttonText}
                        </Button>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <hr
              style={{
                opacity: 0.3,
                background: 'white',
                border: 'none',
                height: 1,
                margin: '2rem 0 1.5rem',
              }}
            />
            <div>
              <div
                className="d-flex align-items-center justify-content-between"
                onClick={() => {
                  setAreRulesVisible((prevValue) => !prevValue);
                }}
              >
                <div>
                  <strong>Official Rules</strong>
                </div>
                <div style={{ margin: '-1rem 0' }}>
                  <IconButton variant="flat" color="neutral">
                    <FontAwesomeIcon name={areRulesVisible ? 'chevron-up' : 'chevron-down'} />
                  </IconButton>
                </div>
              </div>
              {areRulesVisible && (
                <Markdown className="markdown-block" options={{ forceWrapper: true }}>
                  {sweepstakes.rules}
                </Markdown>
              )}
            </div>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};
