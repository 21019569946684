import { Badge, Chip as F7Chip } from 'framework7-react';
import { ReactElement, ReactNode, useLayoutEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';

import './Chip.scss';

export const Chip = ({
  className = '',
  startIcon,
  label,
  disabled = false,
  color = 'default',
  variant = 'default',
  size = 'md',
  badge,
  notificationBadge,
  onClick,
  onRemove,
}: {
  className?: string;
  startIcon?: ReactNode;
  label: string | ReactElement;
  disabled?: boolean;
  color?: 'default' | 'neutral' | 'green' | 'orange' | 'deeppurple';
  variant?: 'default' | 'outlined';
  size?: 'sm' | 'md' | 'lg';
  badge?: number;
  notificationBadge?: boolean;
  onClick?: () => void;
  onRemove?: () => void;
}) => {
  const ref = useRef<{ el: HTMLDivElement }>();
  const classNamesAppended = `chip chip-color-${color} chip-variant-${variant} chip-size-${size} ${disabled ? 'disabled' : ''} ${className}`;

  const [isMounted, setIsMounted] = useState(false);

  useLayoutEffect(() => {
    setIsMounted(true);

    return () => {
      setIsMounted(false);
    };
  }, []);

  return (
    <F7Chip
      ref={ref}
      className={classNamesAppended}
      text={typeof label === 'string' ? label : ''}
      outline={variant === 'outlined'}
      onClick={onClick}
      deleteable={!!onRemove}
      onDelete={onRemove}
      color={color}
    >
      {startIcon && <div slot="media">{startIcon}</div>}
      {typeof label !== 'string' ? label : undefined}
      {badge && <Badge>{badge}</Badge>}
      {notificationBadge &&
        isMounted &&
        createPortal(<Badge className="notification-badge" color="red" />, ref.current?.el)}
    </F7Chip>
  );
};
