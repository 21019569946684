import { useQueryClient } from '@tanstack/react-query';
import { Fab, ListInput } from 'framework7-react';
import { Input as F7Input } from 'framework7-react';
import { FC, Fragment, useEffect, useMemo, useState } from 'react';

import { useAppContext } from '@contexts/AppContext';
import { use } from 'chai';
import { isUndefined } from 'cypress/types/lodash';
import * as dayjs from 'dayjs';
import { AnimatePresence, motion } from 'framer-motion';
import { omitBy } from 'lodash';

import {
  useCurrentEntertainerQuery,
  useFetchCurrentEntertainer,
  usePrioritizedEntertainersQuery,
  useTopEntertainersQuery,
} from '@queries/EntertainerQueries';
import { FeedEvent, useEventQuery, useUnauthorizedEventsQuery } from '@queries/EventQueries';
import { FeedPost, useEntertainerFeedPostsQuery, useFetchNextPosts } from '@queries/PostQueries';
import { Series, useSeriesByTagSlug, useSeriesEntertainersQuery } from '@queries/TagQueries';
import { useCurrentUser, useInterestQuery } from '@queries/UserQueries';

import {
  useCreateEntertainerEventMutation,
  useEntertainerJoinSeriesMutation,
} from '@mutations/EntertainerMutations';
import { useUpdateSeriesEntertainersMutation } from '@mutations/TagMutations';

import { useAnalytics } from '@hooks/useAnalytics';
import { useCityById } from '@hooks/useCurrentUserLocation';
import { useDebounce } from '@hooks/useDebounce';
import { useEventFilters } from '@hooks/useEventFilters';
import { useShareLink } from '@hooks/useShareLink';

import { useLocation, useNavigate, useParams, useRouter } from '@router/index';

import { createScreenComponent } from '@utilities/createScreenComponent';
import { mapStringToParagraphs } from '@utilities/mapStringToParagraphs';
import { openExternalUrl } from '@utilities/openExternalUrl';

import Button from '@components/buttons/Button';
import IconButton from '@components/buttons/IconButton';
import { ShareLinkButton } from '@components/buttons/ShareLinkButton';
import EventCard, {
  EventActions,
  EventHeader,
  EventImage,
  PromoterHeader,
} from '@components/cards/EventCard';
import { PostCard } from '@components/cards/PostCard/PostCard';
import { VenueCard } from '@components/cards/VenueCard';
import { ChipRadioOptions } from '@components/chips/ChipRadioOptions';
import { DatePicker } from '@components/form/DatePicker';
import { DateRangePicker } from '@components/form/DateRangePicker';
import { Input } from '@components/form/Input';
import { SearchField } from '@components/form/SearchField/SearchField';
import { FontAwesomeIcon } from '@components/icons/FontAwesomeIcon';
import { ButtonFilter } from '@components/layout/ButtonFilter/ButtonFilter';
import { useDialog } from '@components/layout/Dialog/useDialog';
import { EmptyState } from '@components/layout/EmptyState/EmptyState';
import { HorizontalScroll } from '@components/layout/HorizontalScroll/HorizontalScroll';
import { LinkBanner } from '@components/layout/LinkBanner/LinkBanner';
import { Screen } from '@components/layout/Screen/Screen';
import { Skeleton } from '@components/layout/Skeleton/Skeleton';
import { TitleToolbar } from '@components/layout/TitleToolbar/TitleToolbar';

import { ShowsTabContent } from '@screens/CommunityScreen/CommunityScreen';
import { CreateEditEventPopup } from '@screens/CreateEditEventScreen/CreateEditEventForm';
import { useAuthGate } from '@screens/LogInScreen/AuthGateContext';
import { EntertainerAvailabilityCard } from '@screens/booking/GigsAndTalentScreen/EntertainerAvailabilityCard';
import { RecommendTabContent } from '@screens/booking/GigsAndTalentScreen/GigsAndTalentScreen';
import { MultiSelectField } from '@screens/entertainer/components/CitiesField';

import { SeriesEventCard } from '../components/SeriesEventCard';
import { SweepstakesCard, useSweepstakesReferralLink } from './SweepstakesCard';

const CAN_ADD_EVENTS = true;

interface SeriesScreenProps {
  tagSlug: string;
}

const EventManagementSection: FC<{ tagSlug: string; onCanAddEvent: () => void }> = ({
  tagSlug,
  onCanAddEvent,
}) => {
  const navigate = useNavigate();
  const { track } = useAnalytics();
  const [browseIsVisible, setBrowseIsVisible] = useState(false);
  const [searchParams, setSearchParams] = useState<
    Partial<{ search: string; startDate?: string; endDate?: string }>
  >({});

  const { openConfirmDialog } = useDialog();
  const { createAuthGateHandler } = useAuthGate();
  const { userIsLoggedIn } = useAppContext();
  const { entertainer } = useCurrentEntertainerQuery();
  const fetchCurrentEntertainer = useFetchCurrentEntertainer();

  const handleSearch = () => {
    const queryString = new URLSearchParams(searchParams).toString();

    navigate(`/noauth/series/${tagSlug}/events?${queryString}`);
  };

  const handleAddEvent = () => {
    if (userIsLoggedIn && entertainer?._id) {
      onCanAddEvent();
    } else {
      track('Open Add Event Confirm Dialog');
      openConfirmDialog({
        title: 'Add An Event',
        text: 'We currently only allow entertainers to add events. Would you like to continue as an entertainer?',
        buttonOk: 'Yes',
        onConfirm: () => {
          createAuthGateHandler(async () => {
            const { entertainer: currentEntertainer } = await fetchCurrentEntertainer();

            if (currentEntertainer._id) {
              onCanAddEvent();
            } else {
              navigate('/entertainer/onboarding', {
                state: {
                  onCompletePath: `/noauth/series/${tagSlug}?addEvent=true`,
                },
              });
            }
          });
        },
      });
    }
  };

  return (
    <div className="position-relative">
      <div className="d-flex gap-2">
        <Button
          startIcon={<FontAwesomeIcon name="search" />}
          roundness="rounded"
          onClick={() => setBrowseIsVisible(true)}
        >
          Browse Events
        </Button>
        {CAN_ADD_EVENTS && (
          <Button
            startIcon={<FontAwesomeIcon name="plus" />}
            variant="outlined"
            color="neutral"
            roundness="rounded"
            onClick={handleAddEvent}
          >
            Add Event
          </Button>
        )}
      </div>

      <AnimatePresence>
        {browseIsVisible && (
          <motion.div
            className="position-absolute w-100 z-3 top-0 pb-4"
            style={{ background: '#000' }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <div className="d-grid gap-2">
              <div className="w-100">
                <Input
                  placeholder="All events"
                  onChange={(e) =>
                    setSearchParams((prevValue) => ({
                      ...prevValue,
                      search: e.target.value,
                    }))
                  }
                  onSubmit={handleSearch}
                />
              </div>
              <div className="w-100">
                <F7Input
                  type="datepicker"
                  placeholder="Any dates"
                  readonly
                  calendarParams={{ rangePicker: true, dateFormat: 'M d', minDate: new Date() }}
                  onCalendarChange={([startDateObj, endDateObj]) => {
                    const startDate = startDateObj
                      ? dayjs(startDateObj).format('YYYY-MM-DD')
                      : undefined;
                    const endDate = endDateObj ? dayjs(endDateObj).format('YYYY-MM-DD') : undefined;

                    setSearchParams((prevValue) => ({
                      ...prevValue,
                      startDate,
                      endDate,
                    }));
                  }}
                />
              </div>
              <div className="d-flex gap-2">
                <Button
                  roundness="rounded"
                  startIcon={<FontAwesomeIcon name="search" />}
                  onClick={handleSearch}
                >
                  Search Events
                </Button>
                <Button
                  roundness="rounded"
                  color="neutral"
                  variant="smooth"
                  startIcon={<FontAwesomeIcon name="times" />}
                  onClick={() => setBrowseIsVisible(false)}
                >
                  Close
                </Button>
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

const FeaturedEventsSection: FC<{
  tagId: string | undefined;
  filterTags: { value: string; label: string }[] | undefined;
  cityId: string | undefined;
  onEventClick: (eventId: string) => void;
}> = ({ tagId = '', filterTags = [], cityId = '', onEventClick }) => {
  const [eventFilter, setEventFilter] = useState<string>('all');
  const { eventsAreReady, events = [] } = useUnauthorizedEventsQuery(
    {
      cityId,
      tagIds: [tagId, ...(eventFilter !== 'all' ? [eventFilter] : [])],
      startDate: dayjs().format('YYYY-MM-DD'),
      featured: 'true',
      limit: '5',
      rsvpData: 'ignore',
    },
    Boolean(cityId && tagId),
  );

  return (
    <div className="mt-4">
      <TitleToolbar text="Featured Events ✨" size="md" />
      {!!filterTags.length && (
        <HorizontalScroll
          items={[
            <ChipRadioOptions
              value={eventFilter}
              onChange={(value) => setEventFilter(value)}
              options={[{ value: 'all', label: 'All Featured' }, ...filterTags].map(
                ({ value, label }) => ({
                  value,
                  label,
                  // showBadge: Boolean(value === 'suggested' && events?.suggested?.length),
                }),
              )}
            />,
          ]}
        />
      )}
      {eventsAreReady ? (
        <>
          {events.length ? (
            <HorizontalScroll
              items={events.map((event) => (
                <SeriesEventCard
                  key={event._id}
                  event={event}
                  onClick={() => onEventClick(event._id)}
                />
              ))}
            />
          ) : (
            <EmptyState title="No upcoming events" text="Check back later for more events" />
          )}
        </>
      ) : (
        <Skeleton height={360} />
      )}
    </div>
  );
};

const UpcomingEventsSection: FC<{
  tagId: string | undefined;
  cityId: string | undefined;
  onEventClick: (eventId: string) => void;
}> = ({ tagId = '', cityId = '', onEventClick }) => {
  const today = dayjs().format('YYYY-MM-DD');
  const { eventDateFilterOptions, eventFilters, updateDateFilter } = useEventFilters({
    dateRange: [today, today],
  });
  const [startDate, endDate] = eventFilters.dateRange;
  const { eventsAreReady, events = [] } = useUnauthorizedEventsQuery(
    {
      startDate,
      endDate,
      tagIds: [tagId],
      cityId,
      limit: '5',
      rsvpData: 'ignore',
    },
    Boolean(cityId && tagId),
  );

  const handleDateFilterChange = (value: string) => {
    const selectedOption = eventDateFilterOptions.find((option) => option.value === value);

    if (selectedOption) {
      const [startDate, endDate] = selectedOption.getRange();
      // track('Update Date Filter', {
      //   value,
      //   startDate,
      //   endDate,
      // });
      updateDateFilter([startDate, endDate]);
    }
  };

  return (
    <div className="mt-4">
      <TitleToolbar text="Upcoming Events 🎉" size="md" />
      <HorizontalScroll
        items={[
          <ChipRadioOptions
            value={eventFilters.dateFilter}
            onChange={handleDateFilterChange}
            options={eventDateFilterOptions}
          />,
        ]}
      />
      {eventsAreReady ? (
        <>
          {events.length ? (
            <HorizontalScroll
              items={events.map((event) => (
                <SeriesEventCard
                  key={event._id}
                  event={event}
                  onClick={() => onEventClick(event._id)}
                />
              ))}
            />
          ) : (
            <EmptyState title="No upcoming events" text="Check back later for more events" />
          )}
        </>
      ) : (
        <Skeleton height={360} />
      )}
    </div>
  );
};

const UpdateEntertainerList = ({
  seriesId,
  cityId,
  entertainers,
}: {
  seriesId;
  cityId: string;
  entertainers: { _id: string }[];
}) => {
  const [entertainerIds, setEntertainerIds] = useState<string[]>(
    entertainers?.map(({ _id }) => _id) ?? [],
  );

  const { topEntertainersAreReady, topEntertainers = [] } = useTopEntertainersQuery(
    cityId,
    'dragPerformer',
    true,
  );

  const { isUpdatingSeriesEntertainers, updateSeriesEntertainersAsync } =
    useUpdateSeriesEntertainersMutation(seriesId);

  const handleSave = () => {
    updateSeriesEntertainersAsync({ entertainerIds });
  };

  return (
    <div className="d-grid gap-1 mb-2">
      {topEntertainersAreReady ? (
        <MultiSelectField
          label="Drag Performers"
          name="entertainerIds"
          allowSearch
          defaultValue={entertainerIds}
          options={topEntertainers
            .map(({ _id, title }) => ({
              value: _id,
              label: title,
            }))
            .sort((a, b) => a.label.localeCompare(b.label))}
          onChange={setEntertainerIds}
        />
      ) : (
        <Skeleton height={64} />
      )}

      <Button
        roundness="rounded"
        color="neutral"
        variant="smooth"
        disabled={isUpdatingSeriesEntertainers}
        onClick={() => {
          handleSave();
        }}
      >
        Update List
      </Button>
    </div>
  );
};

const SeriesEventSections: FC<{
  tagSlug: string;
  series?: Series;
  onAddEvent?: () => void;
}> = ({ tagSlug, series, onAddEvent }) => {
  const { userIsLoggedIn } = useAppContext();
  const { track } = useAnalytics();
  const { navigate, back } = useRouter();

  const handleViewEvent = (eventId: string) => {
    navigate(`/events/${eventId}`);
  };

  return (
    <>
      <EventManagementSection tagSlug={tagSlug} onCanAddEvent={onAddEvent} />

      <div className="mt-4">
        {userIsLoggedIn ? (
          <LinkBanner
            showHighlight
            icon={<FontAwesomeIcon name="comment" />}
            title="Live Feed"
            description={`Check out the live feed to see the latest activity in ${series?.city?.name}`}
            onClick={() => {
              track('Click Live Feed Banner');
              back('/home');
            }}
            style={{
              background: 'linear-gradient(45deg, #1283ff 0%, #d21aff 100%)',
            }}
          />
        ) : (
          <LinkBanner
            icon={
              <IconButton className="mx-auto" variant="smooth" color="neutral" size="lg">
                <FontAwesomeIcon name="calendar" />
              </IconButton>
            }
            title="Try our Booking Wizard"
            description={`Producers and entertainers—Find entertainers or open gigs in ${series?.city?.name}`}
            onClick={() => navigate(`/noauth/booking-wizard/?cityId=${series?.city?._id}`)}
          />
        )}
      </div>

      <FeaturedEventsSection
        tagId={series?._id}
        filterTags={series?.filterTags?.map(({ _id: value, label }) => ({
          value,
          label,
        }))}
        cityId={series?.city?._id}
        onEventClick={handleViewEvent}
      />
      <UpcomingEventsSection
        tagId={series?._id}
        cityId={series?.city?._id}
        onEventClick={handleViewEvent}
      />
    </>
  );
};

export const AddSeriesEventPopup: FC<{
  isVisible: boolean;
  onClose: () => void;
  seriesId: string;
  city: { _id: string; name: string };
}> = ({ isVisible, onClose, seriesId, city }) => {
  const { openAlertDialog } = useDialog();
  const { track } = useAnalytics();
  const queryClient = useQueryClient();
  const { currentUser } = useCurrentUser();
  const { entertainer } = useCurrentEntertainerQuery();

  const { createEntertainerEventAsync } = useCreateEntertainerEventMutation(entertainer?._id);

  if (!entertainer) {
    return <></>;
  }

  return (
    <>
      {seriesId && entertainer?._id && (
        <CreateEditEventPopup
          isVisible={isVisible}
          onClose={() => {
            track('Close Add Event Popup');
            onClose();
          }}
          cityOptions={[
            {
              value: city?._id,
              label: city?.name,
            },
          ]}
          defaultValues={{
            entertainers: currentUser.isSuperAdmin ? [] : [{ _id: entertainer?._id }],
            tagIds: [seriesId],
          }}
          onSubmit={async (payload) => {
            track('Submit Event', {
              payload,
            });
            await createEntertainerEventAsync(payload as any);
            onClose();
            await queryClient.invalidateQueries({ queryKey: ['embed-events'], exact: false });
          }}
        />
      )}
    </>
  );
};

const TAB_OPTION_RECORD = {
  entertainers: 'Entertainers',
  events: 'Shows',
  featured: 'Featured',
};
type TabOption = keyof typeof TAB_OPTION_RECORD;

const TAB_OPTIONS = Object.entries(TAB_OPTION_RECORD).map(([value, label]) => ({ value, label }));

export const SeriesScreen = createScreenComponent<SeriesScreenProps>(
  ({ tagSlug, back, f7route }) => {
    const { userIsLoggedIn } = useAppContext();
    const { currentUser } = useCurrentUser();
    const { entertainer } = useCurrentEntertainerQuery();
    const [currentTab, setCurrentTab] = useState<TabOption>(f7route.query.tab);
    const { isFetched: seriesIsReady, series, seriesHost } = useSeriesByTagSlug(tagSlug);
    const [isAddEventPopupVisible, setIsAddEventPopupVisible] = useState(
      f7route.query.addEvent === 'true',
    );
    const { navigate, back: navigateBack } = useRouter();
    const { openAlertDialog, openConfirmDialog } = useDialog();
    const queryClient = useQueryClient();
    const { track } = useAnalytics();
    const { entertainerJoinSeriesAsync } = useEntertainerJoinSeriesMutation();
    const fetchCurrentEntertainer = useFetchCurrentEntertainer();

    const { createAuthGateHandler } = useAuthGate();
    const { createEntertainerEventAsync } = useCreateEntertainerEventMutation(entertainer?._id);

    const addEventCallback = () =>
      createAuthGateHandler(
        async () => {
          setIsAddEventPopupVisible(true);
        },
        {
          entertainerCallback: () => {},
          cityId: series?.city?._id,
        },
      );

    const handleAddEvent = () => {
      track('Open Add Event Popup');

      if (!entertainer?._id) {
        openConfirmDialog({
          title: 'Add An Event',
          text: 'We currently only allow entertainers to add events. Would you like to continue as an entertainer?',
          buttonOk: 'Yes',
          onConfirm: addEventCallback,
        });
      } else {
        addEventCallback();
      }
    };

    const {
      isFetched: seriesEntertainersAreReady,
      entertainers: seriesEntertainers = [],
      refetch: refetchSeriesEntertainers,
    } = useSeriesEntertainersQuery(series?._id, Boolean(series?.isEntertainerSeries));

    const [entertainerSearch, setEntertainerSearch] = useState<string>();
    const [debouncedEntertainerSearch] = useDebounce(entertainerSearch, 300);

    const filteredEntertainers = useMemo(() => {
      const searchValue = debouncedEntertainerSearch?.trim()?.toLowerCase();
      if (searchValue) {
        return seriesEntertainers.filter(
          ({ title }) => title && title.toLowerCase().includes(searchValue),
        );
      }

      return seriesEntertainers;
    }, [debouncedEntertainerSearch, seriesEntertainers]);

    useEffect(() => {
      const searchValue = debouncedEntertainerSearch?.trim()?.toLowerCase();

      if (searchValue) {
        track('Search Series Entertainers', { search: debouncedEntertainerSearch });
      }
    }, [debouncedEntertainerSearch]);

    useEffect(() => {
      if (seriesIsReady) {
        const defaultTab = series?.isEntertainerSeries ? 'entertainers' : 'events';
        setCurrentTab(f7route.query.tab ?? defaultTab);
        if (f7route.query.joinSeries === 'true') {
          track('Join Series Page', {
            entertainerId: entertainer?._id,
          });
          entertainerJoinSeriesAsync({ seriesId: series?._id }).then(() => {
            refetchSeriesEntertainers();
          });
        }
      }
    }, [seriesIsReady]);

    // TODO: Test when they're already logged in but haven't created an entertainer yet
    const handleJoinList = async () => {
      track('Click Join List');
      createAuthGateHandler(
        async () => {
          let scopedEntertainer = entertainer;

          if (!scopedEntertainer) {
            const { entertainer: updatedEntertainer } = await fetchCurrentEntertainer();
            scopedEntertainer = updatedEntertainer;
          }

          if (scopedEntertainer) {
            await entertainerJoinSeriesAsync({ seriesId: series?._id });
          }
        },
        {
          entertainerCallback: () => {},
          cityId: series?.city?._id,
        },
      );
    };

    const includesCurrentEntertainer = seriesEntertainers.some(
      ({ _id }) => _id === entertainer?._id,
    );

    const handleViewProfile = () => {
      navigate(`/noauth/entertainer/${entertainer?.bookingHandle}`);
    };

    const fetchNextEntertainerFeedPosts = useFetchNextPosts();
    const [additionalPosts, setAdditionalPosts] = useState<FeedPost[]>([]);
    const { postsAreReady, posts: initialPosts = [] } = useEntertainerFeedPostsQuery(
      { cityId: series?.city?._id },
      series?.city?._id && series?.showEntertainerFeed,
    );
    const posts = useMemo(() => {
      return [...initialPosts, ...additionalPosts];
    }, [initialPosts, additionalPosts]);

    const tabOptions = useMemo(() => {
      if (series?.isEntertainerSeries || series?.showRecommendedTab) {
        return TAB_OPTIONS.filter(({ value }) => {
          if (value === 'entertainers') {
            return series?.isEntertainerSeries;
          }

          if (value === 'featured') {
            return series?.showRecommendedTab;
          }

          return true;
        });
      }

      return [];
    }, [series]);

    const { didCopy, shareLink } = useShareLink();
    const handleShareEmptyStateClick = async () => {
      track('Search Entertainer Empty State Share');

      await shareLink({
        url: `https://my.gaggl.app/noauth/series/${tagSlug}`,
      });
    };

    const { handleShareSweepstakesReferralLink } = useSweepstakesReferralLink({
      cityId: series?.city?._id,
    });

    const { city: currentCity } = useCityById(series?.city?._id);
    const isLocalDragSeries =
      series?.isLocalDragSeries || (currentCity ? `${currentCity?.slug}-drag` === tagSlug : false);
    const isLocalDragKingsAndThingsSeries = currentCity
      ? `${currentCity?.slug}-drag-kings-and-things` === tagSlug
      : false;
    const isSweepstakesCardVisible =
      isLocalDragSeries || (isLocalDragKingsAndThingsSeries && !back);

    return (
      <Screen
        name="Series Screen"
        hideToolbar
        disableLocationPermissionGate
        headerProps={{
          left: isLocalDragSeries ? <></> : undefined,
          back: { to: back },
          title: <Screen.GagglTitle />,
          right: <ShareLinkButton url={`https://my.gaggl.app/noauth/series/${tagSlug}`} />,
        }}
        metaData={{
          image: isLocalDragSeries
            ? 'https://my.gaggl.app/assets/images/local-drag-community.png'
            : undefined,
          title: `${series?.title} | Gaggl`,
          description: series?.description,
        }}
        // bottomToolbar={
        //   <Screen.BottomToolbar>
        //     <Button startIcon={<FontAwesomeIcon name="plus" />} roundness="rounded">
        //       Add Event
        //     </Button>
        //   </Screen.BottomToolbar>
        // }
      >
        <Screen.Content
          maxWidth={425}
          infiniteScroll={{
            hasReachedEnd: !series?.showEntertainerFeed,
            onLoadMore: async () => {
              const { posts: nextPosts = [] } = await fetchNextEntertainerFeedPosts(
                'entertainers',
                series?.city?._id,
              );
              setAdditionalPosts((prevPosts) => [...prevPosts, ...nextPosts]);
            },
          }}
        >
          {seriesIsReady ? (
            <>
              <TitleToolbar text={series?.title} />

              <div className="mb-3">
                {series?.description &&
                  mapStringToParagraphs(series?.description, {
                    collapseTopMargin: true,
                    sharedClassName: 'my-2',
                  })}
              </div>

              <div className="d-grid gap-3">
                {seriesHost && (
                  <div
                    className="relative"
                    style={{
                      background: 'rgba(255,255,255,.1)',
                      borderRadius: 8,
                      overflow: 'hidden',
                    }}
                    onClick={() => {
                      navigate(seriesHost.to);
                    }}
                  >
                    <div className="d-flex gap-3 align-items-center">
                      <div className="flex-shrink-0">
                        <div style={{ width: 60, height: 60 }}>
                          <img
                            src={seriesHost?.image}
                            alt={seriesHost?.title}
                            style={{ width: '100%', objectFit: 'cover' }}
                          />
                        </div>
                      </div>
                      <div>
                        <div
                          style={{
                            fontSize: 10,
                            textTransform: 'uppercase',
                            letterSpacing: '.1rem ',
                            opacity: 0.5,
                          }}
                        >
                          <strong>Local Ambassador</strong>
                        </div>
                        <div className="fs-6">
                          <strong>{seriesHost?.title}</strong>
                        </div>
                        {seriesHost?.description && (
                          <div className="opacity-75">{seriesHost?.description}</div>
                        )}
                      </div>
                      <div className="ml-auto px-3">
                        <FontAwesomeIcon name="chevron-right" />
                      </div>
                    </div>
                  </div>
                )}

                {isSweepstakesCardVisible && (
                  <SweepstakesCard
                    onLogin={handleJoinList}
                    onRevisit={() => {
                      openExternalUrl('https://gaggl.app/download');
                    }}
                    onRefer={handleShareSweepstakesReferralLink}
                    onRecommend={() => {
                      setCurrentTab('featured');
                    }}
                    onAddEvent={handleAddEvent}
                  />
                )}
              </div>

              {!!tabOptions.length && (
                <ButtonFilter
                  options={tabOptions}
                  onChange={(value) => {
                    track(`Switch to ${value} tab`);
                    setCurrentTab(value as TabOption);
                  }}
                  selected={currentTab}
                />
              )}

              {currentTab === 'entertainers' && (
                <div>
                  <div className="mb-3">
                    {currentUser?.isSuperAdmin && seriesEntertainersAreReady && (
                      <UpdateEntertainerList
                        seriesId={series?._id}
                        cityId={series?.city?._id}
                        entertainers={seriesEntertainers}
                      />
                    )}
                    {includesCurrentEntertainer ? (
                      <Button roundness="rounded" onClick={handleViewProfile}>
                        View My Profile
                      </Button>
                    ) : (
                      <Button roundness="rounded" onClick={handleJoinList}>
                        Join The List
                      </Button>
                    )}
                  </div>
                  {seriesEntertainers?.length > 10 && (
                    <div className="mb-3">
                      <Input
                        placeholder="Search entertainers..."
                        onChange={(e) => setEntertainerSearch(e.target.value)}
                      />
                    </div>
                  )}
                  {seriesEntertainersAreReady ? (
                    seriesEntertainers?.length ? (
                      filteredEntertainers?.length ? (
                        filteredEntertainers?.map((entertainer) => (
                          <EntertainerAvailabilityCard
                            key={entertainer._id}
                            entertainer={entertainer}
                            cityId={series?.city?._id}
                            back={`/noauth/series/${tagSlug}`}
                          />
                        ))
                      ) : (
                        <EmptyState
                          title="Did we miss someone?"
                          text={`There are no entertainers matching "${entertainerSearch}". Share the link with them so they can join the list`}
                          button={{ text: 'Share', onClick: handleShareEmptyStateClick }}
                        />
                      )
                    ) : (
                      <EmptyState
                        title="No entertainers"
                        text={`No one has joined the list in ${series?.city?.name} yet. Be the first!`}
                      />
                    )
                  ) : (
                    <>
                      <Skeleton height={120} />
                      <Skeleton height={120} />
                      <Skeleton height={120} />
                      <Skeleton height={120} />
                      <Skeleton height={120} />
                      <Skeleton height={120} />
                    </>
                  )}
                </div>
              )}

              {(currentTab === 'events' || !series?.isEntertainerSeries) &&
                (series?.showEntertainerShows ? (
                  <ShowsTabContent
                    onAddEvent={handleAddEvent}
                    cityId={series?.city?._id}
                    tagIds={series?._id ? [series?._id] : undefined}
                    back={`/noauth/series/${tagSlug}`}
                  />
                ) : (
                  <SeriesEventSections
                    tagSlug={tagSlug}
                    series={series}
                    onAddEvent={handleAddEvent}
                  />
                ))}
              {series?.showEntertainerFeed && (
                <>
                  <TitleToolbar text="Trending Entertainers ✨" size="md" />
                  {postsAreReady ? (
                    <>{posts?.map((post, i) => <PostCard key={i} disableLazyLoad post={post} />)}</>
                  ) : (
                    <>
                      <Skeleton height={140} />
                      <Skeleton height={140} />
                      <Skeleton height={140} />
                      <Skeleton height={140} />
                      <Skeleton height={140} />
                    </>
                  )}
                </>
              )}
              {currentTab === 'featured' && series?.showRecommendedTab && (
                <RecommendTabContent
                  cityId={series?.city?._id}
                  back={`/noauth/series/${tagSlug}?tab=${currentTab}`}
                />
              )}
            </>
          ) : (
            <>
              <Skeleton height={40} />
              <Skeleton height={40} />
              <Skeleton height={120} />
              <Skeleton height={40} />
              <Skeleton height={120} />
            </>
          )}
        </Screen.Content>
        {series?._id && entertainer?._id && (
          <AddSeriesEventPopup
            isVisible={isAddEventPopupVisible}
            onClose={() => setIsAddEventPopupVisible(false)}
            seriesId={series._id}
            city={series.city}
          />
        )}
      </Screen>
    );
  },
);
