import { useQuery } from '@tanstack/react-query';

import { useAppContext } from '@contexts/AppContext';

import API from '@api/API';

import { useCurrentUserCity, useCurrentUserLocation } from '@hooks/useCurrentUserLocation';

import { CritiqueData, UserCritique } from './CritiqueQueries';
import { Entertainer } from './EntertainerQueries';

interface Sweepstakes {
  title: string;
  description: string;
  maxParticipants?: number;
  rules: string;
  tasks: {
    title: string;
    description: string;
    buttonText: string;
    numberOfEntriesLabel: string;
    faIconName: string;
    action: string;
    reason: string;
    mobileAdvantageReason?: {
      numberOfEntriesLabel: string;
    };
  }[];
}

export interface SweepstakesResponseBody {
  sweepstakes?: Sweepstakes;
  totalParticipants: number;
  totalEntries: number;
}

export const useSweepstakes = () => {
  const { data, isFetched, refetch } = useQuery({
    queryKey: ['sweepstakes'],
    queryFn: () => API.get<SweepstakesResponseBody>(`/v1/sweepstakes`),
  });

  return { ...data, isFetched, refetch };
};

export interface CurrentUserSweepstakesEntriesResponseBody {
  sweepstakesEntries: any[];
  total: number;
}

export const useCurrentUserSweepstakesEntries = () => {
  const { userIsLoggedIn } = useAppContext();

  const { data, isFetched, refetch } = useQuery({
    queryKey: ['user-sweepstakes-entries'],
    queryFn: () =>
      API.get<CurrentUserSweepstakesEntriesResponseBody>(`/v1/user/sweepstakes-entries`),
    enabled: userIsLoggedIn,
  });

  return { ...data, isFetched, refetch };
};
