import { useMutation, useQuery } from '@tanstack/react-query';
import { List, ListItem } from 'framework7-react';
import { FC, Fragment, useMemo, useState } from 'react';

import pluralize from 'pluralize';

import API from '@api/API';

import { useCurrentEntertainerQuery } from '@queries/EntertainerQueries';
import { useCurrentUser } from '@queries/UserQueries';

import { useAnalytics } from '@hooks/useAnalytics';
import { useCitiesQuery, useCityById } from '@hooks/useCurrentUserLocation';

import { useLocation, useNavigate, useParams } from '@router/index';

import { createScreenComponent } from '@utilities/createScreenComponent';
import { mapStringToParagraphs } from '@utilities/mapStringToParagraphs';
import { openExternalUrl } from '@utilities/openExternalUrl';

import Button from '@components/buttons/Button';
import { ShareLinkButton } from '@components/buttons/ShareLinkButton';
import EventCard, {
  EventActions,
  EventHeader,
  EventImage,
  PromoterHeader,
} from '@components/cards/EventCard';
import { VenueCard } from '@components/cards/VenueCard';
import { Select } from '@components/form/Select';
import { FontAwesomeIcon } from '@components/icons/FontAwesomeIcon';
import Card from '@components/layout/Card/Card';
import { EmptyState } from '@components/layout/EmptyState/EmptyState';
import { ErrorBoundary } from '@components/layout/ErrorBoundary';
import { HorizontalScroll } from '@components/layout/HorizontalScroll/HorizontalScroll';
import { LinkBanner } from '@components/layout/LinkBanner/LinkBanner';
import { Screen } from '@components/layout/Screen/Screen';
import { TitleToolbar } from '@components/layout/TitleToolbar/TitleToolbar';

import { useAuthGate } from '@screens/LogInScreen/AuthGateContext';
import {
  SweepstakesCard,
  useSweepstakesReferralLink,
} from '@screens/series/SeriesScreen/SweepstakesCard';

import { FeaturedKing } from '../EntertainerDetailsScreen/assets/FeaturedKing';
import { AddFeaturedPhotoPopup } from '../components/FeaturedPhotoManagementSection';
import tickets from './tickets.svg';

interface DragKingCommunityScreenProps {}

interface DragKingCommunitySubmission {
  _id: string;
  title: string;
  image?: string;
  status: string;
  entertainer?: {
    _id: string;
    title: string;
    bookingHandle: string;
  };
}

const useDragKingCommunitySubmissions = (isEnabled: boolean) => {
  const { data, isFetched } = useQuery({
    queryKey: ['dragKingCommunitySubmissions'],
    queryFn: () =>
      API.get<{ submissions: DragKingCommunitySubmission[] }>(
        `/v1/drag-king-community/submissions`,
      ),
    enabled: isEnabled,
  });

  return {
    submissions: data?.submissions,
    areSubmissionsReady: isFetched,
  };
};

const useUpdateDragKingCommunitySubmission = () => {
  const { isPending, mutateAsync } = useMutation({
    mutationFn: ({
      entertainerMediaId,
      status,
    }: {
      entertainerMediaId: string;
      status: 'approved' | 'rejected';
    }) =>
      API.post(`/v1/drag-king-community/submissions/${entertainerMediaId}`, {
        status,
      }),
  });

  return {
    isUpdatingSubmission: isPending,
    updateSubmissionAsync: mutateAsync,
  };
};

const DragKingSubmissionCard: FC<{
  submission: DragKingCommunitySubmission;
}> = ({ submission }) => {
  const [status, setStatus] = useState(submission.status);
  const { updateSubmissionAsync } = useUpdateDragKingCommunitySubmission();

  const handleApprove = async () => {
    setStatus('approved');
    await updateSubmissionAsync({
      entertainerMediaId: submission._id,
      status: 'approved',
    });
  };

  const handleReject = async () => {
    setStatus('rejected');
    await updateSubmissionAsync({
      entertainerMediaId: submission._id,
      status: 'rejected',
    });
  };

  return (
    <div key={submission._id} className="d-grid gap-2">
      <img
        src={submission.image}
        alt={submission.title}
        className="w-100"
        style={{ borderRadius: 8 }}
      />
      <div className="d-flex gap-2">
        <Button
          roundness="rounded"
          color="neutral"
          variant={status === 'approved' ? 'default' : 'outlined'}
          onClick={handleApprove}
        >
          Approve
        </Button>
        <Button
          roundness="rounded"
          color="neutral"
          variant={status === 'rejected' ? 'default' : 'outlined'}
          onClick={handleReject}
        >
          Reject
        </Button>
      </div>
    </div>
  );
};

interface DragKingCommunityScreenProps {}

export const DragKingCommunityScreen = createScreenComponent<DragKingCommunityScreenProps>(
  ({ f7route }) => {
    const { track } = useAnalytics();
    const navigate = useNavigate();
    const [cityId, setCityId] = useState<string>(f7route.query?.cityId);
    const [didSubmitCity, setDidSubmitCity] = useState(!!f7route.query?.cityId);
    const { currentUser } = useCurrentUser();
    const { entertainer } = useCurrentEntertainerQuery();
    const { cities = [] } = useCitiesQuery();
    const { city } = useCityById(cityId ?? entertainer?.cityIds?.[0]);
    const { submissions = [] } = useDragKingCommunitySubmissions(!!entertainer?._id);
    const { createAuthGateHandler } = useAuthGate();
    const [addImagePopupIsVisible, setAddImagePopupIsVisible] = useState(false);

    const handleRecommend = () => {
      navigate(`/noauth/series/${city?.slug}-drag?tab=featured`);
    };

    const handleAddEvent = () => {
      navigate(`/noauth/series/${city?.slug}-drag?tab=events`);
    };

    return (
      <Screen
        name="Drag King Community Screen"
        hideToolbar
        headerProps={{
          title: <Screen.GagglTitle />,
          right: <ShareLinkButton url={`https://gaggl.app/drag-king-community`} />,
        }}
      >
        <Screen.Content>
          <div className="d-flex mb-3 gap-3 align-items-center">
            <FeaturedKing height={48} width={48} />
            <div>
              <strong className="fs-5">Drag King Community</strong>
              <br />
              <span>Calling all drag kings! 👑</span>
            </div>
          </div>

          <p className="mt-0">
            <strong>Drag King Community x Gaggl</strong> makes it easier to spotlight and support
            drag kings. Share your photo here to be featured.
          </p>

          {didSubmitCity || entertainer?._id ? (
            <>
              <LinkBanner
                icon={
                  <div className="d-flex align-items-center justify-content-center">
                    <FontAwesomeIcon className="fs-2" name="camera-retro" />
                  </div>
                }
                title="Add another photo"
                description={`${entertainer?.featuredMedia?.length ?? 0} Featured ${pluralize('Look', entertainer?.featuredMedia?.length)}`}
                onClick={() => {
                  track('Add another photo');
                  setAddImagePopupIsVisible(true);
                }}
              />
              <LinkBanner
                icon={
                  <div className="d-flex align-items-center justify-content-center">
                    <FontAwesomeIcon name="calendar fs-2" />
                  </div>
                }
                title="Add an upcoming show"
                description={`Share your upcoming shows`}
                onClick={handleAddEvent}
              />
              <LinkBanner
                icon={
                  <div className="d-flex align-items-center justify-content-center">
                    <FontAwesomeIcon name="star fs-2" />
                  </div>
                }
                title="Make recommendations"
                description={`Recommend other drag artists in ${city?.name}`}
                onClick={handleRecommend}
              />

              {currentUser?.isDragKingCommunityAdmin && (
                <>
                  <TitleToolbar
                    text="Pending Submissions"
                    size="sm"
                    action={<span>{`${submissions.length} total`}</span>}
                  />
                  <div className="d-grid gap-4">
                    {submissions.map((submission) => (
                      <DragKingSubmissionCard key={submission._id} submission={submission} />
                    ))}
                  </div>
                </>
              )}
            </>
          ) : (
            <>
              <p>To get started, select your primary city:</p>
              <div className="d-grid gap-2">
                <Select
                  value={cityId}
                  options={[
                    { value: '', label: '--' },
                    ...cities
                      .filter(({ eventsAreEnabled }) => eventsAreEnabled)
                      .map(({ _id, name }) => ({
                        value: _id,
                        label: name,
                      })),
                  ]}
                  onChange={(e) => {
                    setCityId(e.target.value);
                  }}
                />
                <Button
                  roundness="rounded"
                  disabled={!cityId}
                  onClick={() => {
                    track('Select City', {
                      cityName: city?.name,
                    });
                    setDidSubmitCity(true);
                    setAddImagePopupIsVisible(true);
                  }}
                >
                  Feature Me!
                </Button>
                <Button
                  roundness="rounded"
                  variant="flat"
                  color="neutral"
                  onClick={() => {
                    createAuthGateHandler(() => {
                      setAddImagePopupIsVisible(true);
                    });
                  }}
                >
                  I have an account already
                </Button>
              </div>
            </>
          )}
        </Screen.Content>
        <ErrorBoundary>
          <AddFeaturedPhotoPopup
            isVisible={addImagePopupIsVisible}
            cityId={city?._id}
            onClose={() => setAddImagePopupIsVisible(false)}
            meta={{
              channel: 'dragkingcommunity',
              channelAction: 'ayrshare',
            }}
          />
        </ErrorBoundary>
      </Screen>
    );
  },
);
