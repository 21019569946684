import { useMutation, useQueryClient } from '@tanstack/react-query';

import API from '@api/API';

import { useCurrentEntertainerQuery } from '@queries/EntertainerQueries';
import {
  CurrentUserResponseBody,
  useCurrentUser,
  useNotificationsQuery,
} from '@queries/UserQueries';

import { useCurrentUserCity } from '@hooks/useCurrentUserLocation';

export interface UpdateProfilePayload {
  firstName?: string;
  lastName?: string;
  email?: string;
  phone?: string;
  displayName?: string;
  notificationsStatus?: 'enabled' | 'disabled';
  smsNotificationsStatus?: 'enabled' | 'disabled';
}

export const useUpdateProfileMutation = () => {
  const queryClient = useQueryClient();

  const { mutateAsync: updateProfileAsync, isPending: isSubmitting } = useMutation({
    mutationFn: (updatedUserProperties: UpdateProfilePayload) =>
      API.put('/v1/user/me', updatedUserProperties),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: useCurrentUser.queryKey() });
    },
  });

  return { isSubmitting, updateProfileAsync };
};

export const useDeleteProfileMutation = () => {
  const { mutateAsync, isPending } = useMutation({
    mutationFn: () => API.delete('/v1/user/me'),
  });

  return { isSubmitting: isPending, deleteProfileAsync: mutateAsync };
};

export interface UserOnboardingPayload {
  displayName?: string;
  interests?: string[];
}

export const useUserOnboardingMutation = () => {
  const queryClient = useQueryClient();

  const { mutateAsync: updateUserOnboardingAsync, isPending: isSubmitting } = useMutation({
    mutationFn: (updatedUserProperties: UserOnboardingPayload) =>
      API.post('/v1/user/onboarding', updatedUserProperties),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: useCurrentUser.queryKey() });
    },
  });

  return { isSubmitting, updateUserOnboardingAsync };
};

export const useMarkNotificationsAsSeenMutation = () => {
  const queryClient = useQueryClient();

  const { mutateAsync, isPending } = useMutation({
    mutationFn: () => API.post('/v1/user/notifications/seen', {}),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: useNotificationsQuery.queryKey() });
    },
  });

  return { isSubmitting: isPending, markNotificationsAsSeenAsync: mutateAsync };
};

export interface MarkNotificationAsReadPayload {
  notificationId: string;
}

export const useMarkNotificationAsReadMutation = () => {
  const queryClient = useQueryClient();

  const { mutateAsync, isPending } = useMutation({
    mutationFn: ({ notificationId }: MarkNotificationAsReadPayload) =>
      API.post(`/v1/notifications/${notificationId}/read`, {}),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: useNotificationsQuery.queryKey() });
    },
  });

  return { isSubmitting: isPending, markNotificationAsReadAsync: mutateAsync };
};

export interface MarkSplashNotificationAsReadPayload {
  notificationId: string;
}

export const useMarkSplashNotificationAsReadMutation = () => {
  // const queryClient = useQueryClient();

  const { mutateAsync, isPending } = useMutation({
    mutationFn: ({ notificationId }: MarkSplashNotificationAsReadPayload) =>
      API.post(`/v1/splash-notifications/${notificationId}/read`, {}),
    // onSuccess: (res, { notificationId }) => {
    //   const queryData = queryClient.getQueryData<CurrentUserResponseBody>(
    //     useCurrentUser.queryKey(),
    //   );

    //   if (queryData?.splashNotifications) {
    //     const index = queryData.splashNotifications.findIndex(
    //       (notification) => notification._id !== notificationId,
    //     );

    //     if (index > -1) {
    //       queryData.splashNotifications[index].isRead = true;
    //       queryClient.setQueryData<CurrentUserResponseBody>(useCurrentUser.queryKey(), queryData);
    //     }
    //   }
    // },
  });

  return { isSubmitting: isPending, markSplashNotificationAsReadAsync: mutateAsync };
};

// export interface ChangeUserCityPayload {
//   cityId: string;
// }

// export const useChangeUserCityMutation = () => {
//   const queryClient = useQueryClient();

//   const { mutateAsync, isPending } = useMutation({
//     mutationFn: ({ cityId }: ChangeUserCityPayload) =>
//       API.post(`/v1/notifications/${notificationId}/read`, {}),
//     onSuccess: () => {
//       queryClient.invalidateQueries({ queryKey: useCurrentUserCity.queryKey() });
//     },
//   });

//   return { isSubmitting: isPending, changeUserCityAsync: mutateAsync };
// };

interface AddUserBookingOpportunityPayload {
  cityId: string;
  bookingOpportunites: { startDate?: string; title?: string; details?: string }[];
  isTalentFocused: boolean;
}

export const useAddUserBookingOpportunitiesMutation = () => {
  const queryClient = useQueryClient();

  const { isPending, mutateAsync } = useMutation({
    mutationFn: (payload: AddUserBookingOpportunityPayload) => {
      return API.post<{}, AddUserBookingOpportunityPayload>(
        '/v1/user/booking-opportunity-dates',
        payload,
      );
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: useCurrentEntertainerQuery.queryKey(),
      });
    },
  });

  return {
    isAddingUserBookingOpportunities: isPending,
    addUserBookingOpportunitiesAsync: mutateAsync,
  };
};

interface AskDetailQuestionPayload {
  detailQuestionId: string;
  documentType: 'bookingRequest' | 'bookingOpportunity';
  documentId: string;
}

export const useAskDetailQuestionMutation = () => {
  const { isPending, mutateAsync } = useMutation({
    mutationFn: ({ detailQuestionId, documentType, ...payload }: AskDetailQuestionPayload) => {
      return API.post<{}, Omit<AskDetailQuestionPayload, 'detailQuestionId' | 'documentType'>>(
        `/v1/detail-questions/${detailQuestionId}/documentType/${documentType}/ask`,
        payload,
      );
    },
  });

  return {
    isAddingUserBookingOpportunities: isPending,
    askDetailQuestionAsync: mutateAsync,
  };
};

interface RespondDetailQuestionPayload {
  detailQuestionId: string;
  documentType: 'bookingRequest' | 'bookingOpportunity';
  documentId: string;
  answer: string;
}

export const useRespondDetailQuestionMutation = () => {
  const { isPending, mutateAsync } = useMutation({
    mutationFn: ({ detailQuestionId, documentType, ...payload }: RespondDetailQuestionPayload) => {
      return API.post<{}, Omit<RespondDetailQuestionPayload, 'detailQuestionId' | 'documentType'>>(
        `/v1/detail-questions/${detailQuestionId}/documentType/${documentType}/respond`,
        payload,
      );
    },
  });

  return {
    isAddingUserBookingOpportunities: isPending,
    respondDetailQuestionAsync: mutateAsync,
  };
};

export const useCashOutMutation = () => {
  const { isPending, mutateAsync } = useMutation({
    mutationFn: () => {
      return API.post('/v1/user/cash-out', {});
    },
  });

  return {
    isCashingOut: isPending,
    cashOutAsync: mutateAsync,
  };
};
