import { useQueryClient } from '@tanstack/react-query';
import EmojiPicker, { Theme } from 'emoji-picker-react';
import { Fab, List, ListGroup, ListItem } from 'framework7-react';
import { FC, Fragment, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';

import { useAppContext } from '@contexts/AppContext';
import { filter } from 'cypress/types/bluebird';
import { event } from 'cypress/types/jquery';
import * as dayjs from 'dayjs';
import * as relativeTime from 'dayjs/plugin/relativeTime';
import { Dom7 } from 'framework7';
import { groupBy, sum, uniq } from 'lodash';
import pluralize from 'pluralize';
import { TALENT_CONNECTOR_ENABLED } from 'src/constants/FeatureFlags';

import { useRecommendationQuestions } from '@queries/CritiqueQueries';
import {
  CurrentEntertainer,
  useCityEntertainerAvailabilityQuery,
  useCurrentEntertainerQuery,
  useEntertainerByBookingHandleQuery,
  useFetchCurrentEntertainer,
  useHydratedEntertainersQuery,
  useRecommendedEntertainersQuery,
  useTopEntertainersQuery,
} from '@queries/EntertainerQueries';
import { FeedEvent, useUnauthorizedEventsQuery } from '@queries/EventQueries';
import {
  FeedPost,
  useFeaturedLooksFeedPostsQuery,
  useFeedPostsQuery,
  useFetchNextPosts,
} from '@queries/PostQueries';
import {
  useCityBookingOpportunitiesQuery,
  useCurrentPromoterQuery,
} from '@queries/PromoterQueries';
import { useUserConversationsQuery, useUserWalletQuery } from '@queries/UserQueries';

import { useAddEntertainerToEventMutation } from '@mutations/EntertainerMutations';
import {
  useCreateNewPostMutation,
  useReactToPostMutation,
  useRespondToPostMutation,
} from '@mutations/PostMutations';

import { useTipEntertainer } from '@hooks/EntertainerHooks/useTipEntertainer';
import { useAnalytics } from '@hooks/useAnalytics';
import { CurrentCity, useCityById, useCurrentUserCity } from '@hooks/useCurrentUserLocation';
import { usePWA } from '@hooks/usePWA';

import { useNavigate, useQueryParams, useRouter } from '@router/index';

import { createScreenComponent } from '@utilities/createScreenComponent';
import { findFirstAlphaCharacter } from '@utilities/findFirstAlphaCharacter';
import { mapStringToParagraphs } from '@utilities/mapStringToParagraphs';

import Button from '@components/buttons/Button';
import IconButton from '@components/buttons/IconButton';
import { PostCard, PostCardHeaderWrapper } from '@components/cards/PostCard/PostCard';
import { Chip } from '@components/chips/Chip';
import { ChipRadioOptions } from '@components/chips/ChipRadioOptions';
import { Input } from '@components/form/Input';
import { Select } from '@components/form/Select';
import { Textarea } from '@components/form/Textarea';
import { FontAwesomeIcon } from '@components/icons/FontAwesomeIcon';
import { LineAwesomeIcon } from '@components/icons/LineAwesomeIcon';
import { ButtonFilter } from '@components/layout/ButtonFilter/ButtonFilter';
import Card from '@components/layout/Card/Card';
import { ChipTitleFilter } from '@components/layout/ChipTitleFilter/ChipTitleFilter';
import { useDialog } from '@components/layout/Dialog/useDialog';
import { EmptyState } from '@components/layout/EmptyState/EmptyState';
import { HorizontalScroll } from '@components/layout/HorizontalScroll/HorizontalScroll';
import { InlineDatePicker } from '@components/layout/InlineDatePicker/InlineDatePicker';
import { LazyLoad } from '@components/layout/LazyLoad';
import { LetterAvatar } from '@components/layout/LetterAvatar/LetterAvatar';
import { LinkBanner } from '@components/layout/LinkBanner/LinkBanner';
import { PopoverWrapper } from '@components/layout/Popover/PopoverWrapper';
import { Popup } from '@components/layout/Popup/Popup';
import { Screen } from '@components/layout/Screen';
import { Skeleton } from '@components/layout/Skeleton/Skeleton';
import { TitleToolbar } from '@components/layout/TitleToolbar/TitleToolbar';

import { UserGagglRankingCard } from '@screens/HomeScreen/HomeScreen';
import { useAuthGate } from '@screens/LogInScreen/AuthGateContext';
import { useInAppNotifications } from '@screens/NotificationsScreen/NotificationsScreen';
import { ChangeCityButton } from '@screens/SettingsScreen';
import { PrimaryBookingWizardEntryPoint } from '@screens/booking/BookingWizardScreen/BookingWizardScreen';
import { EntertainerAvailabilityCard } from '@screens/booking/GigsAndTalentScreen/EntertainerAvailabilityCard';
import { TalentTabContent } from '@screens/booking/GigsAndTalentScreen/GigsAndTalentScreen';
import { DRAG_PERFORMER_CATEGORIES } from '@screens/entertainer/CreateEntertainerProfileScreen';
import { EntertainerRankingsContent } from '@screens/entertainer/EntertainerRankingsScreen/EntertainerRankingsScreen';
import { FeaturedEntertainersSection } from '@screens/entertainer/EntertainerRankingsScreen/FeaturedEntertainersSection';
import {
  PastEventsBadgesSection,
  useRecentEventsWithEntertainers,
} from '@screens/entertainer/EntertainerRankingsScreen/PastEventsBadgesSection';
import { MultiSelectField } from '@screens/entertainer/components/CitiesField';
import {
  AddFeaturedPhotoPopup,
  FeaturedPhotoManagementSection,
} from '@screens/entertainer/components/FeaturedPhotoManagementSection';
import { AddSeriesEventPopup } from '@screens/series/SeriesScreen/SeriesScreen';

import { useAnswerRecommendationQuestionMutation } from '../../mutations/CritiqueMutations';
import { FeaturedKing } from '../entertainer/EntertainerDetailsScreen/assets/FeaturedKing';
import inclusiveResistImage from '../entertainer/EntertainerDetailsScreen/assets/inclusive-resist.png';
import { BadgeSection } from './BadgeSection';
import { BookingSection } from './BookingSection';
import { CommentPopup } from './CommentPopup';
import './CommunityScreen.scss';
import { CritiqueQuestionCard } from './CritiqueQuestionCard';
import { NonEntertainerCommunityScreen } from './NonEntertainerCommunityScreen';
import { PostPopup } from './PostPopup';
import { PromoterRankingsContent } from './PromoterRankingsContent';
import { RatingActivityCard } from './RatingActivityCard';
import { VenueRankingsContent } from './VenueRankingsContent';
import stageIconUrl from './assets/stage.svg';

const TAB_OPTION_RECORD = {
  support: 'Endorse',
  collab: 'Collab',
  shows: 'Shows',
};
type TabOption = keyof typeof TAB_OPTION_RECORD;

const TAB_OPTIONS = Object.entries(TAB_OPTION_RECORD).map(([value, label]) => ({ value, label }));

const ConversationsIcon = () => {
  const { navigate } = useRouter();
  const { userConversations } = useUserConversationsQuery();
  const { entertainer } = useCurrentEntertainerQuery();
  const { promoter } = useCurrentPromoterQuery();

  const hasConversations = Object.values(userConversations).some(
    (conversations) => conversations.length,
  );

  const conversationsPath = useMemo(() => {
    if (entertainer) {
      return '/conversations/entertainer';
    }

    if (promoter) {
      return '/conversations/promoter';
    }

    return '/conversations/user';
  }, [entertainer, promoter]);

  if (!hasConversations) {
    return <></>;
  }

  const hasUnread = Object.values(userConversations).some((conversations) =>
    conversations.some(({ isUnread }) => isUnread),
  );

  return (
    <IconButton
      color="neutral"
      variant="smooth"
      badge={hasUnread}
      onClick={() => {
        navigate(conversationsPath, {
          state: { back: '/' },
        });
      }}
    >
      <FontAwesomeIcon name="paper-plane" />
    </IconButton>
  );
};

const TRENDING_TOPICS = {
  bipoc: {
    label: 'BIPOC Artists',
  },
  trans: {
    label: 'Trans',
  },
  king: {
    label: 'Kings',
  },
  thing: {
    label: 'Things',
  },
  producers: { label: 'Producers' },
  hosts: {
    label: 'Hosts',
  },
  mua: {
    label: 'MUAs',
  },
  costumeDesigners: {
    label: 'Costume Designers',
  },
  wigStylists: {
    label: 'Wig Stylists',
  },
  photographers: {
    label: 'Photographers',
  },
};

const RecommendCard = ({
  cityId,
  questionSlug,
  question,
  recommendCallback,
}: {
  cityId: string;
  questionSlug: string;
  question: string;
  recommendCallback?: () => void;
}) => {
  const [customInputIsVisible, setCustomInputIsVisible] = useState(false);
  const [customValue, setCustomValue] = useState('');
  const { createAuthGateHandler } = useAuthGate();
  const { track } = useAnalytics();
  const [entertainerIds, setEntertainerIds] = useState<string[]>([]);
  const { topEntertainersAreReady, topEntertainers = [] } = useTopEntertainersQuery(
    cityId,
    'dragPerformer',
    true,
  );

  const canSubmit = customInputIsVisible ? customValue : entertainerIds.length;
  const { isAnsweringRecommendationQuestion, answerRecommendationQuestionAsync } =
    useAnswerRecommendationQuestionMutation();

  const handleSubmit = () => {
    track('Submit Recommendation', { questionSlug, entertainerIds });

    createAuthGateHandler(
      async () => {
        await answerRecommendationQuestionAsync(
          customInputIsVisible
            ? { questionSlug, entertainerIds, custom: customValue }
            : { questionSlug, entertainerIds },
        );
        recommendCallback?.();
      },
      {
        entertainerCallback: () => {},
        cityId,
      },
    );
  };

  const EntertainerRecommendationEmptyState: FC<{ searchValue: string; onClose: () => void }> = ({
    searchValue,
    onClose,
  }) => {
    return (
      <div
        key={`entertainer-recommentations-${entertainerIds.length}`}
        className="p-2 text-align-center"
      >
        <p className="mb-0">
          <strong>{searchValue ? `No matches for "${searchValue}"` : 'No matches'}</strong>
        </p>
        <p className="mt-0">Did we miss someone? Add them yourself!</p>
        <Button
          variant="smooth"
          color="neutral"
          roundness="rounded"
          onClick={() => {
            setCustomInputIsVisible(true);
            onClose();
          }}
        >
          Add
        </Button>
      </div>
    );
  };

  return (
    <Card>
      <Card.Body style={{ borderBottom: '1px solid rgba(255,255,255,.2)' }}>
        <div style={{ fontSize: 16 }}>
          <strong>{question}</strong>
        </div>
      </Card.Body>
      <div>
        {customInputIsVisible ? (
          <div className="d-grid gap-2">
            <Textarea
              focusOnMount
              rows={2}
              onChange={setCustomValue}
              submitButton={{
                dataTestId: 'custom-recommend-submit',
                text: 'Recommend',
                disabled: !canSubmit || isAnsweringRecommendationQuestion,
                onClick: async () => handleSubmit(),
              }}
            />
          </div>
        ) : (
          <div className="d-flex gap-2 align-items-center">
            <div className="w-100">
              {topEntertainersAreReady ? (
                <MultiSelectField
                  placeholder="Select entertainer(s)..."
                  name="entertainerIds"
                  allowSearch
                  defaultValue={[]}
                  options={topEntertainers
                    .map(({ _id, title }) => ({
                      value: _id,
                      label: title,
                    }))
                    .sort((a, b) => a.label.localeCompare(b.label))}
                  onChange={(updatedEntertainerIds) => {
                    setEntertainerIds(updatedEntertainerIds);
                  }}
                  EmptyStateComponent={EntertainerRecommendationEmptyState}
                />
              ) : (
                <div className="w-100" style={{ height: 44 }} />
              )}
            </div>
            <div className="pe-2">
              <Button
                data-testid="recommend-button"
                roundness="rounded"
                size="sm"
                className="py-2 px-3"
                variant={canSubmit ? 'default' : 'smooth'}
                color={canSubmit ? 'primary' : 'neutral'}
                disabled={!canSubmit || isAnsweringRecommendationQuestion}
                onClick={handleSubmit}
              >
                Recommend
              </Button>
            </div>
          </div>
        )}
      </div>
    </Card>
  );
};

interface RecommendSectionProps {
  cityId: string;
  initialQuestionSlug?: string;
  onSeeRecommendations?: () => void;
}

export const RecommendSection = ({
  cityId,
  initialQuestionSlug,
  onSeeRecommendations,
}: RecommendSectionProps) => {
  const { track } = useAnalytics();

  const {
    recommendationQuestionsAreReady,
    recommendationQuestions,
    recommendAndEarn,
    refetchRecommendationQuestions,
  } = useRecommendationQuestions();
  const [selectedTopicFilter, setSelectedTopicFilter] = useState<string>(initialQuestionSlug);

  const recommendationQuestionOptions = Object.entries(recommendationQuestions).map(
    ([value, data]) => ({
      value,
      label: data.pluralLabel,
    }),
  );

  const questionSlug = selectedTopicFilter ?? recommendationQuestionOptions?.[0]?.value;
  const currentQuestion = recommendationQuestions?.[questionSlug];

  const { openAlertDialog } = useDialog();
  const handleRecommendAndEarnClick = () => {
    track('Click Recommend and Earn');
    openAlertDialog({
      title: recommendAndEarn.dialog.title,
      text: recommendAndEarn.dialog.content,
    });
  };

  const handleRecommendCallback = () => {
    if (recommendAndEarn) {
      refetchRecommendationQuestions();
    }
  };

  return (
    <>
      <TitleToolbar
        text="Recommend 💗"
        size="sm"
        className="mb-0"
        action={
          recommendAndEarn ? (
            <Button
              color="danger"
              size="sm"
              startIcon={<FontAwesomeIcon name="info-circle" />}
              style={{ borderRadius: 8 }}
              showHighlight
              onClick={handleRecommendAndEarnClick}
            >
              {recommendAndEarn.label}
            </Button>
          ) : (
            <>
              {onSeeRecommendations && (
                <Button roundness="rounded" variant="flat" onClick={onSeeRecommendations}>
                  See Recommendations
                </Button>
              )}
            </>
          )
        }
      />
      {recommendationQuestionsAreReady ? (
        <>
          <ChipTitleFilter
            options={recommendationQuestionOptions}
            value={selectedTopicFilter ?? recommendationQuestionOptions?.[0]?.value}
            onChange={(value) => {
              track('Change Trending Topic Filter', { value });
              setSelectedTopicFilter(value);
            }}
          />
          <RecommendCard
            key={`recommendation-question-${selectedTopicFilter}`}
            cityId={cityId}
            questionSlug={questionSlug}
            question={currentQuestion?.question}
            recommendCallback={handleRecommendCallback}
          />
        </>
      ) : (
        <Skeleton height={160} />
      )}
    </>
  );
};

export const FeaturedLooksFeed: FC<{ city?: CurrentCity; showAddLook?: boolean }> = ({
  city: currentCity,
  showAddLook,
}) => {
  const { track } = useAnalytics();
  const { postsAreReady, posts } = useFeaturedLooksFeedPostsQuery({ cityId: currentCity?._id });
  const [addImagePopupIsVisible, setAddImagePopupIsVisible] = useState(false);

  return (
    <>
      <TitleToolbar
        text="Featured Looks 👀"
        size="sm"
        action={
          showAddLook && (
            <Button
              startIcon={<FontAwesomeIcon name="plus" />}
              roundness="rounded"
              variant="flat"
              onClick={() => {
                track(`Click add look`);
                setAddImagePopupIsVisible(true);
              }}
            >
              Add Look
            </Button>
          )
        }
      />
      <div className="d-grid gap-3">
        {postsAreReady ? (
          <>
            {posts?.length ? (
              posts?.map((post, i) => (
                <PostCard
                  key={post._id ?? i}
                  post={post}
                  onContentClick={() => {}}
                  onCommentClick={() => {}}
                />
              ))
            ) : (
              <EmptyState
                title="Nothing trending"
                text={`Gaggl is just getting started in ${currentCity?.name}. Check back later to see the more activity.`}
              />
            )}
          </>
        ) : (
          <div className="grid gap-2">
            <Skeleton height={240} />
            <Skeleton height={240} />
            <Skeleton height={240} />
          </div>
        )}
      </div>
      {showAddLook && (
        <AddFeaturedPhotoPopup
          isVisible={addImagePopupIsVisible}
          cityId={currentCity?._id}
          onClose={() => setAddImagePopupIsVisible(false)}
        />
      )}
    </>
  );
};

const SupportTabContent = ({
  initialQuestionSlug,
  onSeeRecommendations,
}: {
  initialQuestionSlug?: string;
  onSeeRecommendations: () => void;
}) => {
  const { currentCity } = useCurrentUserCity();

  const { entertainer } = useCurrentEntertainerQuery();

  return (
    <>
      <BadgeSection size="full" />

      {!entertainer?.featuredMedia?.length && (
        <div className="mt-3">
          <FeaturedPhotoManagementSection
            hideTitleToolbar
            hideSecondaryButton
            cityId={currentCity?._id}
            featuredMedia={entertainer?.featuredMedia ?? []}
          />
        </div>
      )}

      <PastEventsBadgesSection cityId={currentCity?._id} />

      <RecommendSection
        cityId={currentCity?._id}
        initialQuestionSlug={initialQuestionSlug}
        onSeeRecommendations={onSeeRecommendations}
      />

      <FeaturedLooksFeed city={currentCity} showAddLook />
    </>
  );
};

interface RecommendationsSectionProps {
  cityId: string;
  back: string;
  initialQuestionSlug?: string;
  onRecommend?: (questionSlug: string) => void;
}

export const RecommendationsSection = ({
  cityId,
  back,
  initialQuestionSlug,
  onRecommend,
}: RecommendationsSectionProps) => {
  const { track } = useAnalytics();

  const [selectedTopicFilter, setSelectedTopicFilter] = useState<string>(initialQuestionSlug);

  const { entertainersAreReady, entertainers } = useHydratedEntertainersQuery(
    cityId,
    'dragPerformer',
  );

  const { recommendationQuestionsAreReady, recommendationQuestions } = useRecommendationQuestions();
  const recommendationQuestionOptions = Object.entries(recommendationQuestions).map(
    ([value, data]) => ({
      value,
      label: data.pluralLabel,
    }),
  );
  const questionSlug = selectedTopicFilter ?? recommendationQuestionOptions?.[0]?.value;

  const { recommendedEntertainersAreReady, recommendedEntertainers } =
    useRecommendedEntertainersQuery(cityId, 'dragPerformer');

  const filteredRecommendedEntertainers = useMemo(() => {
    return (
      recommendedEntertainers?.[questionSlug ?? '']
        ?.map((entertainerId) => entertainers?.[entertainerId])
        ?.filter(Boolean) ?? []
    );
  }, [questionSlug, entertainers, recommendedEntertainers]);

  return (
    <>
      <TitleToolbar
        id="recommended"
        text="Recommended Entertainers 💖"
        size="sm"
        className="mb-0"
      />
      <ChipTitleFilter
        options={recommendationQuestionOptions}
        value={selectedTopicFilter ?? recommendationQuestionOptions?.[0]?.value}
        onChange={(value) => {
          track('Change Trending Topic Filter', { value });
          setSelectedTopicFilter(value);
        }}
      />
      {recommendedEntertainersAreReady ? (
        <div className="d-grid gap-3">
          {filteredRecommendedEntertainers.length ? (
            filteredRecommendedEntertainers.map((entertainer) => (
              <div key={entertainer._id}>
                <EntertainerAvailabilityCard
                  entertainer={entertainer}
                  cityId={cityId}
                  back={back}
                />
              </div>
            ))
          ) : (
            <EmptyState
              title="No recommended entertainers"
              text={
                onRecommend
                  ? `No one has recommended any entertainers for being an "${recommendationQuestions[questionSlug]?.reasonLabel}" yet. Be the first!`
                  : `No one has recommended any entertainers for being an "${recommendationQuestions[questionSlug]?.reasonLabel}" yet.`
              }
              button={
                onRecommend && {
                  text: 'Recommend',
                  onClick: () => {
                    onRecommend(questionSlug);
                  },
                }
              }
            />
          )}
        </div>
      ) : (
        <div>
          <Skeleton height={120} />
          <Skeleton height={120} />
          <Skeleton height={120} />
        </div>
      )}
    </>
  );
};

export const FeaturedEntertainerSeries: FC<{ cityId: string; back: string }> = ({
  cityId,
  back,
}) => {
  const featuredIconSize = 24;
  const { navigate } = useRouter();
  const { city: currentCity } = useCityById(cityId);

  if (!currentCity?.featuredSeries?.bipoc && !currentCity?.featuredSeries?.kingsThings) {
    return <></>;
  }

  return (
    <div className="d-grid gap-2 mb-3">
      {currentCity?.featuredSeries?.bipoc && (
        <div
          className="d-flex align-items-center gap-2 p-2"
          style={{ background: 'rgba(255,255,255,.1)', borderRadius: 8 }}
          onClick={() => {
            navigate(`/noauth/series/${currentCity?.featuredSeries?.bipoc}`, {
              state: { back },
            });
          }}
        >
          <div>
            <img
              className="d-block"
              src={inclusiveResistImage}
              alt="Inclusive Resist"
              style={{
                height: featuredIconSize,
                width: featuredIconSize,
                objectFit: 'contain',
              }}
            />
          </div>
          <div>
            <strong>Featured BIPOC Artists</strong>
          </div>
          <div className="ml-auto px-2">
            <FontAwesomeIcon name="chevron-right" />
          </div>
        </div>
      )}

      {currentCity?.featuredSeries?.kingsThings && (
        <div
          className="d-flex align-items-center gap-2 p-2"
          style={{ background: 'rgba(255,255,255,.1)', borderRadius: 8 }}
          onClick={() => {
            navigate(`/noauth/series/${currentCity?.featuredSeries?.kingsThings}`, {
              state: { back },
            });
          }}
        >
          <div>
            <FeaturedKing height={featuredIconSize} width={featuredIconSize} />
          </div>
          <div>
            <strong>Featured Kings & Things</strong>
          </div>
          <div className="ml-auto px-2">
            <FontAwesomeIcon name="chevron-right" />
          </div>
        </div>
      )}
    </div>
  );
};

interface CollabTabContentProps
  extends Pick<RecommendationsSectionProps, 'initialQuestionSlug' | 'onRecommend'> {
  initialSearchValue?: string;
  initialCategory?: string;
  isCurrentEntertainerFeatured?: boolean;
}

const CollabTabContent = ({
  initialSearchValue,
  initialCategory,
  initialQuestionSlug,
  isCurrentEntertainerFeatured,
  onRecommend,
}: CollabTabContentProps) => {
  const { track } = useAnalytics();
  const { navigate } = useRouter();

  const [searchValue, setSearchValue] = useState<string>(initialSearchValue);
  const [category, setCategory] = useState<string>(initialCategory);

  const [selectedTopicFilter, setSelectedTopicFilter] = useState<string>(initialQuestionSlug);
  const { currentCity } = useCurrentUserCity();
  const { entertainersAreReady, entertainers } = useHydratedEntertainersQuery(
    currentCity?._id,
    'dragPerformer',
  );

  const { recommendationQuestionsAreReady, recommendationQuestions } = useRecommendationQuestions();
  const recommendationQuestionOptions = Object.entries(recommendationQuestions).map(
    ([value, data]) => ({
      value,
      label: data.pluralLabel,
    }),
  );
  const questionSlug = selectedTopicFilter ?? recommendationQuestionOptions?.[0]?.value;

  const { recommendedEntertainersAreReady, recommendedEntertainers } =
    useRecommendedEntertainersQuery(currentCity?._id, 'dragPerformer');
  const entertainerCategories = useMemo(() => {
    const categories = Object.values(entertainers).flatMap(
      (entertainer) => entertainer.details?.categories ?? [],
    );

    return uniq(categories).sort((a, b) => a.localeCompare(b));
  }, [entertainers]);

  const back = useMemo(() => {
    const rootUrl = `/community/collab`;

    const params = new URLSearchParams();
    if (searchValue) {
      params.set('s', searchValue);
    }

    if (category) {
      params.set('c', category);
    }

    if (questionSlug) {
      params.set('q', questionSlug);
    }

    return `${rootUrl}?${params.toString()}`;
  }, [searchValue, category, questionSlug]);

  useEffect(() => {
    if (category || searchValue) {
      track('Search Entertainers', { category, searchValue });
    }
  }, [category, searchValue]);

  const filteredEntertainers = useMemo(() => {
    const applySearchFilter = (entertainer: { title: string }) => {
      if (!searchValue) {
        return true;
      }

      const searchValueLowercased = searchValue.toLowerCase();

      return entertainer.title.toLowerCase().includes(searchValueLowercased);
    };

    const applyCategoryFilter = (entertainer: { details?: { categories: string[] } }) => {
      if (!category) {
        return true;
      }

      return entertainer?.details?.categories?.includes(category);
    };

    return (
      Object.values(entertainers)?.filter(applySearchFilter)?.filter(applyCategoryFilter) ?? []
    );
  }, [searchValue, category, entertainers]);

  const searchContent = (
    <>
      <TitleToolbar
        text="Search Results"
        size="sm"
        action={
          <Button
            variant="flat"
            startIcon={<FontAwesomeIcon name="times" />}
            onClick={() => {
              setSearchValue('');
              setCategory('');
            }}
          >
            Clear
          </Button>
        }
      />
      <div className="d-grid gap-3">
        {filteredEntertainers?.map((entertainer) => (
          <LazyLoad key={entertainer._id}>
            <EntertainerAvailabilityCard
              entertainer={entertainer}
              cityId={currentCity?._id}
              back={back}
            />
          </LazyLoad>
        ))}
      </div>
    </>
  );

  const nonSearchContent = (
    <>
      <FeaturedEntertainerSeries cityId={currentCity?._id} back={back} />
      <div>
        <BookingSection back={back} />
      </div>
      <FeaturedEntertainersSection
        isTrendingLooksCtaVisible={false}
        isCurrentEntertainerFeatured={isCurrentEntertainerFeatured}
        cityId={currentCity?._id}
        back={back}
      />
      <RecommendationsSection
        cityId={currentCity?._id}
        back={back}
        initialQuestionSlug={initialQuestionSlug}
        onRecommend={onRecommend}
      />
    </>
  );

  return (
    <>
      <div className="d-flex gap-2 mb-3">
        <div className="w-50">
          <Input
            placeholder="Search by name"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
          />
        </div>
        <div className="w-50">
          <Select
            value={category}
            options={[
              { value: '', label: 'All Categories' },
              ...entertainerCategories.map((category) => ({
                value: category,
                label: category,
              })),
            ]}
            onChange={(event) => {
              setCategory(event.target.value);
            }}
          />
        </div>
      </div>

      {searchValue || category ? searchContent : nonSearchContent}
    </>
  );
};

const ShowListItem = ({
  event,
  onNavigate,
  onJoin,
}: {
  event: FeedEvent;
  onNavigate: () => void;
  onJoin: (cb: (createdEntertainer: Pick<CurrentEntertainer, '_id' | 'title'>) => void) => void;
}) => {
  const { entertainer } = useCurrentEntertainerQuery();
  const [modifiedEntertainers, setModifiedEntertainers] = useState(event.entertainers ?? []);

  const handleJoinCallback = async (
    createdEntertainer: Pick<CurrentEntertainer, '_id' | 'title'>,
  ) => {
    const addedEntertainer = createdEntertainer ?? entertainer;
    if (addedEntertainer?._id) {
      setModifiedEntertainers((prevValue) => [
        ...prevValue,
        { _id: addedEntertainer._id, title: addedEntertainer.title },
      ]);
    }
  };

  return (
    <li className="media-item">
      <div className="item-content">
        <div className="item-inner">
          <div className="d-flex align-items-center">
            <div onClick={onNavigate} style={{ overflow: 'hidden' }}>
              <div className="item-text" style={{ fontSize: 14, lineHeight: '1.1' }}>
                {event.timeDetails}
              </div>
              <div className="item-title-row">
                <div className="item-title">{event.title}</div>
              </div>
              <div className="item-footer">
                {modifiedEntertainers?.length
                  ? modifiedEntertainers?.map(({ title }) => title).join(', ')
                  : 'No entertainers added'}
              </div>
            </div>

            <div className="ml-auto ps-1">
              <PopoverWrapper
                PopoverContent={({ onClose }) => (
                  <div>
                    <Button
                      className="w-100"
                      variant="flat"
                      color="neutral"
                      onClick={() => {
                        onClose();
                        onJoin(handleJoinCallback);
                      }}
                    >
                      Join Lineup
                    </Button>
                  </div>
                )}
              >
                <IconButton data-testid="show-list-item-cta" variant="smooth" color="neutral">
                  <LineAwesomeIcon name="ellipsis-h" />
                </IconButton>
              </PopoverWrapper>
            </div>
          </div>
        </div>
      </div>
    </li>
  );
};

export const ShowsTabContent: FC<{
  onAddEvent: () => void;
  cityId: string;
  tagIds: string[];
  back: string;
}> = ({ onAddEvent, cityId, tagIds, back }) => {
  const { userIsLoggedIn } = useAppContext();
  const { track } = useAnalytics();
  const navigate = useNavigate();
  const { entertainer } = useCurrentEntertainerQuery();
  const { city: currentCity } = useCityById(cityId);
  const [selectedDates, setSelectedDates] = useState<Record<string, boolean>>({});
  const { bookingOpportunitiesAreReady, bookingOpportunities } = useCityBookingOpportunitiesQuery(
    currentCity?._id,
  );
  const { createAuthGateHandler } = useAuthGate();
  const { eventsAreReady, events = [] } = useUnauthorizedEventsQuery(
    {
      cityId,
      tagIds,
      startDate: dayjs().format('YYYY-MM-DD'),
      rsvpData: 'ignore',
    },
    Boolean(cityId && tagIds.length),
  );

  const eventsGroupedByStartDate = useMemo<Record<string, FeedEvent[]>>(() => {
    return groupBy(events, 'startDate');
  }, [events]);

  const filteredEventsGroupsByStartDate = useMemo(() => {
    const hasSelectedDates = Object.values(selectedDates).some((isSelected) => isSelected);

    if (hasSelectedDates) {
      return Object.fromEntries(
        Object.entries(eventsGroupedByStartDate).filter(([date]) => selectedDates[date]),
      );
    }

    return eventsGroupedByStartDate;
  }, [selectedDates, eventsGroupedByStartDate]);

  const handleNavigateToEvent = (eventId: string) => {
    navigate(`/events/${eventId}`, {
      state: { back },
    });
  };

  // if (userIsLoggedIn && entertainer?._id) {
  //   onCanAddEvent();
  // } else {
  //   track('Open Add Event Confirm Dialog');
  //   openConfirmDialog({
  //     title: 'Add An Event',
  //     text: 'We currently only allow entertainers to add events. Would you like to continue as an entertainer?',
  //     buttonOk: 'Yes',
  //     onConfirm: () => {
  //       createAuthGateHandler(async () => {
  //         const { entertainer: currentEntertainer } = await fetchCurrentEntertainer();

  //         if (currentEntertainer._id) {
  //           onCanAddEvent();
  //         } else {
  //           navigate('/entertainer/onboarding', {
  //             state: {
  //               onCompletePath: `/noauth/series/${tagSlug}?addEvent=true`,
  //             },
  //           });
  //         }
  //       });
  //     },
  //   });
  // }

  const { openConfirmDialog } = useDialog();
  const fetchCurrentEntertainer = useFetchCurrentEntertainer();
  const { addEntertainerToEventAsync } = useAddEntertainerToEventMutation();
  const handleJoinLineup = (
    eventId: string,
    cb: (createdEntertainer: Pick<CurrentEntertainer, '_id' | 'title'>) => void,
  ) => {
    track('Join Lineup', { eventId });
    openConfirmDialog({
      title: 'Join Lineup?',
      text: 'Are you sure you want to join this show lineup?',
      onConfirm: () => {
        createAuthGateHandler(
          async () => {
            let addedEntertainer = entertainer;

            if (!addedEntertainer?._id) {
              const { entertainer: refetchedEntertainer } = await fetchCurrentEntertainer();
              addedEntertainer = refetchedEntertainer;
            }

            await addEntertainerToEventAsync({ entertainerId: addedEntertainer?._id, eventId });
            await cb(addedEntertainer);
          },
          {
            entertainerCallback: () => {},
            cityId,
          },
        );
      },
      buttonOk: 'Join',
    });
  };

  const openStageIconSize = 48;
  const openStageCount = bookingOpportunities?.filter(({ type }) => type === 'openStage')?.length;
  const handleClickOpenStages = () => {
    track('Click Open Stages');
    navigate(`/noauth/gigs-and-talent/${cityId}`, {
      state: { back },
    });
  };

  return (
    <>
      {bookingOpportunitiesAreReady ? (
        <div
          className="d-flex align-items-center gap-3 p-2"
          style={{ background: 'rgba(255,255,255,.1)', borderRadius: 8 }}
          onClick={handleClickOpenStages}
        >
          <div className="d-block">
            <img
              src={stageIconUrl}
              alt="stage"
              className="d-block"
              style={{
                height: openStageIconSize,
                width: openStageIconSize,
                objectFit: 'contain',
                borderRadius: 8,
              }}
            />
          </div>
          <div>
            <div>
              <strong>Open stages</strong>
            </div>
            <div>
              <span>{`${openStageCount} Available`}</span>
            </div>
          </div>
          <div className="ml-auto px-2">
            <FontAwesomeIcon name="chevron-right" />
          </div>
        </div>
      ) : (
        <Skeleton height={64} />
      )}
      <TitleToolbar
        text="Performance Calendar 🎉"
        size="md"
        action={
          <Button
            variant="flat"
            size="sm"
            startIcon={<FontAwesomeIcon name="plus" />}
            onClick={onAddEvent}
          >
            Add Show
          </Button>
        }
      />
      {eventsAreReady ? (
        <>
          {events.length ? (
            <Card>
              <div className="pb-3">
                <InlineDatePicker
                  events={Object.keys(eventsGroupedByStartDate).map((date) => ({
                    date: dayjs(date).toDate(),
                    color: 'var(--f7-theme-color)',
                  }))}
                  minDate={dayjs().startOf('day').toISOString()}
                  value={selectedDates}
                  onChange={setSelectedDates}
                />
              </div>
            </Card>
          ) : (
            <EmptyState
              title="No upcoming shows"
              text="No one has added any upcoming shows. Add yours and help folks know what you have coming up!"
            />
          )}
        </>
      ) : (
        <Skeleton height={356} />
      )}
      <List mediaList style={{ marginLeft: -16, marginRight: -16 }}>
        {Object.entries(filteredEventsGroupsByStartDate).map(([date, groupedEvents]) => (
          <ListGroup>
            <ListItem title={dayjs(date).format('dddd, MMMM D, YYYY')} groupTitle />
            {groupedEvents.map((event) => (
              <ShowListItem
                key={event._id}
                event={event}
                onNavigate={() => handleNavigateToEvent(event._id)}
                onJoin={(cb) => handleJoinLineup(event._id, cb)}
              />
            ))}
          </ListGroup>
        ))}
      </List>
    </>
  );
};

let didShowIntentionDialog = false;

export const EntertainerCommunityScreen = createScreenComponent<{ tab?: TabOption }>(
  ({ tab, f7route }) => {
    const { track } = useAnalytics();
    const { navigate } = useRouter();
    const { entertainer } = useCurrentEntertainerQuery();
    const { walletIsReady, wallet } = useUserWalletQuery();
    const { hasUnreadNotifications } = useInAppNotifications();
    const { currentCityIsReady, currentCity } = useCurrentUserCity();
    const [currentTab, setCurrentTab] = useState<TabOption>(tab ?? 'support');
    const [initialState, setInitialState] = useState<{
      supportQuestionSlug?: string;
      collabSearchValue?: string;
      collabCategory?: string;
      collabQuestionSlug?: string;
    }>({
      collabSearchValue: f7route.query.s,
      collabCategory: f7route.query.c,
      collabQuestionSlug: f7route.query.q,
    });

    const { openAlertDialog, openVerticalButtonsDialog } = useDialog();
    const [isAddEventPopupVisible, setIsAddEventPopupVisible] = useState(false);

    const pwaPromptRef = useRef(false);
    const { canInstallPWA, promptPWAInstall } = usePWA();

    useEffect(() => {
      if (f7route.query.installPWA === 'true' && canInstallPWA && !pwaPromptRef.current) {
        pwaPromptRef.current = true;
        promptPWAInstall()
          .then(() => {
            pwaPromptRef.current = false;
          })
          .catch(() => {
            pwaPromptRef.current = false;
          });
      }
    }, []);

    // useLayoutEffect(() => {
    //   if (!didShowIntentionDialog) {
    //     didShowIntentionDialog = true;

    //     const buttons = ['See shows', 'Collaborate'].map((text) => ({
    //       text,
    //       onClick: () => {
    //         if (text === 'See shows') {
    //           track('Select Intention', { intention: 'See shows' });
    //           setCurrentTab('shows');
    //         } else {
    //           track('Select Intention', { intention: 'Collaborate' });
    //           setCurrentTab('collab');
    //         }
    //       },
    //     }));

    //     openVerticalButtonsDialog({
    //       title: 'Quick question',
    //       text: 'What do you want to do?',
    //       buttons,
    //     });
    //   }
    // }, []);

    const handleReenter = () => {};

    const handleSwitchToRecommendationQuestion = (questionSlug: string) => {
      track('Switch to Recommendation Question', { questionSlug });
      setCurrentTab('support');
      setInitialState({ supportQuestionSlug: questionSlug });
    };

    const handleSeeRecommendations = () => {
      track('See Recommendations');
      setCurrentTab('collab');
    };

    const visibleFilters = useMemo(() => {
      return TAB_OPTIONS.map(({ value, label }) => ({
        value,
        label,
      })).filter(({ value }) => {
        if (value !== 'shows') {
          return true;
        }

        return !!currentCity?.featuredSeries?.drag;
      });
    }, [currentCity]);

    const title = useMemo(() => {
      const isKing = entertainer?.details?.categories?.includes('Drag King');
      const isThing = entertainer?.details?.categories?.includes('Drag Thing');

      const rootMessage = `Hey ${entertainer?.title}!`;

      if (isThing) {
        return `${rootMessage} 👹`;
      }

      if (isKing) {
        return `${rootMessage} 👑`;
      }

      return `${rootMessage} 💃🏽`;
    }, [entertainer]);

    return (
      <Screen
        name="Entertainer Community Screen"
        disableLocationPermissionGate={false}
        onReenter={handleReenter}
        headerProps={{
          left: (
            <div className="ps-2 d-flex align-items-center" style={{ gap: 10 }}>
              <Screen.GagglTitle />
              <ChangeCityButton
                buttonProps={{
                  variant: 'smooth',
                  style: { padding: '4px 12px' },
                }}
              />
            </div>
          ),
          right: (
            <div className="d-flex gap-2">
              <ConversationsIcon />
              <IconButton
                color="neutral"
                variant="smooth"
                badge={hasUnreadNotifications}
                onClick={() => {
                  navigate('/notifications');
                }}
              >
                <FontAwesomeIcon name="bell" />
              </IconButton>
            </div>
          ),
        }}
      >
        <Screen.Content maxWidth={425}>
          <TitleToolbar
            text={<span style={{ fontSize: '1.5rem' }}>{title}</span>}
            className="title-toolbar-welcome mb-0"
            size="md"
            action={
              walletIsReady &&
              !wallet.total && (
                <IconButton
                  variant="smooth"
                  color="neutral"
                  badge
                  onClick={() => {
                    track('Click Wallet Icon', wallet);
                    !wallet.total
                      ? openAlertDialog({
                          title: 'Empty Wallet',
                          text: "You have't earned any tips yet, but we have plenty of opportunities for you to earn some by helping build the community through sharing recommendations, referrals, and more! Once you have some funds, you can cash out, and we'll send you a Venmo!",
                        })
                      : navigate('/profile');
                  }}
                >
                  <FontAwesomeIcon name="wallet" />
                </IconButton>
              )
            }
          />
          {currentCity?.name ? (
            <p className="mt-0">{`Dive into the ${currentCity?.name} community`}</p>
          ) : (
            <div className="w-100 mb-2">
              <Skeleton height={26} />
            </div>
          )}
          {currentCity?.name ? (
            <ButtonFilter
              className="mt-0"
              options={visibleFilters}
              selected={currentTab}
              onChange={(updatedSearchType: TabOption) => {
                track(`Switch to ${TAB_OPTION_RECORD[updatedSearchType]}`);
                setCurrentTab(updatedSearchType);
                setInitialState({});
              }}
            />
          ) : (
            <div className="w-100 mb-2">
              <Skeleton height={32} />
            </div>
          )}

          {currentTab === 'support' && (
            <SupportTabContent
              initialQuestionSlug={initialState.supportQuestionSlug}
              onSeeRecommendations={handleSeeRecommendations}
            />
          )}
          {currentTab === 'collab' && (
            <CollabTabContent
              initialSearchValue={initialState.collabSearchValue}
              initialCategory={initialState.collabCategory}
              initialQuestionSlug={initialState.collabQuestionSlug}
              onRecommend={handleSwitchToRecommendationQuestion}
              isCurrentEntertainerFeatured={f7route.query.featured === 'self'}
            />
          )}
          {currentTab === 'shows' && (
            <ShowsTabContent
              onAddEvent={() => setIsAddEventPopupVisible(true)}
              cityId={currentCity?._id}
              tagIds={[currentCity?.featuredSeries?.drag]}
              back="/community/shows"
            />
          )}
        </Screen.Content>
        {currentCity?.featuredSeries?.drag && (
          <AddSeriesEventPopup
            isVisible={isAddEventPopupVisible}
            onClose={() => setIsAddEventPopupVisible(false)}
            seriesId={currentCity?.featuredSeries?.drag}
            city={currentCity}
          />
        )}
      </Screen>
    );
  },
);

export const CommunityScreen = createScreenComponent<{ tab?: any }>((props) => {
  const { entertainer, entertainerIsReady } = useCurrentEntertainerQuery();

  if (!entertainerIsReady) {
    return <></>;
  }

  if (entertainer?._id) {
    return <EntertainerCommunityScreen {...props} />;
  }

  return <NonEntertainerCommunityScreen {...props} />;
});
