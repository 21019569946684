import { FC, useMemo, useState } from 'react';

import { useCitiesQuery, useCityById } from '@hooks/useCurrentUserLocation';

import { useLocation, useNavigate, useParams } from '@router/index';

import { createScreenComponent } from '@utilities/createScreenComponent';
import { mapStringToParagraphs } from '@utilities/mapStringToParagraphs';
import { openExternalUrl } from '@utilities/openExternalUrl';

import Button from '@components/buttons/Button';
import EventCard, {
  EventActions,
  EventHeader,
  EventImage,
  PromoterHeader,
} from '@components/cards/EventCard';
import { VenueCard } from '@components/cards/VenueCard';
import { Select } from '@components/form/Select';
import { HorizontalScroll } from '@components/layout/HorizontalScroll/HorizontalScroll';
import { Screen } from '@components/layout/Screen/Screen';
import { TitleToolbar } from '@components/layout/TitleToolbar/TitleToolbar';

import { useAuthGate } from '@screens/LogInScreen/AuthGateContext';
import {
  SweepstakesCard,
  useSweepstakesReferralLink,
} from '@screens/series/SeriesScreen/SweepstakesCard';

import tickets from './tickets.svg';

export const LandingPageSweepstakesCard: FC<{ isCardVisible?: boolean; cityId: string }> = ({
  isCardVisible,
  cityId,
}) => {
  const navigate = useNavigate();
  const { city } = useCityById(cityId);
  const { createAuthGateHandler } = useAuthGate();

  const handleLogin = () => {
    createAuthGateHandler(() => {}, {
      entertainerCallback: () => {},
      cityId,
    });
  };

  const handleRevisit = () => {
    openExternalUrl('https://gaggl.app/download');
  };

  const { handleShareSweepstakesReferralLink } = useSweepstakesReferralLink({
    cityId,
  });

  const handleRecommend = () => {
    navigate(`/noauth/series/${city?.slug}-drag?tab=featured`);
  };

  const handleAddEvent = () => {
    navigate(`/noauth/series/${city?.slug}-drag?tab=events`);
  };

  return (
    <SweepstakesCard
      isCardVisible={isCardVisible}
      onLogin={handleLogin}
      onRevisit={handleRevisit}
      onRefer={handleShareSweepstakesReferralLink}
      onRecommend={handleRecommend}
      onAddEvent={handleAddEvent}
    />
  );
};

interface EntertainerSweepstakesScreenProps {}

export const EntertainerSweepstakesScreen =
  createScreenComponent<EntertainerSweepstakesScreenProps>(({ f7route }) => {
    const [cityId, setCityId] = useState<string>(f7route.query?.cityId);
    const { cities = [] } = useCitiesQuery();
    const [didSubmitCity, setDidSubmitCity] = useState(!!f7route.query?.cityId);

    return (
      <Screen
        name="Entertainer Sweepstakes Screen"
        hideToolbar
        headerProps={{ title: <Screen.GagglTitle /> }}
      >
        <Screen.Content>
          {didSubmitCity ? (
            <LandingPageSweepstakesCard isCardVisible cityId={cityId} />
          ) : (
            <>
              <div className="d-flex mb-3 gap-3 align-items-center">
                <img src={tickets} className="d-block" style={{ width: 60 }} />
                <div className="fs-5">
                  <strong>Welcome to Gaggl's $250 Drag Sweepstakes!</strong>
                </div>
              </div>
              <p className="mt-0">
                Gaggl is a free app built for drag performers to grow their audience, get booked,
                and connect with the local queer community—all in one place. Whether you’re just
                starting out or a seasoned queen, king, or thing, Gaggl is here to help you showcase
                your talent and find real opportunities.
              </p>
              <p>
                This sweepstakes is our way of saying hey—and giving back to the performers who make
                queer nightlife entertaining.
              </p>
              <p>To get started, select your city:</p>
              <div className="d-grid gap-2">
                <Select
                  value={cityId}
                  options={[
                    { value: '', label: '--' },
                    ...cities
                      .filter(({ eventsAreEnabled }) => eventsAreEnabled)
                      .map(({ _id, name }) => ({
                        value: _id,
                        label: name,
                      })),
                  ]}
                  onChange={(e) => {
                    setCityId(e.target.value);
                  }}
                />
                <Button
                  roundness="rounded"
                  disabled={!cityId}
                  onClick={() => setDidSubmitCity(true)}
                >
                  Get started for a chance to win $250!
                </Button>
              </div>
            </>
          )}
        </Screen.Content>
      </Screen>
    );
  });
