import { Progressbar } from 'framework7-react';
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useSwipeable } from 'react-swipeable';

import { motion } from 'framer-motion';

import { ToastNotification } from '@api/handleResponse';

import { useCurrentEntertainerQuery } from '@queries/EntertainerQueries';
import { useToastNotification } from '@queries/UserQueries';

import { FontAwesomeIcon } from '@components/icons/FontAwesomeIcon';
import Card from '@components/layout/Card/Card';

import { UserScoreNotification } from '../UserScoreNotification/UserScoreNotification';
import './UserToastNotification.scss';

const animateProgress = (start, end, cb: (updatedProgress: number) => void, duration = 300) => {
  if (start === undefined || end === undefined) return;

  const stepTime = 10; // Update interval in milliseconds
  const steps = duration / stepTime; // Number of steps in the animation
  const increment = (end - start) / steps; // Amount to increase per step

  let currentProgress = start;
  cb(currentProgress);

  const interval = setInterval(() => {
    currentProgress += increment;
    cb(currentProgress);

    if ((increment > 0 && currentProgress >= end) || (increment < 0 && currentProgress <= end)) {
      cb(end); // Ensure it ends exactly at the target value
      clearInterval(interval);
    }
  }, stepTime);
};

const UnwrappedUserToastNotification: FC<{
  toastNotification: ToastNotification;
  onComplete: () => void;
}> = ({ toastNotification, onComplete }) => {
  const [toastNotificationIsVisible, setToastNotificationIsVisible] = useState(false);
  const [prevId, setPrevId] = useState<string>();
  const [progress, setProgress] = useState(-1);

  const swipeHandlers = useSwipeable({
    onSwipedUp: () => {
      setToastNotificationIsVisible(false);
    },
  });

  useEffect(() => {
    if (prevId !== toastNotification.id) {
      setToastNotificationIsVisible(true);
      setPrevId(toastNotification.id);
      setProgress(toastNotification.progress?.startPercentage ?? -1);
      setTimeout(() => {
        setToastNotificationIsVisible(false);
        setTimeout(() => {
          onComplete();
        }, 500);
      }, 4000);
    }
  }, [toastNotification]);

  return (
    <motion.div
      id="user-toast-notifcation"
      initial={{ top: -200 }}
      animate={toastNotificationIsVisible ? { top: 0 } : undefined}
      exit={{ top: -200 }}
      onAnimationComplete={() => {
        if (toastNotification.progress) {
          animateProgress(
            toastNotification.progress.startPercentage,
            toastNotification.progress.endPercentage,
            setProgress,
            500,
          );
        }
      }}
    >
      <div {...swipeHandlers}>
        <Card>
          <Card.Body>
            {progress > -1 && (
              <div>
                <Progressbar
                  style={{ height: 10, borderRadius: 500, marginBottom: '.5rem' }}
                  progress={progress}
                />
              </div>
            )}
            <div className="d-flex gap-3">
              <div
                className="user-toast-notifcation_icon"
                style={{ color: toastNotification.icon.color }}
              >
                <motion.div
                  initial={{ scale: 1 }}
                  animate={{ scale: 1.2 }}
                  transition={{ repeat: Infinity, repeatType: 'reverse', duration: 0.5 }}
                >
                  <FontAwesomeIcon name={toastNotification.icon.name} />
                </motion.div>
              </div>
              <div className="user-toast-notifcation_content">
                <h3>
                  <strong>{toastNotification.title}</strong>
                </h3>
                <p>{toastNotification.content}</p>
              </div>
            </div>
          </Card.Body>
        </Card>
      </div>
    </motion.div>
  );
};

export const UserToastNotification = () => {
  const { entertainerIsReady, entertainer } = useCurrentEntertainerQuery();
  const { toastNotification } = useToastNotification();
  const [toastNotifications, setToastNotifications] = useState<Record<string, ToastNotification>>(
    {},
  );
  const [displayedToastNotifications, setDisplayedToastNotications] = useState<
    Record<string, true>
  >({});

  useEffect(() => {
    if (toastNotification?.id) {
      setToastNotifications((prevValue) => {
        return {
          ...prevValue,
          [toastNotification.id]: toastNotification,
        };
      });
    }
  }, [toastNotification?.id]);

  const visibleToastNotification = useMemo<ToastNotification | undefined>(() => {
    return Object.values(toastNotifications).find(({ id }) => !displayedToastNotifications[id]);
  }, [toastNotifications, displayedToastNotifications]);

  if (entertainerIsReady && !entertainer && !visibleToastNotification) {
    return <UserScoreNotification />;
  }

  if (!visibleToastNotification) {
    return <></>;
  }

  const handleComplete = () => {
    setDisplayedToastNotications((prevValue) => {
      return { ...prevValue, [visibleToastNotification.id]: true };
    });
  };

  return (
    <UnwrappedUserToastNotification
      toastNotification={visibleToastNotification}
      onComplete={handleComplete}
    />
  );
};
