import * as Sentry from '@sentry/react';
import { QueryKey } from '@tanstack/react-query';

import { queryClient } from '@contexts/queryClient';
import CryptoJS from 'crypto-js';

function decryptMessage(encryptedMessage: string, secretKey: string) {
  const [iv, encrypted] = encryptedMessage.split(':');
  const keyHex = CryptoJS.enc.Hex.parse(secretKey);
  const ivHex = CryptoJS.enc.Hex.parse(iv);
  const decrypted = CryptoJS.AES.decrypt(
    {
      ciphertext: CryptoJS.enc.Hex.parse(encrypted),
    },
    keyHex,
    {
      iv: ivHex,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    },
  );

  const decryptedMessage = decrypted.toString(CryptoJS.enc.Utf8);
  return decryptedMessage;
}

function deriveKey(password: string, salt: string, keyLength = 32) {
  const iterations = 100000; // Number of iterations for PBKDF2
  const digest = CryptoJS.algo.SHA256; // Hash function to use

  const keyMaterial = CryptoJS.PBKDF2(password, salt, {
    keySize: keyLength / 4, // Dividing by 4 because CryptoJS keySize is in words (4 bytes per word)
    iterations: iterations,
    hasher: digest,
  });

  return keyMaterial.toString(CryptoJS.enc.Hex);
}

const handleEncryptedResponse = async <ResponseBody>(response: Response) => {
  const data = await response.text();
  const [one, two, three] = data.split(':');

  const decrypted = decryptMessage(
    [two, three].join(':'),
    deriveKey(document.location.origin, one),
  );
  const parsed = JSON.parse(decrypted);

  return parsed as ResponseBody;
};

// Duplicated!!!
interface SplashNotification {
  isRead?: boolean;
  _id: string;
  imageUrl?: string;
  title: string;
  content: string;
  displayPath: string;
  button?: {
    text: string;
    to: string;
  };
}

export interface ToastNotification {
  id: string;
  icon: {
    color: string;
    name: string;
  };
  title: string;
  content: string;
  progress?: {
    startPercentage: number;
    endPercentage: number;
  };
}

export const handleResponse = async <
  ResponseBody extends {
    invalidateQueries?: QueryKey[];
    splashNotification?: SplashNotification;
    toastNotification?: ToastNotification;
  },
>(
  response: Response,
) => {
  let json = {} as ResponseBody;

  const isEncrypted = response.headers.get('X-Gaggl') === 'true';
  if (isEncrypted) {
    json = await handleEncryptedResponse(response);
  }

  const contentType = response.headers.get('content-type');
  if (contentType && contentType.indexOf('application/json') !== -1) {
    json = await response.json();
  }

  try {
    if (json?.invalidateQueries?.length) {
      await Promise.all(
        json?.invalidateQueries?.map((queryKey) => {
          queryClient.invalidateQueries({ queryKey, exact: true });
        }) ?? [],
      );
    }
  } catch (error) {
    Sentry.captureException(error);
  }

  try {
    if (json?.splashNotification) {
      const currentUser = queryClient.getQueryData<{ splashNotifications: SplashNotification[] }>([
        'user',
      ]);
      const updatedCurrentUser = {
        ...currentUser,
        splashNotifications: [
          json?.splashNotification,
          ...(currentUser?.splashNotifications ?? []),
        ],
      };
      queryClient.setQueryData(['user'], updatedCurrentUser);
    }
  } catch (error) {
    Sentry.captureException(error);
  }
  try {
    if (json?.toastNotification) {
      queryClient.setQueryData<ToastNotification>(['toast-notification'], json.toastNotification);
    }
  } catch (error) {
    Sentry.captureException(error);
  }

  return json;
};
