import { FC, Fragment, useState } from 'react';

import { useAppContext } from '@contexts/AppContext';
import { map } from 'cypress/types/bluebird';
import pluralize from 'pluralize';
import { Navigation, Pagination } from 'swiper';

import { useCurrentEntertainerQuery } from '@queries/EntertainerQueries';
import { FeedEvent } from '@queries/EventQueries';

import { useRespondToEntertainerEventRequestMutation } from '@mutations/EntertainerMutations';

import { useAnalytics } from '@hooks/useAnalytics';

import { useNavigate } from '@router/index';

import { findFirstAlphaCharacter } from '@utilities/findFirstAlphaCharacter';
import { mapStringToParagraphs } from '@utilities/mapStringToParagraphs';
import { openExternalUrl } from '@utilities/openExternalUrl';

import Button from '@components/buttons/Button';
import EventCard from '@components/cards/EventCard';
import Card from '@components/layout/Card/Card';
import { useDialog } from '@components/layout/Dialog/useDialog';
import { EmptyState } from '@components/layout/EmptyState/EmptyState';
import { ErrorBoundary } from '@components/layout/ErrorBoundary';
import { LetterAvatar } from '@components/layout/LetterAvatar/LetterAvatar';
import { Screen } from '@components/layout/Screen';
import { Swiper } from '@components/layout/Swiper/Swiper';
import { TitleToolbar } from '@components/layout/TitleToolbar/TitleToolbar';

import { useAuthGate } from '@screens/LogInScreen/AuthGateContext';

import { EditEntertainerPopup } from '../EntertainerDetailsScreen/EditEntertainerPopup';
import { BookingHandleInput } from '../ManageEntertainerProfileScreen';
import { OnboardEntertainerPopup } from '../OnboardEntertainerScreen/OnboardEntertainerScreen';
import { FeaturedPhotoManagementSection } from '../components/FeaturedPhotoManagementSection';
import profileSlide1 from './profile-1.jpg';
import profileSlide2 from './profile-2.jpg';
import profileSlide3 from './profile-3.jpg';
import profileSlide4 from './profile-4.png';
import storySlide1 from './story-1.jpeg';
import storySlide2 from './story-2.jpeg';
import storySlide3 from './story-3.jpeg';

const EventRequestCard: FC<{ event: FeedEvent }> = ({ event }) => {
  const headerTitle = event.promoters?.[0]?.title ?? event.venue?.title;

  return (
    <Card>
      <div className="reviewed-header-wrapper">
        <div className="reviewed-header">
          <span>Tagged Event</span>
          <Card.Header
            avatar={<LetterAvatar letter={findFirstAlphaCharacter(headerTitle)} />}
            title={headerTitle}
          />
        </div>
      </div>
      <EventCard hideRsvp showPromoter={false} event={event} onClick={() => {}} hideActions />
      <Card.Body className="pt-0">{mapStringToParagraphs(event.excerpt)}</Card.Body>
    </Card>
  );
};

export const LandingPageCampaignScreen = () => {
  const { track } = useAnalytics();
  const navigate = useNavigate();
  const { createAuthGateHandler } = useAuthGate();
  const { userIsLoggedIn } = useAppContext();
  const { entertainer } = useCurrentEntertainerQuery();
  const [isEntertainerOnboardingPopupVisible, setIsEntertainerOnboardingPopupVisible] =
    useState(false);
  const [isEditPopupVisible, setIsEditPopupVisible] = useState(false);

  const [profileExampleIsVisible, setProfileExampleIsVisible] = useState(false);
  const [storyExampleIsVisible, setStoryExampleIsVisible] = useState(false);

  const handleEnter = () => {
    track('Click Enter');
    createAuthGateHandler(() => {});
  };

  return (
    <Screen
      name="Landing Page Campaign Screen"
      headerProps={{
        title: <Screen.GagglTitle />,
      }}
      hideToolbar
      disableLocationPermissionGate
    >
      <Screen.Content maxWidth={425}>
        {/* <Card>
          <img src={headerImage} alt="logo" />
        </Card> */}

        <TitleToolbar text="Earn $1 for Every Badge You Receive!" />
        <p>
          With Gaggl’s badge rewards program, you can turn your creativity into cash. Every time a
          fan sends you a badge through your <strong>werqwith.me</strong> page, you’ll earn{' '}
          <strong>$1 per badge</strong>. Showcase your best looks, share your page, and let your
          fans support you while you get rewarded!
        </p>

        {!entertainer && (
          <Fragment key="unauthorized-actions">
            {userIsLoggedIn ? (
              <EmptyState
                title="You are not eligible to participate"
                content="You must be an active drag entertainer to participate in the badge rewards program. You can create your entertainer profile in the app."
                button={{
                  text: 'Return To App',
                  onClick: () => {
                    track('Click Return To App');
                    navigate('/', {
                      animate: false,
                    });
                  },
                }}
              />
            ) : (
              <Button roundness="rounded" onClick={handleEnter}>
                Get Started
              </Button>
            )}
          </Fragment>
        )}

        {entertainer && (
          <Fragment key="authorized-actions">
            <TitleToolbar text="How It Works:" size="md" />

            <TitleToolbar text="1. Add Your Link to Your Instagram Bio" size="md" />

            <BookingHandleInput entertainer={entertainer} />
            <p>
              Use your unique <strong>werqwith.me</strong> page as your Instagram bio link. It’s
              fully customizable, so you can include all your important links—like Venmo, merch
              stores, booking info, or anything else you want fans to access. Only badges sent
              through your <strong>werqwith.me</strong> page will qualify for the rewards program,
              so make sure this link is in your bio to start earning!
            </p>
            <Button
              variant="smooth"
              color="neutral"
              roundness="rounded"
              onClick={() => {
                setIsEditPopupVisible(true);
              }}
            >
              Edit Profile
            </Button>

            <TitleToolbar text="2. Upload Featured Looks" size="md" />
            <p>
              Share your best looks on your <strong>werqwith.me</strong> page! Fans can send you
              badges directly from your featured looks, giving them an easy way to show their
              support and appreciation.
            </p>
            <FeaturedPhotoManagementSection
              hideTitleToolbar
              cityId={entertainer?.cityIds?.[0]}
              featuredMedia={entertainer.featuredMedia}
            />

            <TitleToolbar text="3. Actively Share Your Profile" size="md" />
            <BookingHandleInput entertainer={entertainer} />
            <p>
              Your <strong>werqwith.me</strong> page is designed for easy sharing. Post Instagram
              Stories or feed posts highlighting your look, and include the link to your page so
              fans can quickly click and send you badges. For example, share a story with your look
              and add the link to your page for direct engagement.
            </p>

            <div style={{}}>
              <hr style={{ margin: '48px 0' }} />
              <TitleToolbar text="Werq Smarter, Not Harder" size="md" />
              <p>
                <strong>How to Update Your Instagram Bio Link</strong>
              </p>

              {profileExampleIsVisible ? (
                <Swiper pagination={true} modules={[Pagination, Navigation]}>
                  {[profileSlide1, profileSlide2, profileSlide3, profileSlide4].map((slide) => (
                    <Swiper.Slide>
                      <div>
                        <img
                          src={slide}
                          style={{ display: 'block', margin: '0 auto', width: 180, height: 'auto' }}
                        />
                      </div>
                    </Swiper.Slide>
                  ))}
                </Swiper>
              ) : (
                <Button
                  color="neutral"
                  variant="smooth"
                  roundness="rounded"
                  onClick={() => {
                    track('Click Profile Example');
                    setProfileExampleIsVisible(true);
                  }}
                >
                  View Example
                </Button>
              )}

              <ol>
                <li>Go to your Instagram profile and tap “Edit Profile.”</li>
                <li>
                  Click the "Link" field, and paste your unique <strong>werqwith.me</strong> link.
                </li>
                <li>Tap “Save” and your link is now live in your bio.</li>
              </ol>

              <p style={{ marginTop: 4 * 12 }}>
                <strong>How to Share Your Werqwith.me Page in a Story</strong>
              </p>

              {storyExampleIsVisible ? (
                <Swiper pagination={true} modules={[Pagination, Navigation]}>
                  {[storySlide1, storySlide2, storySlide3].map((slide) => (
                    <Swiper.Slide>
                      <div>
                        <img
                          src={slide}
                          style={{ display: 'block', margin: '0 auto', width: 180, height: 'auto' }}
                        />
                      </div>
                    </Swiper.Slide>
                  ))}
                </Swiper>
              ) : (
                <Button
                  color="neutral"
                  variant="smooth"
                  roundness="rounded"
                  onClick={() => {
                    track('Click Story Example');
                    setStoryExampleIsVisible(true);
                  }}
                >
                  View Example
                </Button>
              )}

              <ol>
                <li>
                  Create a new Instagram Story with a photo or video showcasing your latest look.
                </li>
                <li>
                  Tap the Sticker Icon (between "Aa" and the Music Icon) at the top of the Story
                  editor.
                </li>
                <li>Tap the Link Icon (chain icon) in the list.</li>
                <li>Paste your werqwith.me link and add it to your Story.</li>
                <li>Include a call-to-action like: “Share a Badge”</li>
                <li>Post the Story and watch the badges roll in!</li>
              </ol>
            </div>

            <div style={{ marginTop: 4 * 36, fontSize: '.8rem' }}>
              <hr />
              <p>
                <em>
                  * Rewards are capped at $100 for $1 badges each month across all participants.
                  Once the cap is reached, badges will still earn $0.50 each for the remainder of
                  the month.
                </em>
              </p>
            </div>
          </Fragment>
        )}
      </Screen.Content>
      <ErrorBoundary>
        <OnboardEntertainerPopup
          isVisible={isEntertainerOnboardingPopupVisible}
          onClose={() => {
            track('Close onboard entertainer popup');
            setIsEntertainerOnboardingPopupVisible(false);
          }}
        />
      </ErrorBoundary>
      <ErrorBoundary>
        {entertainer && (
          <EditEntertainerPopup
            isVisible={isEditPopupVisible}
            onClose={() => {
              setIsEditPopupVisible(false);
            }}
          />
        )}
      </ErrorBoundary>
    </Screen>
  );
};
