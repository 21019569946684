import { useMutation, useQueryClient } from '@tanstack/react-query';
import { FC, MouseEventHandler, useRef, useState } from 'react';

import dayjs from 'dayjs';
import pluralize from 'pluralize';

import API from '@api/API';

import { Entertainer } from '@queries/EntertainerQueries';

import { useAnalytics } from '@hooks/useAnalytics';

import { useNavigate } from '@router/index';

import Button from '@components/buttons/Button';
import { AsyncImage } from '@components/layout/AsyncImg';
import Card from '@components/layout/Card/Card';
import { useDialog } from '@components/layout/Dialog/useDialog';

import { useEntertainerImpression } from '@screens/entertainer/EntertainerDetailsScreen/sendEntertainerDetailsPageView';

type InquireEntertainerAvailabilityPayload = {
  cityId: string;
  date: string;
};

const useInquireEntertainerAvailabilityMutation = (entertainerId: string) => {
  const { isPending, mutateAsync } = useMutation({
    mutationFn: (payload: InquireEntertainerAvailabilityPayload) => {
      return API.post(`/v1/entertainers/${entertainerId}/inquire`, payload);
    },
  });

  return {
    isInquiringEntertainerAvailability: isPending,
    inquireEntertainerAvailabilityAsync: mutateAsync,
  };
};

export const EntertainerAvailabilityCard: FC<{
  entertainer: Pick<
    Entertainer,
    | '_id'
    | 'avatar'
    | 'featuredMedia'
    | 'bookingHandle'
    | 'title'
    | 'cities'
    | 'badgeCount'
    | 'details'
  >;
  cityId: string;
  date?: string;
  back: string;
}> = ({ entertainer, date: initialDate, back }) => {
  const divRef = useRef();
  const { track } = useAnalytics();
  const navigate = useNavigate();
  const { openConfirmDialog } = useDialog();
  const [date, setDate] = useState(initialDate);
  const { inquireEntertainerAvailabilityAsync } = useInquireEntertainerAvailabilityMutation(
    entertainer._id,
  );

  useEntertainerImpression(entertainer._id, divRef);

  const image = entertainer.avatar || entertainer.featuredMedia?.[0]?.image;
  const imageSize = 3 * 18;

  const handleClick = () => {
    track('Click Entertainer Availability Card');
    navigate(`/noauth/entertainer/${entertainer.bookingHandle}`, {
      state: {
        back,
      },
    });
  };

  const [didSend, setDidSend] = useState(false);
  const handleInquire: MouseEventHandler = (e) => {
    e.stopPropagation();
    track('Click Inquire Entertainer');
    openConfirmDialog({
      title: 'Inquire',
      text: `Are you sure you want to send an inquiry to ${entertainer.title}? This will send them a notification for ${dayjs(date).format('ddd, MMM D, YYYY')}.`,
      buttonOk: 'Send',
      onConfirm: () => {
        setDidSend(true);
        inquireEntertainerAvailabilityAsync({ cityId: entertainer.cities[0]._id, date });
      },
    });
  };

  const handleBook: MouseEventHandler = (e) => {
    e.stopPropagation();
    track('Click Book Entertainer');
    navigate(`/noauth/book/${entertainer.bookingHandle}`, {
      state: {
        back,
      },
    });
  };

  return (
    <Card>
      <div ref={divRef}>
        <Card.Header
          className="p-2"
          avatar={
            <div
              style={{
                position: 'relative',
                width: imageSize,
                height: imageSize,
              }}
            >
              <div style={{ borderRadius: 16, overflow: 'hidden', width: '100%', height: '100%' }}>
                {image ? (
                  <AsyncImage
                    src={image}
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover',
                    }}
                    placeholder={
                      <div
                        style={{
                          width: '100%',
                          height: '100%',
                          background: 'rgba(255,255,255,.2)',
                        }}
                      />
                    }
                  />
                ) : (
                  <div
                    style={{ width: '100%', height: '100%', background: 'rgba(255,255,255,.2)' }}
                  />
                )}
              </div>
            </div>
          }
          tagline={entertainer.cities.map((city) => city.name).join(' • ')}
          title={entertainer.title}
          subtitle={[
            `${entertainer.badgeCount} ${pluralize('Badge', entertainer.badgeCount)}`,
            `${entertainer.featuredMedia.length} ${pluralize('Look', entertainer.featuredMedia.length)}`,
            `${entertainer.details.categories.length} ${pluralize('Category', entertainer.details.categories.length)}`,
          ].join(' • ')}
        />
        <Card.Footer className="d-flex gap-2 px-2 pb-2">
          <Button
            className="py-0"
            style={{ height: 36 }}
            variant="smooth"
            color="neutral"
            roundness="rounded"
            onClick={handleClick}
          >
            View Profile
          </Button>
          {date && (
            <Button
              className="py-0"
              style={{ height: 36 }}
              variant="smooth"
              color="neutral"
              roundness="rounded"
              disabled={didSend}
              onClick={handleInquire}
            >
              {didSend ? 'Inquiry sent!' : 'Inquire'}
            </Button>
          )}
        </Card.Footer>
      </div>
    </Card>
  );
};
