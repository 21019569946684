import * as Sentry from '@sentry/react';
import { FC, useRef, useState } from 'react';
import PhoneInput, { formatPhoneNumber, isValidPhoneNumber } from 'react-phone-number-input';
import flags from 'react-phone-number-input/flags';

import { useAppContext } from '@contexts/AppContext';

import { useCurrentUser } from '@queries/UserQueries';

import { useClaimEntertainerByInstagramHandleMutation } from '@mutations/EntertainerMutations';

import { useAnalytics } from '@hooks/useAnalytics';

import { openExternalUrl } from '@utilities/openExternalUrl';

import Button from '@components/buttons/Button';
import { FormLabel } from '@components/form/FormLabel';
import { Input } from '@components/form/Input';
import { TitleToolbar } from '@components/layout/TitleToolbar/TitleToolbar';

import { AuthPopupProps } from './LogInScreen';

export interface AuthPopupEntertainer {
  title: string;
  contact: {
    instagram: string;
  };
}

const Step1: FC<
  AuthPopupProps & {
    onSubmit: (entertainerProperties: AuthPopupEntertainer) => void;
  }
> = ({ onSubmit }) => {
  const { track } = useAnalytics();
  const [updatedEntertainerProperties, setUpdatedEntertainerProperties] = useState({
    title: '',
    contact: {
      instagram: '',
    },
  });

  const handleContinue = () => {
    track('Light Entertainer Onboarding', {
      title: updatedEntertainerProperties.title,
      instagram: updatedEntertainerProperties.contact.instagram,
    });
    onSubmit(updatedEntertainerProperties);
  };

  return (
    <>
      <TitleToolbar text="Step 1 of 2: Entertainer Details" size="md" />
      <div className="d-grid gap-2">
        <div>
          <FormLabel text="Stage Name *" />
          <Input
            name="stage-name"
            placeholder="eg. Glitter Disco, Willow Wisp, etc."
            onChange={(e) =>
              setUpdatedEntertainerProperties((prevValue) => ({
                ...prevValue,
                title: e.target.value,
              }))
            }
          />
        </div>
        <div>
          <FormLabel text="Instagram Username *" />
          <Input
            type="text"
            name="instagram"
            placeholder="e.g. your.ig.handle"
            onChange={(e) =>
              setUpdatedEntertainerProperties((prevValue) => ({
                ...prevValue,
                contact: { instagram: e.target.value },
              }))
            }
          />
        </div>
        <div>
          <Button
            roundness="rounded"
            onClick={handleContinue}
            disabled={
              !updatedEntertainerProperties || !updatedEntertainerProperties.contact.instagram
            }
          >
            Continue
          </Button>
        </div>
      </div>
    </>
  );
};

const Step2: FC<AuthPopupProps & { onSubmit: () => void }> = ({ onSubmit, ...props }) => {
  const { phoneNumber, setPhoneNumber } = props;

  return (
    <>
      <TitleToolbar text="Step 2 of 2: Verification" size="md" />
      <p className="mt-0">
        For security, we require a phone number, but no worries, it stays confidential and is never
        shared.
      </p>
      <div className="d-grid gap-2">
        <div>
          <FormLabel text="Phone Number *" />
          <PhoneInput
            placeholder="(303) 555-1234"
            defaultCountry="US"
            flags={flags}
            value={phoneNumber}
            onChange={setPhoneNumber}
            inputComponent={Input}
          />
        </div>
        <div>
          <Button
            roundness="rounded"
            onClick={onSubmit}
            disabled={props.isAuthorizing || !isValidPhoneNumber(phoneNumber)}
          >
            Finish
          </Button>
        </div>
        <p className="text-center">
          {'By continuing, you agree to our '}
          <strong onClick={() => openExternalUrl('https://gaggl.app/terms')}>Terms</strong>
          {' and '}
          <strong onClick={() => openExternalUrl('https://gaggl.app/privacy-policy')}>
            Privacy Policy
          </strong>
          .
        </p>
      </div>
    </>
  );
};

export const EntertainerLoginContent: FC<AuthPopupProps> = (props) => {
  const { currentUser } = useCurrentUser();
  const entertainerRef = useRef<AuthPopupEntertainer>();
  const [step, setStep] = useState(1);

  const handleClaim = async () => {
    if (entertainerRef.current.contact.instagram) {
      await useClaimEntertainerByInstagramHandleMutation.mutationFn({
        title: entertainerRef.current.title,
        instagram: entertainerRef.current.contact.instagram,
        phoneNumber: props.phoneNumber ? props.phoneNumber : currentUser?.phone,
        cityId: props.options?.cityId,
      });
    }
  };

  const handleSubmitFirstStep = async (entertainerProperties: AuthPopupEntertainer) => {
    try {
      if (!currentUser?._id) {
        setStep(2);
      }
      await props.entertainerCallback(entertainerProperties);
      entertainerRef.current = entertainerProperties;

      if (currentUser?._id) {
        await handleClaim();
        props.onClose();
        props.redirectOverride();
      }
    } catch (e) {
      Sentry.captureException(e);
    }
  };

  const handleSumbitSecondStep = async () => {
    try {
      await props.handleInternalPhoneLogIn();
      try {
        await handleClaim();
      } catch (e) {
        Sentry.captureException(e);
      }
    } catch (e) {
      Sentry.captureException(e);
    }
  };

  return (
    <div>
      {step === 1 && <Step1 {...props} onSubmit={handleSubmitFirstStep} />}
      {step === 2 && <Step2 {...props} onSubmit={handleSumbitSecondStep} />}
    </div>
  );
};
