import { useMemo, useState } from 'react';

import { useAppContext } from '@contexts/AppContext';
import * as dayjs from 'dayjs';
import { capitalize, groupBy } from 'lodash';

import {
  EntertainerBookingOpportunity,
  useCurrentEntertainerQuery,
} from '@queries/EntertainerQueries';
import {
  BOOKING_OPPORTUNITY_ENTERTAINER_STATUSES,
  PublicBookingOpportunity,
} from '@queries/PromoterQueries';

import {
  useRespondToBookingOpportunityMutation,
  useRespondToBookingOpportunityNoAuthMutation,
} from '@mutations/EntertainerMutations';

import { useAnalytics } from '@hooks/useAnalytics';

import { useNavigate } from '@router/index';

import Button from '@components/buttons/Button';
import { OptionButton } from '@components/buttons/OptionButton';
import { Chip } from '@components/chips/Chip';
import { FontAwesomeIcon } from '@components/icons/FontAwesomeIcon';
import Card from '@components/layout/Card/Card';
import { PopoverWrapper } from '@components/layout/Popover/PopoverWrapper';

import { useAuthGate } from '@screens/LogInScreen/AuthGateContext';

export const formatStatus = (status: string) => {
  if (status === 'new') {
    return 'New';
  }

  if (status === 'notInterested') {
    return 'Not Interested';
  }

  return capitalize(status);
};

const StatusActionButton = ({
  bookingOpportunity,
}: {
  bookingOpportunity: Pick<EntertainerBookingOpportunity, '_id' | 'status' | 'city'>;
}) => {
  const [status, setStatus] = useState(bookingOpportunity.status);
  const { createAuthGateHandler } = useAuthGate();
  const { track } = useAnalytics();

  const { respondToBookingOpportunityAsync } = useRespondToBookingOpportunityMutation();
  const { respondToBookingOpportunityNoAuthAsync } = useRespondToBookingOpportunityNoAuthMutation();

  if (['confirmed', 'rejected'].includes(status)) {
    return (
      <Button
        disabled
        className="py-2 px-3 w-100"
        roundness="rounded"
        variant="outlined"
        color="neutral"
        size="sm"
      >
        {formatStatus(status)}
      </Button>
    );
  }

  const handleRsvp = async (status: 'interested' | 'notInterested') => {
    track('Booking Opportunity RSVP', { status });

    createAuthGateHandler(
      async () => {
        setStatus(status);
        await respondToBookingOpportunityAsync({
          bookingOpportunityId: bookingOpportunity._id,
          status,
        });
      },
      {
        entertainerCallback: async ({ title, contact }) => {
          await respondToBookingOpportunityNoAuthAsync({
            bookingOpportunityId: bookingOpportunity._id,
            title,
            instagram: contact?.instagram,
            status,
          });
        },
      },
    );
  };

  return (
    <OptionButton
      className="w-100"
      label={['new', 'open'].includes(status) ? 'RSVP' : formatStatus(status)}
      accept={{
        label: 'Interested',
        onClick: () => handleRsvp('interested'),
      }}
      deny={{
        label: 'Not Interested',
        onClick: () => handleRsvp('notInterested'),
      }}
    />
  );
};

export const BookingOpportunityCard = ({
  bookingOpportunity,
}: {
  bookingOpportunity: Pick<
    EntertainerBookingOpportunity,
    '_id' | 'status' | 'type' | 'promoter' | 'title' | 'startDate' | 'city'
  >;
}) => {
  const { entertainer } = useCurrentEntertainerQuery();
  const navigate = useNavigate();

  const handleDetailsClick = () => {
    navigate(
      entertainer
        ? `/manage/entertainer/booking-opportunity/${bookingOpportunity._id}`
        : `/noauth/booking-opportunity/${bookingOpportunity._id}`,
    );
  };

  return (
    <Card style={{ overflow: 'visible' }}>
      {bookingOpportunity.type === 'openStage' && (
        <div style={{ position: 'absolute', right: 8, top: -12 }}>
          <Chip label="Open Stage" size="sm" color="deeppurple" />
        </div>
      )}
      <Card.Header
        tagline={bookingOpportunity?.promoter?.title}
        title={bookingOpportunity.title}
        subtitle={`${bookingOpportunity.startDate ? dayjs(bookingOpportunity.startDate).format('ddd, MMM D, YYYY') : 'Date TBD'} | ${bookingOpportunity.city?.name}`}
        action={<StatusActionButton bookingOpportunity={bookingOpportunity} />}
      />
      <Card.Footer>
        <Button variant="smooth" color="neutral" roundness="rounded" onClick={handleDetailsClick}>
          Details
        </Button>
      </Card.Footer>
    </Card>
  );
};
