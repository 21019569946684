import { FC, useLayoutEffect, useMemo, useRef, useState } from 'react';

import Button from '@components/buttons/Button';

import './Textarea.scss';

interface TextareaProps {
  name?: string;
  focusOnMount?: boolean;
  growWithContent?: boolean;
  rows?: number;
  placeholder?: string;
  defaultValue?: string;
  onChange: (updatedValue: string) => void;
  submitButton?: {
    dataTestId?: string;
    text: string;
    disabled: boolean;
    onClick: () => Promise<void>;
  };
}

export const Textarea: FC<TextareaProps> = ({
  name,
  focusOnMount,
  growWithContent,
  rows,
  placeholder,
  defaultValue,
  onChange,
  submitButton,
}) => {
  const internalRef = useRef<HTMLTextAreaElement>(null);
  const [uncontrolledValue, setValue] = useState<string>(defaultValue ?? '');
  const value = useMemo(() => uncontrolledValue, [uncontrolledValue]);

  useLayoutEffect(() => {
    if (focusOnMount) {
      internalRef.current?.focus();
    }

    if (growWithContent) {
    }
  }, []);

  const hasFooter = !!submitButton;

  const handleChange = (updatedValue: string) => {
    setValue(updatedValue);
    onChange(updatedValue);
  };

  return (
    <div className={`textarea-wrapper ${hasFooter ? 'has-footer' : ''}`}>
      <textarea
        ref={internalRef}
        name={name}
        rows={rows}
        placeholder={placeholder}
        value={uncontrolledValue}
        onChange={(event) => handleChange(event.target.value)}
      />
      {hasFooter && (
        <div className="textarea-footer d-flex align-items-center">
          <div className="ml-auto">
            <Button
              data-testid={submitButton?.dataTestId}
              disabled={!value || submitButton?.disabled}
              onClick={async () => {
                await submitButton.onClick();
                setValue('');
              }}
            >
              {submitButton.text}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};
