import { FC, useEffect, useMemo, useRef, useState } from 'react';

import dayjs from 'dayjs';

import {
  fetchCurrentEntertainer,
  useCurrentEntertainerAvailabilityQuery,
  useCurrentEntertainerQuery,
  useFetchCurrentEntertainer,
} from '@queries/EntertainerQueries';

import { useUpdateEntertainerAvailabilityMutation } from '@mutations/EntertainerMutations';
import { useAddUserBookingOpportunitiesMutation } from '@mutations/UserMutations';

import { useAnalytics } from '@hooks/useAnalytics';
import { useCitiesQuery, useCurrentUserCity } from '@hooks/useCurrentUserLocation';

import { useRouter } from '@router/index';

import { createScreenComponent } from '@utilities/createScreenComponent';

import Button from '@components/buttons/Button';
import IconButton from '@components/buttons/IconButton';
import { FormLabel } from '@components/form/FormLabel';
import { Input } from '@components/form/Input';
import { Select } from '@components/form/Select';
import { Textarea } from '@components/form/Textarea';
import { FontAwesomeIcon } from '@components/icons/FontAwesomeIcon';
import Card from '@components/layout/Card/Card';
import { useDialog } from '@components/layout/Dialog/useDialog';
import { EmptyState } from '@components/layout/EmptyState/EmptyState';
import { HorizontalScroll } from '@components/layout/HorizontalScroll/HorizontalScroll';
import { InlineDatePicker } from '@components/layout/InlineDatePicker/InlineDatePicker';
import { LinkBanner } from '@components/layout/LinkBanner/LinkBanner';
import { List } from '@components/layout/List/List';
import { Screen } from '@components/layout/Screen';
import { Skeleton } from '@components/layout/Skeleton/Skeleton';
import { TitleToolbar } from '@components/layout/TitleToolbar/TitleToolbar';

import { useAuthGate } from '@screens/LogInScreen/AuthGateContext';

export const PrimaryBookingWizardEntryPoint: FC<{
  onClickEntertainer: () => void;
  onClickPromoter: () => void;
}> = ({ onClickEntertainer, onClickPromoter }) => {
  const { track } = useAnalytics();
  const handleEntertainer = () => {
    track('Booking Wizard - Entertainer');
    onClickEntertainer();
  };
  const handlePromoter = () => {
    track('Booking Wizard - Promoter');
    onClickPromoter();
  };

  return (
    <Card>
      <Card.Header title="⬇️ Are you..." className="pt-1 pb-0" />
      <List
        style={{
          // borderTop: '1px solid rgba(255,255,255,.1)',
          background: 'rgba(0,0,0,.25)',
        }}
        listItems={[
          {
            hasCheckbox: true,
            title: 'An entertainer looking for gigs',
            subTitle: 'Share your availability and get discovered for upcoming opportunities.',
            onClick: handleEntertainer,
          },
          {
            hasCheckbox: true,
            title: 'A producer looking for entertainers',
            subTitle: 'Find performers who fit your needs and book your next event.',
            onClick: handlePromoter,
          },
        ]}
      />
    </Card>
  );
};

const BookingOpportunityDetailsForm = ({
  isTalentFocused,
  cityId,
  goBackOnSave,
}: {
  isTalentFocused: boolean;
  cityId: string;
  goBackOnSave: boolean;
}) => {
  const { navigate, back: navigateBack } = useRouter();
  const { track } = useAnalytics();
  const { createAuthGateHandler } = useAuthGate();
  const { isAddingUserBookingOpportunities, addUserBookingOpportunitiesAsync } =
    useAddUserBookingOpportunitiesMutation();

  const [bookingOpportunityProperties, setBookingOpportunityProperties] = useState({
    title: '',
    details: '',
  });

  const handleSubmitTalentFocused = () => {
    track('Booking Wizard - Submit Talent Focused');
    createAuthGateHandler(
      async () => {
        await addUserBookingOpportunitiesAsync({
          cityId,
          bookingOpportunites: [bookingOpportunityProperties],
          isTalentFocused: true,
        });

        if (goBackOnSave) {
          navigateBack('/gigs-and-talent');
        } else {
          navigate('/gigs-and-talent');
        }
      },
      {
        authMessage:
          'Almost there! For security, we require a phone number, but no worries, it stays confidential and is never shared.',
      },
    );
  };

  const cannotSubmit =
    !bookingOpportunityProperties.title ||
    !bookingOpportunityProperties.details ||
    isAddingUserBookingOpportunities;

  return (
    <>
      <TitleToolbar text="Tell us more!" size="md" />
      <p className="mt-0">
        Let entertainers know what type of show or event you’re producing and this will go out to
        all registered entertainers in your area.
      </p>
      <div className="d-grid gap-2">
        <div>
          <FormLabel text="Subject *" />
          <Input
            name="title"
            placeholder="ISO: Trans BIPOC artists"
            onChange={(event) =>
              setBookingOpportunityProperties((prevValue) => ({
                ...prevValue,
                title: event.target.value,
              }))
            }
          />
        </div>
        <div>
          <FormLabel text="Details *" />
          <Textarea
            name="details"
            placeholder="I'm looking to put on a show highlighting the best Trans BIPOC artists that our city has to offer. If you have ANY interest, please RSVP!"
            onChange={(value) =>
              setBookingOpportunityProperties((prevValue) => ({ ...prevValue, details: value }))
            }
          />
        </div>
      </div>
      <div className="d-grid mt-3 gap-2">
        <Button roundness="rounded" disabled={cannotSubmit} onClick={handleSubmitTalentFocused}>
          Request Talent
        </Button>
        {!isTalentFocused && (
          <Button roundness="rounded" color="neutral" variant="flat">
            Skip
          </Button>
        )}
      </div>
    </>
  );
};

type BookingWizardType = 'entertainer' | 'promoter';

export const BookingWizardScreen = createScreenComponent<{
  type?: BookingWizardType;
  back?: string;
  goBackOnSave?: boolean;
}>(({ type: initialType, goBackOnSave, back, f7route }) => {
  const [type, setType] = useState(initialType);
  const { openConfirmDialog } = useDialog();
  const now = new Date();
  const [currentMonth, setCurrentMonth] = useState(now.getMonth());
  const [currentYear, setCurrentYear] = useState(now.getFullYear());
  const [selectedCity, setSelectedCity] = useState<string>();
  const [didSubmitCity, setDidSubmitCity] = useState(false);
  const [isTalentFocused, setIsTalentFocused] = useState(false);
  const [selectedDates, setSelectedDates] = useState<Record<string, boolean>>({});
  const { currentCityIsReady, currentCity: currentCityQueryValue } = useCurrentUserCity();
  const { cities = [] } = useCitiesQuery();
  const currentCity = useMemo(() => {
    if (f7route.query.cityId) {
      const matchingCity = cities.find(({ _id }) => _id === f7route.query.cityId);

      if (matchingCity) {
        return matchingCity;
      }
    }

    if (currentCityQueryValue) {
      return currentCityQueryValue;
    }

    return undefined;
  }, [currentCityQueryValue, f7route.query.cityId, cities]);
  const cityId = selectedCity ?? currentCity?._id;
  const { isSubmitting: isUpdatingEntertainerAvailability, updateEntertainerAvailabilityAsync } =
    useUpdateEntertainerAvailabilityMutation();
  const { addUserBookingOpportunitiesAsync } = useAddUserBookingOpportunitiesMutation();
  const fetchCurrentEntertainer = useFetchCurrentEntertainer();
  const { entertainer } = useCurrentEntertainerQuery();
  const { currentEntertainerAvailabilityIsReady, currentEntertainerAvailability } =
    useCurrentEntertainerAvailabilityQuery(entertainer?._id);
  const didInitRef = useRef(false);

  useEffect(() => {
    if (!didInitRef.current && currentEntertainerAvailabilityIsReady) {
      setSelectedDates(currentEntertainerAvailability ?? {});
      didInitRef.current = true;
    }
  }, [currentEntertainerAvailabilityIsReady, currentEntertainerAvailability]);

  const { navigate, back: navigateBack } = useRouter();
  const { track } = useAnalytics();
  const { createAuthGateHandler } = useAuthGate();
  const handleSubmitEntertainerAvailability = () => {
    track('Booking Wizard - Submit Availability');
    createAuthGateHandler(
      async () => {
        // Submit availability
        await updateEntertainerAvailabilityAsync({
          cityIds: currentCity?._id ? [currentCity._id] : undefined,
          availability: selectedDates,
          referredBy: f7route.query?.referredBy,
        });
        const { entertainer: currentEntertainer } = await fetchCurrentEntertainer();

        if (goBackOnSave) {
          navigateBack('/gigs-and-talent');
        } else {
          navigate(currentEntertainer ? '/gigs-and-talent' : '/entertainer/onboarding', {
            state: {
              onCompletePath: '/gigs-and-talent',
            },
          });
        }
      },
      {
        entertainerCallback: () => {},
        cityId,
      },
    );
  };

  const handleSubmitBookingOpportunityDates = () => {
    track('Booking Wizard - Submit Booking Opportunity');
    createAuthGateHandler(async () => {
      await addUserBookingOpportunitiesAsync({
        cityId,
        bookingOpportunites: Object.entries(selectedDates)
          .filter(([, isSelected]) => isSelected)
          .map(([startDate]) => ({ startDate })),
        isTalentFocused: false,
      });

      if (goBackOnSave) {
        navigateBack('/gigs-and-talent');
      } else {
        navigate('/gigs-and-talent');
      }
    });
  };

  const handleTalentFocused = () => {
    track('Booking Wizard - Talent Focused');
    openConfirmDialog({
      title: 'Are you sure?',
      text: 'Are you producing a show but need the right talent before you can set the date?',
      buttonOk: 'Continue',
      onConfirm: () => {
        setIsTalentFocused(true);
      },
    });
  };

  const handleFlexibleAvailability = () => {
    track('Booking Wizard - Flexible Availability');
    createAuthGateHandler(
      async () => {
        await updateEntertainerAvailabilityAsync({
          cityIds: currentCity?._id ? [currentCity._id] : undefined,
          availability: selectedDates,
          hasFlexibleAvailability: true,
          referredBy: f7route.query?.referredBy,
        });
        const { entertainer: currentEntertainer } = await fetchCurrentEntertainer();

        if (goBackOnSave) {
          navigateBack(back ?? '/gigs-and-talent');
        } else {
          navigate(back ?? (currentEntertainer ? '/gigs-and-talent' : '/entertainer/onboarding'), {
            state: {
              onCompletePath: '/gigs-and-talent',
            },
          });
        }
      },
      {
        entertainerCallback: () => {},
        cityId,
      },
    );
  };

  const hasSelectedDates = Object.values(selectedDates).some((isSelected) => isSelected);
  const cannotSubmit = !hasSelectedDates || (!currentCity?._id && !selectedCity);
  const cannotBeReferred = f7route.query?.referredBy && entertainer;

  return (
    <Screen
      name="Booking Wizard Screen"
      headerProps={{ title: <Screen.GagglTitle />, back: back ? { to: back } : undefined }}
      hideToolbar
      disableLocationPermissionGate
    >
      <Screen.Content maxWidth={425}>
        {type ? (
          <>
            {currentCity?._id || didSubmitCity ? (
              <>
                {type === 'entertainer' && (
                  <>
                    <TitleToolbar text="Set Your Availability" size="md" />
                    <p className="mt-0">
                      Choose the dates you’re available to perform. Producers can view your
                      availability and reach out with opportunities that match your schedule.
                    </p>
                    <InlineDatePicker
                      minDate={dayjs().format('YYYY-MM-DD')}
                      value={selectedDates}
                      onChange={setSelectedDates}
                      onMonthYearChange={(year, month) => {
                        setCurrentMonth(month);
                        setCurrentYear(year);
                      }}
                    />
                    <div className="mt-3 d-grid gap-2">
                      <Button
                        roundness="rounded"
                        color="neutral"
                        variant="smooth"
                        onClick={() => {
                          const newSelectedDates = {};
                          const daysInMonth = dayjs(
                            `${currentYear}-${currentMonth + 1}-01`,
                          ).daysInMonth(); // Get the days in the current month

                          for (let i = 1; i <= daysInMonth; i++) {
                            const date = dayjs(`${currentYear}-${currentMonth + 1}-${i}`);
                            if (!date.isBefore(dayjs(), 'day')) {
                              newSelectedDates[date.format('YYYY-MM-DD')] = true; // Only add valid dates
                            }
                          }

                          setSelectedDates((prevValue) => {
                            return {
                              ...prevValue,
                              ...newSelectedDates,
                            };
                          });
                        }}
                      >
                        Select Entire Month
                      </Button>
                      <Button
                        roundness="rounded"
                        disabled={cannotSubmit || isUpdatingEntertainerAvailability}
                        onClick={handleSubmitEntertainerAvailability}
                      >
                        Save Availability
                      </Button>
                      <Button
                        roundness="rounded"
                        color="neutral"
                        variant="flat"
                        disabled={isUpdatingEntertainerAvailability}
                        onClick={handleFlexibleAvailability}
                      >
                        I have flexible availability
                      </Button>
                    </div>
                  </>
                )}
                {type === 'promoter' && (
                  <>
                    {!isTalentFocused ? (
                      <>
                        <TitleToolbar text="When Are You Looking for Talent?" size="md" />
                        <p className="mt-0">
                          Let us know the dates you need talent for your event. This helps
                          entertainers check their availability and reach out if they’re the perfect
                          fit.
                        </p>
                        <InlineDatePicker value={selectedDates} onChange={setSelectedDates} />
                        <div className="mt-3 d-grid gap-2">
                          <Button
                            roundness="rounded"
                            disabled={cannotSubmit}
                            onClick={handleSubmitBookingOpportunityDates}
                          >
                            Request Talent
                          </Button>
                          <Button
                            roundness="rounded"
                            color="neutral"
                            variant="flat"
                            disabled={isUpdatingEntertainerAvailability}
                            onClick={handleTalentFocused}
                          >
                            I'm more interested in the right talent
                          </Button>
                        </div>
                      </>
                    ) : (
                      <BookingOpportunityDetailsForm
                        isTalentFocused={isTalentFocused}
                        cityId={cityId}
                        goBackOnSave={goBackOnSave}
                      />
                    )}
                  </>
                )}
              </>
            ) : (
              <>
                <TitleToolbar text="Select a City to Get Started" size="md" />
                <p className="mt-0">
                  To help you connect with entertainers and producers in your area, we first need to
                  know where you’re looking.
                </p>
                <div className="d-grid gap-2">
                  <Select
                    value={selectedCity}
                    options={[
                      { value: '', label: '--' },
                      ...cities
                        .filter(({ eventsAreEnabled }) => eventsAreEnabled)
                        .map(({ _id, name }) => ({
                          value: _id,
                          label: name,
                        })),
                    ]}
                    onChange={(e) => {
                      setSelectedCity(e.target.value);
                    }}
                  />
                  <Button
                    roundness="rounded"
                    disabled={!selectedCity}
                    onClick={() => {
                      setDidSubmitCity(true);
                    }}
                  >
                    Next
                  </Button>
                </div>
              </>
            )}
          </>
        ) : (
          <>
            {cannotBeReferred ? (
              <>
                <TitleToolbar text="You're already signed up!" size="md" />
                <p className="mt-0 mb-3">
                  You already have an entertainer account so you can't be referred by someone else.
                  But good news! You can refer others to Gaggl and earn rewards as well! Follow the
                  link to learn more.
                </p>
                <Button
                  roundness="rounded"
                  onClick={() => {
                    track('Booking Wizard - Referral Learn More');
                    navigate('/noauth/referral-program');
                  }}
                >
                  Learn More
                </Button>
              </>
            ) : (
              <>
                {!initialType && (
                  <>
                    <TitleToolbar text="Welcome to Gaggl's Booking Wizard!" size="md" />
                    <p className="mt-0 mb-3">
                      Whether you're an entertainer looking for gigs or a producer looking for
                      entertainers, our Booking Wizard is here to help you connect and book your
                      next event.
                    </p>
                  </>
                )}
                <PrimaryBookingWizardEntryPoint
                  onClickEntertainer={() => {
                    setType('entertainer');
                  }}
                  onClickPromoter={() => {
                    setType('promoter');
                  }}
                />
              </>
            )}
          </>
        )}
      </Screen.Content>
    </Screen>
  );
});
