import { useQuery } from '@tanstack/react-query';
import { Badge } from 'framework7-react';
import { FC, useEffect, useRef, useState } from 'react';

import { useAppSettingsQuery } from '@contexts/AppContext';
import { groupBy } from 'lodash';
import { sumBy } from 'lodash';
import pluralize from 'pluralize';

import API from '@api/API';

import {
  CurrentEntertainer,
  Entertainer,
  EntertainerBadge,
  useCurrentEntertainerBadgesQuery,
  useCurrentEntertainerQuery,
  useFetchCurrentEntertainer,
} from '@queries/EntertainerQueries';

import { useAddEntertainerMediaMutation } from '@mutations/EntertainerMutations';

import { useAnalytics } from '@hooks/useAnalytics';
import { useExportComponentToImage } from '@hooks/useExportComponentToImage';
import { useShareImage } from '@hooks/useShareLink';

import { useNavigate } from '@router/index';

import { openExternalUrl } from '@utilities/openExternalUrl';

import Button from '@components/buttons/Button';
import { ImageUploadField } from '@components/form/ImageUploadField';
import { Input } from '@components/form/Input';
import { AsyncImage } from '@components/layout/AsyncImg';
import Card from '@components/layout/Card/Card';
import { EmptyState } from '@components/layout/EmptyState/EmptyState';
import { HorizontalScroll } from '@components/layout/HorizontalScroll/HorizontalScroll';
import { Popup } from '@components/layout/Popup/Popup';
import { TitleToolbar } from '@components/layout/TitleToolbar/TitleToolbar';

import { useAuthGate } from '@screens/LogInScreen/AuthGateContext';

const BadgeShareImage = ({ src }: { src: string }) => {
  const { data } = useQuery({
    queryKey: ['badgeShareImage', src],
    queryFn: async () => {
      const response = await fetch(src);
      return response.text();
    },
  });

  if (data) {
    return <div dangerouslySetInnerHTML={{ __html: data }} />;
  }

  return <img src={src} style={{ margin: '2px auto', height: 32, width: 32 }} />;
};

export const AddImageContent: FC<{
  onSave: () => void;
  cityId: string;
  meta?: { channel?: string; channelAction?: string };
}> = ({ onSave, cityId, meta = {} }) => {
  const { track } = useAnalytics();
  const fetchCurrentEntertainer = useFetchCurrentEntertainer();
  const { entertainer } = useCurrentEntertainerQuery();
  const { createAuthGateHandler } = useAuthGate();
  const [title, setTitle] = useState<string>('');
  const [updatedImage, setUpdatedImage] = useState<any>();
  const { isSubmitting, addEntertainerMediaAsync } = useAddEntertainerMediaMutation();

  const handleSubmit = () => {
    track('Look Submitted', {
      title,
      image: updatedImage,
      meta,
    });

    createAuthGateHandler(
      async () => {
        let scopedEntertainer = entertainer;

        if (!scopedEntertainer) {
          const { entertainer: updatedEntertainer } = await fetchCurrentEntertainer();
          scopedEntertainer = updatedEntertainer;
        }

        if (scopedEntertainer) {
          await addEntertainerMediaAsync({
            entertainerId: scopedEntertainer?._id,
            title,
            data: updatedImage,
            meta,
          });
        }
        onSave?.();
      },
      {
        entertainerCallback: () => {},
        cityId,
      },
    );
  };

  return (
    <div className="d-grid gap-2">
      <Input
        placeholder="Title *"
        name="featuredLookTitle"
        onChange={(event) => setTitle(event.target.value)}
      />
      <Card>
        <ImageUploadField
          name="featuredLookImage"
          placeholder="Select an image"
          onChange={setUpdatedImage}
        />
      </Card>
      <div>
        <Button
          roundness="rounded"
          disabled={!updatedImage || !title || isSubmitting}
          onClick={handleSubmit}
        >
          {isSubmitting ? 'Uploading...' : 'Upload'}
        </Button>
      </div>
    </div>
  );
};

const ExistingImageCard = ({
  media,
  imageHeight,
  badges,
}: {
  media: CurrentEntertainer['featuredMedia'][number];
  imageHeight?: number;
  badges?: EntertainerBadge[];
}) => {
  const divRef = useRef<HTMLDivElement>(null);
  const { exportComponentAsJPEG } = useExportComponentToImage();
  const { shareImage } = useShareImage();
  const { track } = useAnalytics();
  const { appSettings } = useAppSettingsQuery();

  const badgesByImageUrl = groupBy(badges, 'imageUrl');

  const handleShareImage = async () => {
    exportComponentAsJPEG(divRef, {
      html2CanvasOptions: {
        scale: 5,
      },
      cb: (uri: string) => {
        track('Share Featured Image', {
          mediaId: media._id,
        });
        shareImage({ base64Image: uri });
      },
    });
  };

  const earnedBadges =
    appSettings.entertainerMediaBadges?.filter((badge) => badgesByImageUrl?.[badge.imageUrl]) ?? [];
  const totalBadgeCount = sumBy(
    earnedBadges,
    (badge) => badgesByImageUrl?.[badge.imageUrl]?.length,
  );

  const badgeSection = (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'absolute',
        textAlign: 'center',
        bottom: 0,
        width: '100%',
        padding: 16,
      }}
    >
      <div style={{ background: 'rgba(0,0,0,.5)', padding: 8, borderRadius: 16 }}>
        <div className={totalBadgeCount ? 'mb-2' : ''} style={{ fontSize: 12 }}>
          {totalBadgeCount ? (
            <>My {pluralize('Badge', totalBadgeCount)} for this Look</>
          ) : (
            'No Badges Yet'
          )}
        </div>
        <div
          style={{
            display: 'flex',
            gap: 1,
          }}
        >
          {earnedBadges?.map((badge) => (
            <div
              key={badge._id}
              className="d-flex gap-1 flex-column align-items-center text-center"
              style={{
                width: `${100 / earnedBadges?.length}%`,
              }}
            >
              <div
                style={{
                  width: 36,
                  height: 36,
                  display: 'block',
                  position: 'relative',
                }}
              >
                <div style={{ position: 'absolute', top: -8, right: -8 }}>
                  <Badge color="red">{badgesByImageUrl?.[badge.imageUrl]?.length}</Badge>
                </div>
                <BadgeShareImage src={badge.imageUrl} />
              </div>
              <div style={{ fontSize: 10, lineHeight: 1 }}>{badge.title}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );

  const shareableImage = (
    <div ref={divRef} style={{ position: 'relative' }}>
      <img
        src={media.image}
        style={{
          objectFit: 'cover',
          display: 'block',
          margin: 0,
          padding: 0,
          border: 0,
          outline: 0,
          overflow: 'hidden',
        }}
      />
      {!!earnedBadges?.length && badgeSection}
    </div>
  );

  return (
    <Card>
      <div
        style={{
          opacity: 0,
          position: 'absolute',
        }}
      >
        {shareableImage}
      </div>
      <Card.Header title={media.title} />
      <Card.Body>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: imageHeight,
            position: 'relative',
          }}
        >
          <AsyncImage
            src={media.image}
            style={{ borderRadius: '1rem', height: 'auto', maxHeight: '100%' }}
          />
          {badgeSection}
        </div>
      </Card.Body>
      <Card.Footer>
        <Button variant="smooth" color="neutral" roundness="rounded" onClick={handleShareImage}>
          {totalBadgeCount ? 'Share Badge Count' : 'Share Look'}
        </Button>
      </Card.Footer>
    </Card>
  );
};

export const AddFeaturedPhotoPopup: FC<{
  isVisible: boolean;
  onClose: () => void;
  cityId: string;
  meta?: {
    channel?: string;
    channelAction?: string;
  };
}> = ({ isVisible, onClose, cityId, meta }) => {
  return (
    <Popup isVisible={isVisible} onClose={onClose}>
      <Popup.Content>
        <TitleToolbar className="mt-2" text="Add Featured Look" size="md" />
        {isVisible && <AddImageContent onSave={onClose} meta={meta} cityId={cityId} />}
      </Popup.Content>
    </Popup>
  );
};

export const FeaturedPhotoManagementSection: FC<{
  hideTitleToolbar?: boolean;
  hideSecondaryButton?: boolean;
  cityId: string;
  featuredMedia: Entertainer['featuredMedia'];
}> = ({ hideTitleToolbar = false, hideSecondaryButton = false, cityId, featuredMedia }) => {
  const { track } = useAnalytics();
  const navigate = useNavigate();
  const [addImagePopupIsVisible, setAddImagePopupIsVisible] = useState(false);
  const { entertainer } = useCurrentEntertainerQuery();
  const { receivedBadges = [] } = useCurrentEntertainerBadgesQuery(entertainer?._id);

  const badgesByMediaId = groupBy(
    receivedBadges.filter((badge) => badge.entertainerMediaId),
    'entertainerMediaId',
  );

  if (!entertainer?._id) {
    return <></>;
  }

  return (
    <>
      {!hideTitleToolbar && <TitleToolbar text="Featured Looks 🧚🏽" size="md" />}
      {featuredMedia.length === 0 && (
        <EmptyState
          title="Your Spotlight Awaits!"
          text="Show off your fiercest creations and let your fans celebrate your artistry. Adding a featured look gets you noticed, earns you badges, and helps you climb the leaderboard. Don’t keep your brilliance hidden—share your look now!"
          button={{
            text: 'Add a Featured Look',
            onClick: () => {
              track('Add First Look Click');
              setAddImagePopupIsVisible(true);
            },
          }}
          secondaryButton={
            !hideSecondaryButton && {
              text: 'See Trending Looks',
              onClick: () => {
                track('See Trending Looks Click');
                navigate('/community?topic=featuredLooks', {
                  animate: false,
                });
              },
            }
          }
        />
      )}

      {featuredMedia.length === 1 && (
        <ExistingImageCard
          media={featuredMedia?.[0]}
          badges={badgesByMediaId?.[featuredMedia?.[0]?._id ?? '']}
        />
      )}

      {featuredMedia.length > 1 && (
        <HorizontalScroll
          items={featuredMedia.map((media) => (
            <ExistingImageCard
              key={media._id}
              media={media}
              imageHeight={240}
              badges={badgesByMediaId?.[media._id]}
            />
          ))}
        />
      )}

      {featuredMedia.length > 0 && (
        <Button
          roundness="rounded"
          className="mt-2"
          onClick={() => {
            track('Add Another Look Click');
            setAddImagePopupIsVisible(true);
          }}
        >
          Add Another Look
        </Button>
      )}

      <AddFeaturedPhotoPopup
        isVisible={addImagePopupIsVisible}
        cityId={cityId}
        onClose={() => setAddImagePopupIsVisible(false)}
      />
    </>
  );
};
