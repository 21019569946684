export const mapStringToParagraphs = (
  text: string = '',
  {
    collapseTopMargin,
    sharedClassName,
  }: { collapseTopMargin?: boolean; sharedClassName?: string } = {},
) => {
  return text.split('\n').map((paragraph, index) => (
    <p
      key={index}
      className={`${sharedClassName} ${collapseTopMargin && index === 0 ? 'mt-0' : ''}`}
    >
      {paragraph}
    </p>
  ));
};
