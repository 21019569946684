import { FC, Fragment, useState } from 'react';

import { map } from 'cypress/types/bluebird';
import pluralize from 'pluralize';

import { useCurrentEntertainerQuery } from '@queries/EntertainerQueries';
import { FeedEvent } from '@queries/EventQueries';

import { useRespondToEntertainerEventRequestMutation } from '@mutations/EntertainerMutations';

import { useAnalytics } from '@hooks/useAnalytics';

import { useNavigate } from '@router/index';

import { findFirstAlphaCharacter } from '@utilities/findFirstAlphaCharacter';
import { mapStringToParagraphs } from '@utilities/mapStringToParagraphs';
import { openExternalUrl } from '@utilities/openExternalUrl';

import Button from '@components/buttons/Button';
import EventCard from '@components/cards/EventCard';
import Card from '@components/layout/Card/Card';
import { useDialog } from '@components/layout/Dialog/useDialog';
import { EmptyState } from '@components/layout/EmptyState/EmptyState';
import { ErrorBoundary } from '@components/layout/ErrorBoundary';
import { LetterAvatar } from '@components/layout/LetterAvatar/LetterAvatar';
import { Screen } from '@components/layout/Screen';
import { TitleToolbar } from '@components/layout/TitleToolbar/TitleToolbar';

import { useAuthGate } from '@screens/LogInScreen/AuthGateContext';

import { OnboardEntertainerPopup } from '../OnboardEntertainerScreen/OnboardEntertainerScreen';
import { FeaturedPhotoManagementSection } from '../components/FeaturedPhotoManagementSection';
import headerImage from './election-campaign-header.png';

const EventRequestCard: FC<{ event: FeedEvent }> = ({ event }) => {
  const headerTitle = event.promoters?.[0]?.title ?? event.venue?.title;

  return (
    <Card>
      <div className="reviewed-header-wrapper">
        <div className="reviewed-header">
          <span>Tagged Event</span>
          <Card.Header
            avatar={<LetterAvatar letter={findFirstAlphaCharacter(headerTitle)} />}
            title={headerTitle}
          />
        </div>
      </div>
      <EventCard hideRsvp showPromoter={false} event={event} onClick={() => {}} hideActions />
      <Card.Body className="pt-0">{mapStringToParagraphs(event.excerpt)}</Card.Body>
    </Card>
  );
};

export const ElectionCampaignScreen = () => {
  const { track } = useAnalytics();
  const navigate = useNavigate();
  const { createAuthGateHandler } = useAuthGate();
  const { openConfirmDialog } = useDialog();
  const { entertainer } = useCurrentEntertainerQuery();
  const [isEntertainerOnboardingPopupVisible, setIsEntertainerOnboardingPopupVisible] =
    useState(false);

  const handleOpenInstagramAccount = () => {
    track('Open @gaggl.app');
    openExternalUrl('https://www.instagram.com/gaggl.app/');
  };

  const handleOpenInstagramPost = () => {
    track('Open Election Results Post');
    openExternalUrl('https://www.instagram.com/p/DCJ64fkxOyM/');
  };

  const handleEnter = () => {
    track('Click Enter');
    createAuthGateHandler(() => {
      setIsEntertainerOnboardingPopupVisible(true);
    });
  };

  const handleIneligible = () => {
    track('Click Ineligible');
    openConfirmDialog({
      title: "You're Ineligible",
      text: "You must be a local active drag performer to enter. But don't worry, you can still support your local queer community by signing up!",
      onConfirm: () => {
        createAuthGateHandler(() => {
          navigate('/');
        });
      },
    });
  };

  return (
    <Screen
      name="Election Campaign Screen"
      headerProps={{
        title: 'Queer Is Here',
      }}
      hideToolbar
      disableLocationPermissionGate
    >
      <Screen.Content maxWidth={425}>
        <Card>
          <img src={headerImage} alt="logo" />
        </Card>

        <TitleToolbar text="Enter for a Chance to Win $150" size="md" />
        <p>
          This election could have shown that LGBTQ+ voices matter in America. Instead, it’s a
          reminder that we’re still fighting to be seen, especially in places where it’s not always
          safe.
        </p>

        <p>
          We’ve felt the weight of this moment, but we refuse to let it define us. From big cities
          to small towns, drag is our strength, our joy, and our way of saying: we belong
          everywhere. Drag breathes life into the queer community, fuels resilience, and boldly
          declares that we’re here to stay.
        </p>

        <p>
          <strong>
            If you are a drag performer, join us on Gaggl to be part of this movement—and enter for
            a chance to win $150! Here’s how:
          </strong>
        </p>

        <p>
          <div onClick={handleOpenInstagramAccount}>
            1️⃣ <strong>Follow</strong> <span className="text-underline">@gaggl.app</span> on
            Instagram{' '}
            <span className="px-1" style={{ background: 'rgb(103, 58, 183)', borderRadius: 4 }}>
              →
            </span>
          </div>
          <div onClick={handleOpenInstagramPost}>
            2️⃣ <strong>Like & Share</strong> the post giveaway post{' '}
            <span className="px-1" style={{ background: 'rgb(103, 58, 183)', borderRadius: 4 }}>
              →
            </span>
          </div>
          3️⃣ <strong>Sign Up</strong> using the button below and share a "Featured Look"
        </p>

        <p>
          By signing up, you’re helping amplify queer voices, elevate local performers, and spread
          the message of resilience across every corner of America. Coast to coast, we’re here to
          stay.
        </p>

        {!entertainer && (
          <Fragment key="unauthorized-actions">
            <Button onClick={handleEnter}>Yes, I am a Drag Performer</Button>
            <Button variant="flat" color="neutral" onClick={handleIneligible}>
              No, I am not a Drag Performer
            </Button>
          </Fragment>
        )}

        {entertainer && (
          <Fragment key="authorized-actions">
            {entertainer.featuredMedia.length ? (
              <EmptyState
                title="You're all set!"
                content={
                  <div className="text-align-left mb-4">
                    <div className="mb-2">
                      <strong>🌟 Bonus Entries:</strong> Add the link to your Gaggl profile in your
                      Instagram bio for an extra 25 entries!
                    </div>
                    <div>
                      <strong>✨ Extra Entries for Badges:</strong> Each badge you send to fellow
                      performers adds to your entries, showing support and solidarity in our
                      community!
                    </div>
                  </div>
                }
                button={{
                  text: 'Get my profile link',
                  onClick: () => {
                    track('Get profile link');
                    navigate('/manage/entertainer');
                  },
                }}
                secondaryButton={{
                  text: 'Send badges',
                  onClick: () => {
                    track('Navigate Home', {
                      text: 'Send Badges',
                    });
                    navigate('/');
                  },
                }}
              />
            ) : (
              <FeaturedPhotoManagementSection
                hideTitleToolbar
                cityId={entertainer?.cityIds?.[0]}
                featuredMedia={[]}
              />
            )}
          </Fragment>
        )}
      </Screen.Content>
      <ErrorBoundary>
        <OnboardEntertainerPopup
          isVisible={isEntertainerOnboardingPopupVisible}
          onClose={() => {
            track('Close onboard entertainer popup');
            setIsEntertainerOnboardingPopupVisible(false);
          }}
        />
      </ErrorBoundary>
    </Screen>
  );
};
