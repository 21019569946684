import { QueryClient, useQuery, useQueryClient } from '@tanstack/react-query';
import { useMemo } from 'react';

import { event } from 'cypress/types/jquery';

import API from '@api/API';

import { useAppContext } from '../contexts/AppContext';
import { BasicRating, CritiqueData, CritiqueQuestion, UserCritique } from './CritiqueQueries';
import { FeedEvent } from './EventQueries';
import { FeedPost } from './PostQueries';
import { PublicBookingOpportunity, useCityBookingOpportunitiesQuery } from './PromoterQueries';

export const TYPES_RECORD = {
  dragPerformer: 'Drag Performer',
  dj: 'DJ',
  gogo: 'Go-Go',
  comedian: 'Comedian',
  other: 'Other',
} as const;

export type EntertainerType = keyof typeof TYPES_RECORD;

export interface Entertainer {
  _id: string;
  avatar: string;
  badgeCount: number;
  title: string;
  tagline: string;
  description: string;
  type: EntertainerType;
  cityIds: string[];
  cities: { _id: string; name: string }[];
  details: any;
  tags: {
    like: string[];
    neutral: string[];
    dislike: string[];
  };
  bookingHandle?: string;
  smsKeyword?: string;
  availabilityReferralUrl?: string;
  sweepstakesReferralUrl?: string;
  contact?: {
    email?: string;
    instagram?: string;
    facebook?: string;
    venmo?: string;
    cashApp?: string;
    links?: {
      type: string;
      url: string;
      text: string;
    }[];
  };
  featuredMedia: {
    _id: string;
    title: string;
    image: string;
  }[];
  isFeaturedBipocArtist?: boolean;
}

export interface CurrentEntertainer extends Entertainer {
  boostExpiresAt?: string;
  boosts?: string[];
  hasFlexibleAvailability: boolean;
}
export interface EntertainerBookingOpportunity
  extends Pick<PublicBookingOpportunity, 'type' | 'messaging'> {
  primaryImage?: string;
  _id: string;
  title: string;
  details: string;
  startDate: string;
  startTime: string;
  city: { name: string };
  venue: { title: string };
  promoter: { _id: string; title: string };
  status: PublicBookingOpportunity['entertainers'][0]['status'] | 'new';
  updates: PublicBookingOpportunity['updates'];
  askedDetailQuestions: {
    _id: string;
    detailQuestionId: string;
    question: string;
    answer?: string;
  }[];
}

export interface EntertainerBookingRequest {
  _id: string;
  status: string;
  requestedBy: string;
  createdAt: string;
  city: {
    _id: string;
    name: string;
  };
  entertainer: {
    _id: string;
    title: string;
  };
  details: {
    startDate: string;
    startTime: string;
    venue: { title: string };
  };
  contact: {
    name: string;
    phone: string;
    email: string;
  };
  performance: {
    duration: string;
    type: string;
    specialRequests: string;
    additionalQuestions?: string;
  };
  technical: {
    soundSystem: string;
    dressingRoom: string;
    lightingSetup: string;
  };
}

export interface EntertainerCompetition {
  _id: string;
  title: string;
  subtitle?: string;
  startDate: string;
  endDate: string;
  instructions?: string;
  additionalTips?: string[];
  frameOptions?: ('gaggl' | 'cdr-default' | 'cdr-s3')[];
  toSupportMessage?: string;
}

export const useCurrentEntertainerQuery = () => {
  const { userIsLoggedIn } = useAppContext();

  const {
    isFetched: entertainerIsReady,
    data,
    refetch: refetchEntertainer,
  } = useQuery({
    queryKey: useCurrentEntertainerQuery.queryKey(),
    queryFn: () => useCurrentEntertainerQuery.queryFn(),
    enabled: userIsLoggedIn,
  });

  return {
    entertainerIsReady,
    hasOnboardedBookingWizard: data?.hasOnboardedBookingWizard,
    entertainer: data?.entertainer,
    bookingOpportunities: data?.bookingOpportunities,
    bookingRequests: data?.bookingRequests,
    upcomingEvents: data?.upcomingEvents,
    competitions: data?.competitions,
    eventRequests: data?.eventRequests,
    basicRatings: data?.basicRatings ?? { like: 0, neutral: 0, dislike: 0 },
    refetchEntertainer,
  };
};
useCurrentEntertainerQuery.queryKey = () => ['user', 'entertainer'];
useCurrentEntertainerQuery.queryFn = () =>
  API.get<{
    hasOnboardedBookingWizard: boolean;
    entertainer: CurrentEntertainer;
    bookingOpportunities: EntertainerBookingOpportunity[];
    bookingRequests: EntertainerBookingRequest[];
    upcomingEvents: (FeedEvent & { canUserEdit: boolean })[];
    competitions: EntertainerCompetition[];
    eventRequests: FeedEvent[];
    basicRatings: {
      like: number;
      neutral: number;
      dislike: number;
    };
  }>(`/v1/entertainer/me`);

export const fetchCurrentEntertainer = async (queryClient: QueryClient) => {
  const queryKey = useCurrentEntertainerQuery.queryKey();
  await Promise.all([
    queryClient.cancelQueries({ queryKey }),
    queryClient.invalidateQueries({ queryKey }),
  ]);

  return queryClient.fetchQuery({
    queryKey: useCurrentEntertainerQuery.queryKey(),
    queryFn: () => useCurrentEntertainerQuery.queryFn(),
  });
};

export const useFetchCurrentEntertainer = () => {
  const queryClient = useQueryClient();

  return () => fetchCurrentEntertainer(queryClient);
};

export interface EntertainerLink {
  type: string;
  url: string;
  text: string;
}
export interface UnauthorizedPublicEntertainer {
  _id: string;
  isPublic?: boolean;
  isDemoEnabled?: {
    primaryCommentDisplayName: string;
    primaryCommentText: string;
  };
  isClaimed: boolean;
  avatar?: string;
  title: string;
  tagline?: string;
  description?: string;
  tags?: {
    like: string[];
    neutral: string[];
    dislike: string[];
  };
  type?: string;
  cities: { _id: string; name: string; rank: number; total: number }[];
  contact?: {
    instagram?: string;
    venmo?: string;
    tiktok?: string;
    email?: string;
    website?: string;
    links?: EntertainerLink[];
  };
  details: {
    categories: string[];
  };
  featuredMedia: {
    _id: string;
    title: string;
    image: string;
  }[];
  bookingHandle?: string;
  isFeaturedBipocArtist?: boolean;
  isFeaturedKingThing?: boolean;
}

export const useEntertainerByBookingHandleQuery = (bookingHandle: string) => {
  const { isFetched, data } = useQuery({
    queryKey: ['entertainer', 'bookingHandle', bookingHandle],
    queryFn: () =>
      API.get<{
        entertainer: UnauthorizedPublicEntertainer;
        upcomingEvents: FeedEvent[];
        activeCompetitions: { _id: string; title: string }[];
      }>(`/v1/entertainers/bookingHandle/${bookingHandle}`),
    enabled: !!bookingHandle,
  });

  return {
    entertainerIsReady: isFetched,
    entertainer: data?.entertainer,
    upcomingEntertainerEvents: data?.upcomingEvents,
    activeEntertainerCompetitions: data?.activeCompetitions,
  };
};

export const useRelatedEntertainersByBookingHandleQuery = (
  bookingHandle: string,
  isEnabled = true,
) => {
  const { isFetched, data } = useQuery({
    queryKey: ['entertainer', 'bookingHandle', bookingHandle, 'related-entertainers'],
    queryFn: () =>
      API.get<{
        relatedEntertainers: Entertainer[];
      }>(`/v1/entertainers/bookingHandle/${bookingHandle}/related-entertainers`),
    enabled: !!bookingHandle && isEnabled,
  });

  return {
    relatedEntertainersAreReady: isFetched,
    relatedEntertainers: data?.relatedEntertainers,
  };
};

export const useEntertainerByIdQuery = (entertainerId: string) => {
  const { isFetched, data } = useQuery({
    queryKey: ['entertainer', entertainerId],
    queryFn: () =>
      API.get<{
        entertainer: UnauthorizedPublicEntertainer;
      }>(`/v1/entertainers/${entertainerId}`),
    enabled: !!entertainerId,
  });

  return {
    entertainerIsReady: isFetched,
    entertainer: data?.entertainer,
  };
};

export const useEntertainerReviewsQuery = (entertainerId?: string) => {
  const { isFetched, data, refetch } = useQuery({
    queryKey: useEntertainerReviewsQuery.queryKey(entertainerId),
    queryFn: () => useEntertainerReviewsQuery.queryFn(entertainerId),
    enabled: !!entertainerId,
  });

  return {
    reviewsAreReady: isFetched,
    userPrimaryRating: data?.userPrimaryRating,
    reviews: data?.reviews,
    refetchReviews: refetch,
  };
};
useEntertainerReviewsQuery.queryKey = (entertainerId: string) => [
  'entertainer-reviews',
  entertainerId,
];
useEntertainerReviewsQuery.queryFn = (entertainerId: string) =>
  API.get<{
    userPrimaryRating?: BasicRating;
    reviews: FeedPost[];
  }>(`/v1/entertainers/${entertainerId}/reviews`);

export interface EntertainerRank {
  _id: string;
  title: string;
  rank: number;
  bookingHandle: string;
  tags: {
    like: string[];
    neutral: string[];
    dislike: string[];
  };
  primaryRating: string;
  like: { label: string }[];
  neutral: { label: string }[];
  dislike: { label: string }[];
  details?: { categories: string[] };
}
export const useTopEntertainersQuery = (cityId: string, type: string, userIsLoggedIn: boolean) => {
  const { isFetched, data, refetch } = useQuery({
    queryKey: useTopEntertainersQuery.queryKey(cityId, type, userIsLoggedIn),
    queryFn: () => useTopEntertainersQuery.queryFn(cityId, type),
    enabled: !!cityId && !!type,
  });

  return {
    topEntertainersAreReady: isFetched,
    totalEntertainers: data?.total,
    topEntertainers: data?.entertainers,
    refetchTopEntertainers: refetch,
  };
};
useTopEntertainersQuery.queryKey = (cityId: string, type: string, userIsLoggedIn: boolean) => [
  'top-entertainers',
  type,
  cityId,
  userIsLoggedIn,
];
useTopEntertainersQuery.queryFn = (cityId: string, type: string) =>
  API.get<{
    total: number;
    entertainers: EntertainerRank[];
  }>(`/v1/entertainers/top?cityId=${cityId}&type=${type}`);

export const useHydratedEntertainersQuery = (cityId: string, type: string) => {
  const { isFetched, data, refetch } = useQuery({
    queryKey: useHydratedEntertainersQuery.queryKey(cityId, type),
    queryFn: () => useHydratedEntertainersQuery.queryFn(cityId, type),
    enabled: !!cityId && !!type,
  });

  return {
    entertainersAreReady: isFetched,
    entertainers: data?.entertainers ?? {},
    refetchTopEntertainers: refetch,
  };
};
useHydratedEntertainersQuery.queryKey = (cityId: string, type: string) => [
  'hydrated-entertainers',
  type,
  cityId,
];
useHydratedEntertainersQuery.queryFn = (cityId: string, type: string) =>
  API.get<{
    entertainers: Record<string, Entertainer>;
  }>(`/v1/cities/${cityId}/entertainers/?type=${type}`);

export const useRecommendedEntertainersQuery = (cityId: string, type: string) => {
  const { isFetched, data, refetch } = useQuery({
    queryKey: useRecommendedEntertainersQuery.queryKey(cityId, type),
    queryFn: () => useRecommendedEntertainersQuery.queryFn(cityId, type),
    enabled: !!cityId && !!type,
  });

  return {
    recommendedEntertainersAreReady: isFetched,
    recommendedEntertainers: data?.entertainerIdsByQuestionSlug,
    refetchRecommendedEntertainers: refetch,
  };
};

useRecommendedEntertainersQuery.queryKey = (cityId: string, type: string) => [
  'recommended-entertainers',
  type,
  cityId,
];

useRecommendedEntertainersQuery.queryFn = (cityId: string, type: string) =>
  API.get<{
    entertainerIdsByQuestionSlug: Record<string, string[]>;
  }>(`/v1/entertainers/recommended?cityId=${cityId}&type=${type}`);

export const usePrioritizedEntertainersQuery = (cityId: string, type: string, isEnabled = true) => {
  const { isFetched, data, refetch } = useQuery({
    queryKey: usePrioritizedEntertainersQuery.queryKey(cityId, type),
    queryFn: () => usePrioritizedEntertainersQuery.queryFn(cityId, type),
    enabled: !!cityId && !!type && isEnabled,
  });

  return {
    prioritizedEntertainersAreReady: isFetched,
    prioritizedEntertainers: data?.entertainers,
    refetchprioritizedEntertainers: refetch,
  };
};

usePrioritizedEntertainersQuery.queryKey = (cityId: string, type: string) => [
  'prioritized-entertainers',
  type,
  cityId,
];
usePrioritizedEntertainersQuery.queryFn = (cityId: string, type: string) =>
  API.get<{
    entertainers: { _id: string; title: string; primaryRating }[];
  }>(`/v1/entertainers/prioritized?cityId=${cityId}&type=${type}`);

export const useFeaturedEntertainersQuery = (cityId: string, type: string) => {
  const { isFetched, data, refetch } = useQuery({
    queryKey: useFeaturedEntertainersQuery.queryKey(cityId, type),
    queryFn: () => useFeaturedEntertainersQuery.queryFn(cityId, type),
    enabled: !!cityId && !!type,
  });

  return {
    featuredEntertainersAreReady: isFetched,
    featuredEntertainers: data?.entertainers,
    refetchFeaturedEntertainers: refetch,
  };
};

useFeaturedEntertainersQuery.queryKey = (cityId: string, type: string) => [
  'featured-entertainers',
  type,
  cityId,
];
useFeaturedEntertainersQuery.queryFn = (cityId: string, type: string) =>
  API.get<{
    entertainers: Entertainer[];
  }>(`/v1/entertainers/featured?cityId=${cityId}&type=${type}`);

export const useEntertainerCritiqueDataQuery = (entertainerId: string, isEnabled = true) => {
  const { isFetched, data, refetch } = useQuery({
    queryKey: useEntertainerCritiqueDataQuery.queryKey(entertainerId),
    queryFn: () => useEntertainerCritiqueDataQuery.queryFn(entertainerId),
    enabled: isEnabled && !!entertainerId,
    refetchOnMount: 'always',
    refetchOnReconnect: 'always',
    refetchOnWindowFocus: 'always',
  });

  return {
    entertainerCritiqueDataIsReady: isFetched,
    entertainerCritiqueData: data?.critiqueData,
    refetchEntertainerCritiqueData: refetch,
  };
};
useEntertainerCritiqueDataQuery.queryKey = (entertainerId) => [
  'entertainer-critiques',
  entertainerId,
];
useEntertainerCritiqueDataQuery.queryFn = (entertainerId) =>
  API.get<{ critiqueData: CritiqueData }>(`/v1/entertainers/${entertainerId}/critiques`);

export const useCurrentUserEntertainerCritiqueQuery = (entertainerId: string, isEnabled = true) => {
  const { isFetched, data, refetch } = useQuery({
    queryKey: useCurrentUserEntertainerCritiqueQuery.queryKey(entertainerId),
    queryFn: () => useCurrentUserEntertainerCritiqueQuery.queryFn(entertainerId),
    enabled: isEnabled && !!entertainerId,
  });

  return {
    userEntertainerCritiqueIsReady: isFetched,
    userEntertainerCritique: data?.userCritique,
    refetchUserEntertainerCritique: refetch,
  };
};
useCurrentUserEntertainerCritiqueQuery.queryKey = (entertainerId) => [
  'user-critiques',
  'entertainers',
  entertainerId,
];
useCurrentUserEntertainerCritiqueQuery.queryFn = (entertainerId) =>
  API.get<{ userCritique: UserCritique }>(`/v1/user/critiques/entertainers/${entertainerId}`);

export interface EntertainerBadge {
  _id: string;
  isLocked: boolean;
  sentAt: string;
  title: string;
  description: string;
  imageUrl: string;
  reason: string;
  eventTitle?: string;
  entertainerMediaId?: string;
  to: {
    entertainerId: string;
    title: string;
  };
  from?: {
    userId: string;
    entertainerId: string;
    displayName: string;
  };
}

export const useCurrentEntertainerBadgesQuery = (entertainerId: string, isEnabled = true) => {
  const { isFetched, data, refetch } = useQuery({
    queryKey: useCurrentEntertainerBadgesQuery.queryKey(entertainerId),
    queryFn: () => useCurrentEntertainerBadgesQuery.queryFn(entertainerId),
    enabled: isEnabled && !!entertainerId,
    refetchOnMount: 'always',
    refetchOnReconnect: 'always',
    refetchOnWindowFocus: 'always',
  });

  return {
    entertainerBadgesAreReady: isFetched,
    sentBadges: data?.sent,
    receivedBadges: data?.received,
    refetchEntertainerBadges: refetch,
  };
};
useCurrentEntertainerBadgesQuery.queryKey = (entertainerId) => [
  'currentEntertainer',
  entertainerId,
  'badges',
];
useCurrentEntertainerBadgesQuery.queryFn = (entertainerId) =>
  API.get<{ sent: EntertainerBadge[]; received: EntertainerBadge[] }>(
    `/v1/user/entertainer/${entertainerId}/badges`,
  );

export const useCurrentEntertainerAvailabilityQuery = (entertainerId: string) => {
  const { isFetched, data, refetch } = useQuery({
    queryKey: useCurrentEntertainerAvailabilityQuery.queryKey(entertainerId),
    queryFn: () => useCurrentEntertainerAvailabilityQuery.queryFn(entertainerId),
    enabled: !!entertainerId,
  });

  return {
    currentEntertainerAvailabilityIsReady: isFetched,
    currentEntertainerAvailability: data?.availability ?? {},
    refetchCurrentEntertainerAvailability: refetch,
  };
};

useCurrentEntertainerAvailabilityQuery.queryKey = (entertainerId: string) => [
  'entertainer-availability',
  entertainerId,
];

useCurrentEntertainerAvailabilityQuery.queryFn = (entertainerId: string) =>
  API.get<{
    availability: Record<string, boolean>;
  }>(`/v1/user/entertainer/${entertainerId}/availability`);

export const useCityEntertainerAvailabilityQuery = (cityId: string) => {
  const { isFetched, data, refetch } = useQuery({
    queryKey: useCityEntertainerAvailabilityQuery.queryKey(cityId),
    queryFn: () => useCityEntertainerAvailabilityQuery.queryFn(cityId),
    enabled: !!cityId,
  });

  return {
    cityEntertainerAvailabilityIsReady: isFetched,
    cityEntertainerAvailability: data,
    refetchCityEntertainerAvailability: refetch,
  };
};

useCityEntertainerAvailabilityQuery.queryKey = (cityId: string) => [
  'city-entertainer-availability',
  cityId,
];
useCityEntertainerAvailabilityQuery.queryFn = (cityId: string) =>
  API.get<{
    availabilityByDate: Record<string, string[]>;
    entertainersById: Record<string, Entertainer>;
  }>(`/v1/cities/${cityId}/availability`);

export const useEntertainerQuickPicksQuery = (entertainerId: string, firstQuestionId?: string) => {
  const { isFetched, data, refetch } = useQuery({
    queryKey: useEntertainerQuickPicksQuery.queryKey(entertainerId, firstQuestionId),
    queryFn: () => useEntertainerQuickPicksQuery.queryFn(entertainerId, firstQuestionId),
    enabled: !!entertainerId,
  });

  return {
    critiqueQuestionsAreReady: isFetched,
    critiqueQuestions: data?.critiqueQuestions,
    refetchCritiqueQuestions: refetch,
  };
};

useEntertainerQuickPicksQuery.queryKey = (entertainerId: string, firstQuestionId?: string) => [
  'entertainer',
  entertainerId,
  'quick-picks',
  firstQuestionId,
];
useEntertainerQuickPicksQuery.queryFn = (entertainerId: string, firstQuestionId?: string) => {
  const query = firstQuestionId ? `?firstQuestionId=${firstQuestionId}` : '';

  return API.get<{
    critiqueQuestions: CritiqueQuestion[];
  }>(`/v1/entertainers/${entertainerId}/quick-picks${query}`);
};
