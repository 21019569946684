import { useQuery } from '@tanstack/react-query';

import { useAppContext } from '@contexts/AppContext';

import API from '@api/API';

import { useCurrentUserCity, useCurrentUserLocation } from '@hooks/useCurrentUserLocation';

import { CritiqueData, UserCritique } from './CritiqueQueries';
import { Entertainer } from './EntertainerQueries';

export interface Series {
  _id: string;
  title: string;
  description?: string;
  city: {
    _id: string;
    name: string;
  };
  filterTags: { _id: string; label: string }[];
  isEntertainerSeries?: boolean;
  isLocalDragSeries?: boolean;
  entertainerCategories: string[];
  showEntertainerFeed?: boolean;
  showRecommendedTab?: boolean;
  showEntertainerShows?: boolean;
}

export const useSeries = () => {
  const { data, isFetched, refetch } = useQuery({
    queryKey: ['series'],
    queryFn: () => API.get<{ series: Series[] }>(`/v1/series`),
  });

  return { series: data?.series, isFetched, refetch };
};

interface SeriesHost {
  title: string;
  description: string;
  image: string;
  to: string;
}

export const useSeriesByTagSlug = (tagSlug: string) => {
  const { data, isFetched, refetch } = useQuery({
    queryKey: ['series', tagSlug],
    queryFn: () => API.get<{ series: Series; seriesHost?: SeriesHost }>(`/v1/series/${tagSlug}`),
    enabled: !!tagSlug,
  });

  return { series: data?.series, seriesHost: data?.seriesHost, isFetched, refetch };
};

export const useSeriesEntertainersQuery = (tagId: string, isEnabled = true) => {
  const { data, isFetched, refetch } = useQuery({
    queryKey: useSeriesEntertainersQuery.queryKey(tagId),
    queryFn: () => API.get<{ entertainers: Entertainer[] }>(`/v1/series/${tagId}/entertainers`),
    enabled: !!tagId,
  });

  return { entertainers: data?.entertainers, isFetched, refetch };
};

useSeriesEntertainersQuery.queryKey = (tagId: string) => ['series', tagId, 'entertainers'];
