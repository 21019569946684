import { uuid } from '@utilities/uuid';

const createSessionId = () => {
  const newSessionId = uuid();
  localStorage.setItem('sessionId', newSessionId);
  return newSessionId;
};

const urlParams = new URLSearchParams(window.location.search);
const referredBy = urlParams.get('referredBy');
export const sessionId = localStorage.getItem('sessionId') || createSessionId();

export const useReferredBy = () => {
  return { sessionId, referredBy };
};
