import { FC, createContext, useCallback, useContext, useRef, useState } from 'react';

import { useAppContext } from '@contexts/AppContext';

import { useFetchCurrentUserGagglRank } from '@queries/CritiqueQueries';
import { useCurrentEntertainerQuery } from '@queries/EntertainerQueries';

import { FormLabel } from '@components/form/FormLabel';

import { AuthPopupEntertainer, EntertainerLoginContent } from './EntertainerLoginContent';
import { AuthPopup, AuthPopupProps, useAuthState } from './LogInScreen';

type CreateAuthGateHandlerFn = (
  callback: () => void,
  options?: {
    authMessage?: string;
    entertainerCallback?: (entertainerProperies: AuthPopupEntertainer) => void;
    cityId?: string;
  },
) => void;

const AuthGateContext = createContext<{
  createAuthGateHandler: CreateAuthGateHandlerFn;
}>({ createAuthGateHandler: () => {} });

export const useAuthGate = () => useContext(AuthGateContext);

export const AuthGateProvider = ({ children }) => {
  const [authPopupIsVisible, setAuthPopupIsVisible] = useState(false);
  const authState = useAuthState();
  const { userIsLoggedIn } = useAppContext();
  const { entertainer } = useCurrentEntertainerQuery();
  const fetchCurrentUserGagglRank = useFetchCurrentUserGagglRank();

  const authCallbackRef = useRef<() => void>();
  const entertainerSubmitCallbackRef =
    useRef<(entertainerProperies: AuthPopupEntertainer) => void>();
  const optionsRef = useRef<{ cityId?: string }>({});
  const [isEntertainerPopup, setIsEntertainerPopup] = useState(false);
  const [authMessage, setAuthMessage] = useState('');
  const createAuthGateHandler = useCallback<CreateAuthGateHandlerFn>(
    async (callback, options = {}) => {
      const { authMessage, entertainerCallback, ...authOptions } = options;

      if (userIsLoggedIn) {
        if ((entertainer && entertainerCallback) || !entertainerCallback) {
          return callback();
        }
      }

      setAuthMessage(authMessage);
      setAuthPopupIsVisible(true);
      authCallbackRef.current = callback;
      entertainerSubmitCallbackRef.current = entertainerCallback;
      setIsEntertainerPopup(!!entertainerCallback);
      optionsRef.current = authOptions;
    },
    [userIsLoggedIn],
  );

  return (
    <AuthGateContext.Provider value={{ createAuthGateHandler }}>
      {children}
      <AuthPopup
        isVisible={authPopupIsVisible}
        onClose={() => {
          setAuthPopupIsVisible(false);
          authState.setUserId('');
        }}
        redirectOverride={async () => {
          await fetchCurrentUserGagglRank();
          requestAnimationFrame(() => {
            authCallbackRef.current?.();
          });
        }}
        entertainerCallback={isEntertainerPopup ? entertainerSubmitCallbackRef.current : undefined}
        options={optionsRef.current}
        {...authState}
        authMessage={authMessage}
        loginButtonText="Continue"
        CustomLoginContentComponent={isEntertainerPopup ? EntertainerLoginContent : undefined}
      />
    </AuthGateContext.Provider>
  );
};
